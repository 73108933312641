import {
  useStep,
  useHomeFeaturesStore,
} from "../../../stores/buyerHomeFeaturesStore";

interface City {
  id: number;
  name: string;
  icon?: string;
  latitude?: number;
  longitude?: number;
}

const usePointsInterest = () => {
  const { setCurrentStep } = useStep();
  const {
    selectedAddresses,
    setSelectedAddresses,
    selectedStation,
    setSelectedStation,
    selectedSchool,
    setSelectedSchool,
    selectedWorkAddress,
    setSelectedWorkAddress,
    setPointsInterestError,
    pointsInterestError,
    setRemainingPopularPoints,
    remainingPopularPoints,
    selectedPoints,
    setSelectedPoints,
    placeWorship,
    setPlaceWorship,
  } = useHomeFeaturesStore();

  const addPointOfInterest = (point: City) => {
    setRemainingPopularPoints((prev) =>
      prev.filter((item) => item.id !== point.id)
    );
    setSelectedPoints((prev) => [...prev, point]);
  };

  const removePointOfInterest = (point: City) => {
    setRemainingPopularPoints((prev) => [...prev, point]);
    setSelectedPoints((prev) => prev.filter((item) => item.id !== point.id));
    if (point.id === 1) {
      setSelectedStation("");
    }
    if (point.id === 2) {
      setSelectedWorkAddress("");
    }
    if (point.id === 3) {
      setSelectedSchool("");
    }
    if (point.id === 4) {
      setPlaceWorship("");
    }
  };

  const removeSelectedAddress = (id: number) => {
    setSelectedAddresses((prev) => prev.filter((address) => address.id !== id));
  };

  return {
    removeSelectedAddress,
    addPointOfInterest,
    removePointOfInterest,
    setCurrentStep,
    remainingPopularPoints,
    setRemainingPopularPoints,
    selectedAddresses,
    setSelectedAddresses,
    selectedPoints,
    setSelectedPoints,
    pointsInterestError,
    setPointsInterestError,
    selectedStation,
    setSelectedStation,
    selectedSchool,
    setSelectedSchool,
    selectedWorkAddress,
    setSelectedWorkAddress,
    placeWorship,
    setPlaceWorship,
  };
};

export { usePointsInterest };

import { useModalStore } from "../../../stores/handleModalStore";
import { Button } from "../../common";
import { Modal } from "../../common/modal";

const CartModal = () => {
  const { setCartModal, cartModal, minCartModal } = useModalStore();
  return (
    <Modal closeIcon={true} setIsOpen={setCartModal} isOpen={cartModal}>
      <div className="flex items-center text-center flex-col">
        <div className="text-z-shark text-xl text-[22px] font-bold">
          {minCartModal ? "Add showings" : "Trim down your showings"}
        </div>
        <p className="text-z-shark mt-5 leading-5 font-light">
          {minCartModal
            ? `Select a minimum of 2 properties to go on showings. Move any to your "Save for Later" list, while unselected ones will remain in your "Likes" folder.`
            : `Select up to 8 properties per showing. Move any to your "Save for
          Later" list, while unselected ones will remain in your "Likes" folder.`}
        </p>
        <Button
          radius="50px"
          height="md"
          width="full"
          customClassName="font-bold text-lg mt-5"
          onClick={() => setCartModal(false)}
        >
          Ok, Got It
        </Button>
      </div>
    </Modal>
  );
};

export { CartModal };

import { useEffect, useRef, useState } from "react";
import Chart, { type TooltipItem } from "chart.js/auto";
import { formatPrice } from "../../../utils/formatPrice";
import type { Listing } from "../../../interfaces/listing";
// import ArrowUpIcon from "../../../assets/Listing/arrowUpZownForecast.svg";
// import ArrowDownIcon from "../../../assets/Listing/arrowDownZownForecast.svg";

// import millify from "millify";

interface ZownForecastProps {
	listing: Listing;
	zownForecast: Record<string, number>;
	
}

const ZownForecast = ({ listing, zownForecast }: ZownForecastProps) => {
	const chartRef = useRef<Chart | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	useEffect(() => {
		const fetchZownForecast = async () => {
			const xValues = Object.keys(zownForecast).sort();
			const yValues = xValues.map((date) => zownForecast[date]);

			const targetXValues = xValues.slice(-30, -8);
			const targetYValues = yValues.slice(-30, -8);

			if (canvasRef.current) {
				if (chartRef.current) {
					chartRef.current.destroy();
				}

				const ctx = canvasRef.current.getContext("2d");
				if (ctx) {
					const gradient = ctx.createLinearGradient(0, 0, 0, 600);
					gradient.addColorStop(0, "rgba(254, 209, 28, 0.7)");
					gradient.addColorStop(1, "rgba(254, 209, 28, 0)");

					chartRef.current = new Chart(ctx, {
						type: "line",
						data: {
							labels: targetXValues,
							datasets: [
								{
									fill: true,
									backgroundColor: gradient,
									pointRadius: 0,
									borderColor: "#FECD07",
									tension: 0.5,
									data: targetYValues,
								},
							],
						},
						options: {
							responsive: true,
							maintainAspectRatio: false,
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									mode: "index",
									intersect: false,
									callbacks: {
										label: (tooltipItem: TooltipItem<"line">) =>
											`$${formatPrice(tooltipItem.parsed.y)}`,
									},
								},
							},
							scales: {
								y: {
									display: false,
									grid: {
										display: false,
									},
								},
								x: {
									type: "category",
									grid: {
										display: false,
									},
									ticks: {
										autoSkip: true,
										maxRotation: 0,
										minRotation: 0,
									},
								},
							},
						},
					});
				}
			}
		};

		fetchZownForecast();
	}, [zownForecast]);

	useEffect(() => {
		return () => {
			chartRef.current?.destroy();
		};
	}, []);

	return (
		<div className="space-y-4 px-4">
			<div className="space-y-2">
				{/* <div className="w-full flex justify-between">
					<div className="w-full space-y-2">
						<div className="text-lg font-semibold">Zown Forecast</div>
						{listing.valuation &&
							listing.valuation.final_ai_eval_for_target_property && (
								<div className="font-bold text-xl bg-z-abbey w-fit rounded-full px-4 py-0.5">
									$
									{formatPrice(
										listing.valuation.final_ai_eval_for_target_property,
									)}
								</div>
							)}
					</div>
				</div> */}
				{/* <div className="w-full flex flex-col items-end space-y-2">
            <div className="flex items-center justify-between gap-1.5">
              <div className="text-[10px]">Last 30 days</div>
              <div className="text-sm rounded-full py-1 px-2 bg-[#FF5757]/20 text-[#FF5757] font-semibold flex items-center gap-1">
                <img src={ArrowDownIcon} alt="" />
                12.4%
              </div>
            </div>
            <div className="flex items-center justify-between gap-1.5">
              <div className="text-[10px]">6 Month Forecast</div>
              <div className="text-sm rounded-full py-1 px-2 bg-[#0A9D57]/20 text-[#0A9D57] font-semibold flex items-center gap-1">
                <img src={ArrowUpIcon} alt="" />
                19.8%
              </div>
            </div>
          </div> */}
				{/* <div>
					{listing.valuation && (
						<div className="text-xs">
							Estimated Range: $
							{millify(listing.valuation.minimum_possible_price_for_property)}-
							${millify(listing.valuation.maximum_possible_price_for_property)}
						</div>
					)}
				</div> */}
				<canvas ref={canvasRef} id="myChart" className="h-auto max-h-[245px]" />
			</div>
		</div>
	);
};

export { ZownForecast };

import { useEffect, useRef, useState } from "react";
import {
	ChevronDownIcon,
	CreditIcon,
	CreditScoreIcon,
	SuggestionsIcon,
} from "../../assets/icons";
import clsx from "clsx";

const CreditScoreSection = () => {
	const [creditScoreIsOpen, setCreditScoreIsOpen] = useState(false);
	const creditRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (creditScoreIsOpen) {
				creditRef?.current?.scrollIntoView({behavior:'smooth'})
		} else {
			creditRef?.current?.scrollIntoView({behavior:'smooth', block:'center'})
		}
	} , [creditScoreIsOpen])

	return (
		<div className="shadow-[0_0_6px_0_rgba(0,0,0,0.15)] rounded-[20px] w-full py-5 px-6 mt-10">
			<div className="flex items-center space-x-1">
				<img src={CreditIcon} alt="credit icon" />
				<div className="text-[#6541F8] text-xl font-semibold">Credit Score</div>
			</div>
			<div
				className={clsx(
					"bg-[#F5F2FF] rounded-2xl flex flex-col overflow-hidden px-4 py-5 transition-height duration-300 ease-in-out relative mt-3",
					creditScoreIsOpen ? "h-[400px]" : "h-[180px]",
				)}
				ref={creditRef}
			>
				<div
					className={clsx(
						"absolute top-40 transition-all duration-300 left-1/2 -translate-x-1/2",
						creditScoreIsOpen && "rotate-180",
					)}
					onClick={() => setCreditScoreIsOpen((prev) => !prev)}
					onKeyUp={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							setCreditScoreIsOpen((prev) => !prev);
						}
					}}
				>
					<ChevronDownIcon fill="#6541F8" />
				</div>
				<div className="flex items-center relative">
					<span className="text-z-shark text-3xl font-bold">450</span>
					<div className="absolute bottom-0 left-12 flex items-center">
						<div className="px-2 text-[#828282]">/</div>
						<span className="font-medium text-[#828282]">900</span>
					</div>
				</div>
				<div className="text-[#828282] text-xs">Next update in 7 days</div>
				<div className="flex items-center space-x-2 mt-3 pt-4 border-t border-[rgba(130, 130, 130, 0.7)] mb-3"
				onClick={() => setCreditScoreIsOpen((prev) => !prev)}
				onKeyUp={(e) => {
					if (e.key === "Enter" || e.key === " ") {
						setCreditScoreIsOpen((prev) => !prev);
					}
				}}>
					<div className="w-8">
						<CreditScoreIcon bg="white" />
					</div>
					<div className="text-z-shark text-[10px] font-medium">
						You’re doing 3 things really well - tackling the other 2 could boost
						your score
					</div>
				</div>

				<div className="mt-9">
					<div className="text-[#6541F8] font-bold">Suggestions</div>
					<div className="space-y-3 mt-3">
						<div className="bg-white rounded-2xl py-4 px-3 flex items-center space-x-3">
							<SuggestionsIcon fill="#D9D0FF" stroke="#6541F8" />
							<div className="text-xs font-medium">
								Lorem ipsum dolor sit amet, consectetur adipiscing
							</div>
						</div>
						<div className="bg-white rounded-2xl py-4 px-3 flex items-center space-x-3">
							<SuggestionsIcon fill="#D9D0FF" stroke="#6541F8" />
							<div className="text-xs font-medium">
								Lorem ipsum dolor sit amet, consectetur adipiscing
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { CreditScoreSection };

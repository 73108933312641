import { create } from "zustand";
import { persist } from "zustand/middleware";

export type PropertyType =
  | "detached"
  | "semi-detached"
  | "townhouse"
  | "condo townhouse"
  | "condo"
  | "other";

interface FilterPanelVisibility {
  filterPanelVisible: boolean;
  toggleFilterPanelVisible: () => void;
  showFilterPanel: () => void;
  hideFilterPanel: () => void;
  setFilterPanelOrigin: (value: string) => void;
  filterPanelOrigin: string;
}

export const useFilterPanelStore = create<FilterPanelVisibility>((set) => ({
  filterPanelOrigin: "",
  filterPanelVisible: false,
  toggleFilterPanelVisible: () =>
    set((state) => ({ filterPanelVisible: !state.filterPanelVisible })),
  showFilterPanel: () => set(() => ({ filterPanelVisible: true })),
  hideFilterPanel: () => set(() => ({ filterPanelVisible: false })),
  setFilterPanelOrigin: (value: string) => set({ filterPanelOrigin: value }),
}));

export interface SearchFilterValues {
  soldStatusType?: "forSale" | "sold";
  bedCount: string;
  bathCount: string;
  parking: string;
  denCount: string;
  priceRange: { minVal?: number; maxVal?: number };
  propertyType: string[];
  daysOnMarket: string;
  ageOfProperty: string;
  style: string;
  sqFt: string;
  basement: string;
  garageType: string;
  numberOfKitchens: string;
}

export interface MatchFilterValues {
  matchLocation: string[];
  matchLifestyle: string[];
  matchMustHaves: string[];
  matchNiceHaves: string[];
  matchBedCount: string[];
  matchBathCount: string[];
  matchParking: string[];
  matchDenCount: boolean | null;
  matchPropertyType: string[];
  setMatchLocation: (updateFn: (prevTypes: string[]) => string[]) => void;
  setMatchLifestyle: (updateFn: (prevTypes: string[]) => string[]) => void;
  setMatchMustHaves: (updateFn: (prevTypes: string[]) => string[]) => void;
  setMatchNiceHaves: (updateFn: (prevTypes: string[]) => string[]) => void;
  setMatchBedCount: (value: string[]) => void;
  setMatchBathCount: (value: string[]) => void;
  setMatchParking: (value: string[]) => void;
  setMatchDenCount: (value: boolean | null) => void;
  setMatchPropertyType: (updateFn: (prevTypes: string[]) => string[]) => void;
  clearAllMatchFilters: () => void;
  getAllMatchFilters: () => {
    matchLocation: string[];
    matchLifestyle: string[];
    matchMustHaves: string[];
    matchNiceHaves: string[];
    matchBedCount: string[];
    matchBathCount: string[];
    matchParking: string[];
    matchDenCount: boolean | null;
    matchPropertyType: string[];
  };
}

interface SearchFilters extends SearchFilterValues {
  setSoldStatusType?: (type: "forSale" | "sold") => void;
  setBedCount: (count: string) => void;
  setBathCount: (count: string) => void;
  setParking: (count: string) => void;
  setDenCount: (count: string) => void;
  setPriceRange: (range: { minVal?: number; maxVal?: number }) => void;
  setPropertyType: (updateFn: (prevTypes: string[]) => string[]) => void;
  setDaysOnMarket: (days: string) => void;
  setAgeOfProperty: (age: string) => void;
  setStyle: (style: string) => void;
  setSqFt: (sqFt: string) => void;
  setBasement: (basement: string) => void;
  setGarageType: (garageType: string) => void;
  setNumberOfKitchens: (numberOfKitchens: string) => void;
  clearAllPreferences: () => void;
  getAllPreferences: () => Partial<SearchFilters>;
}

export const useSearchFiltersStore = create(
  persist<SearchFilters>(
    (set, get) => ({
      // soldStatusType: "forSale",
      bedCount: "any",
      bathCount: "any",
      parking: "any",
      denCount: "any",
      priceRange: { minVal: 0, maxVal: 2000000 },
      propertyType: [],
      daysOnMarket: "Any",
      ageOfProperty: "Any",
      style: "Any",
      sqFt: "Any",
      basement: "Any",
      garageType: "Any",
      numberOfKitchens: "Any",
      // setSoldStatusType: (soldStatusType: "forSale" | "sold") =>
      //   set({ soldStatusType }),
      setBedCount: (bedCount: string) => set({ bedCount }),
      setBathCount: (bathCount: string) => set({ bathCount }),
      setParking: (parking: string) => set({ parking }),
      setDenCount: (denCount: string) => set({ denCount }),
      setPriceRange: (range) => set({ priceRange: range }),
      setPropertyType: (updateFn) =>
        set((state) => ({
          propertyType: updateFn(state.propertyType),
        })),
      setDaysOnMarket: (daysOnMarket: string) => set({ daysOnMarket }),
      setAgeOfProperty: (ageOfProperty: string) => set({ ageOfProperty }),
      setStyle: (style: string) => set({ style }),
      setSqFt: (sqFt: string) => set({ sqFt }),
      setBasement: (basement: string) => set({ basement }),
      setGarageType: (garageType: string) => set({ garageType }),
      setNumberOfKitchens: (numberOfKitchens: string) =>
        set({ numberOfKitchens }),
      clearAllPreferences: () =>
        set({
          soldStatusType: "forSale",
          bedCount: "any",
          bathCount: "any",
          parking: "any",
          denCount: "any",
          priceRange: { minVal: 0, maxVal: 2000000 },
          propertyType: [],
          daysOnMarket: "Any",
          ageOfProperty: "Any",
          style: "Any",
          sqFt: "Any",
          basement: "Any",
          garageType: "Any",
          numberOfKitchens: "Any",
        }),
      getAllPreferences: () => {
        const {
          bedCount,
          bathCount,
          parking,
          denCount,
          priceRange,
          propertyType,
          daysOnMarket,
          ageOfProperty,
          style,
          sqFt,
          basement,
          garageType,
          numberOfKitchens,
        } = get();

        return {
          bedCount,
          bathCount,
          parking,
          denCount,
          priceRange,
          propertyType,
          daysOnMarket,
          ageOfProperty,
          style,
          sqFt,
          basement,
          garageType,
          numberOfKitchens,
        };
      },
    }),
    {
      name: "search-filters-storage",
    }
  )
);

export const useMatchFiltersStore = create<MatchFilterValues>((set, get) => ({
  matchLocation: [],
  matchLifestyle: [],
  matchMustHaves: [],
  matchNiceHaves: [],
  matchBedCount: [],
  matchBathCount: [],
  matchParking: [],
  matchDenCount: null,
  matchPropertyType: [] as string[],
  setMatchLocation: (updateFn: (prevTypes: string[]) => string[]) =>
    set((state) => ({
      matchLocation: updateFn(state.matchLocation ?? []),
    })),
  setMatchLifestyle: (updateFn: (prevTypes: string[]) => string[]) =>
    set((state) => ({
      matchLifestyle: updateFn(state.matchLifestyle ?? []),
    })),
  setMatchMustHaves: (updateFn: (prevTypes: string[]) => string[]) =>
    set((state) => ({
      matchMustHaves: updateFn(state.matchMustHaves ?? []),
    })),
  setMatchNiceHaves: (updateFn: (prevTypes: string[]) => string[]) =>
    set((state) => ({
      matchNiceHaves: updateFn(state.matchNiceHaves ?? []),
    })),

  setMatchBedCount: (value: string[]) => set({ matchBedCount: value }),
  setMatchBathCount: (value: string[]) => set({ matchBathCount: value }),
  setMatchParking: (value: string[]) => set({ matchParking: value }),

  setMatchDenCount: (value: boolean | null) => set({ matchDenCount: value }),

  setMatchPropertyType: (updateFn: (prevTypes: string[]) => string[]) => {
    const { matchPropertyType } = get();
    const updatedTypes = updateFn(matchPropertyType);
    set({
      matchPropertyType: updatedTypes,
    });
  },

  clearAllMatchFilters: () =>
    set({
      matchBedCount: [],
      matchBathCount: [],
      matchParking: [],
      matchDenCount: null,
      matchPropertyType: [],
    }),

  getAllMatchFilters: () => {
    const {
      matchLocation,
      matchLifestyle,
      matchMustHaves,
      matchNiceHaves,
      matchBedCount,
      matchBathCount,
      matchParking,
      matchDenCount,
      matchPropertyType,
    } = get();

    return {
      matchLocation,
      matchLifestyle,
      matchMustHaves,
      matchNiceHaves,
      matchBedCount,
      matchBathCount,
      matchParking,
      matchDenCount,
      matchPropertyType,
    };
  },
}));

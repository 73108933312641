import { create } from "zustand";
import { pointsOfInterest } from "../components/PointsOfInterestView/data";

interface Priorities {
  id: string;
  title: string;
}

interface City {
  icon?: string;
  type?: string;
  id: number;
  name: string;
  latitude?: number;
  longitude?: number;
}

const recommendedCities: City[] = [
  { id: 11, name: "Pickering, ON" },
  { id: 12, name: "Ajax, ON" },
  { id: 13, name: "Whitby, ON" },
  { id: 14, name: "Oshawa, ON" },
];
interface HomeFeaturesState {
  selectedBed: string;
  setSelectedBed: (selectedBed: string) => void;
  selectedBath: string;
  setSelectedBath: (selectedBath: string) => void;
  selectedParking: string;
  setSelectedParking: (selectedParking: string) => void;
  selectedDens: string;
  setSelectedDens: (selectedDens: string) => void;
  propertySearchingPrice: number[];
  setPropertySearchingPrice: (propertySearchingPrice: number[]) => void;
  propertySearchingBeds: string;
  setPropertySearchingBeds: (propertySearchingPrice: string) => void;
  propertyCitySelectionError: boolean;
  setPropertyCitySelectionError: (propertyCitySelectionError: boolean) => void;
  validationPropertyCitySelection: () => boolean;
  selectedCities: City[];
  setSelectedCities: (updater: (prev: City[]) => City[]) => void;
  remainingRecommendedCities: City[];
  setRemainingRecommendedCities: (updater: (prev: City[]) => City[]) => void;
  selectedStationLat: number;
  selectedStationLon: number;
  selectedSchoolLat: number;
  selectedSchoolLon: number;
  selectedWorkLat: number;
  selectedWorkLon: number;
  selectedAddresses: City[];
  setSelectedAddresses: (updater: (prev: City[]) => City[]) => void;
  selectedStation: string;
  setSelectedStation: (
    selectedStation: string,
    lat: number,
    lon: number
  ) => void;
  selectedSchool: string;
  setSelectedSchool: (selectedSchool: string, lat: number, lon: number) => void;
  placeWorship: string;
  setPlaceWorship: (placeWorship: string) => void;
  selectedWorkAddress: string;
  setSelectedWorkAddress: (
    selectedWorkAddress: string,
    lat: number,
    lon: number
  ) => void;
  pointsInterestError: boolean;
  setPointsInterestError: (pointsInterestError: boolean) => void;
  remainingPopularPoints: City[];
  setRemainingPopularPoints: (
    updater: (remainingPopularPoints: City[]) => City[]
  ) => void;
  selectedPoints: City[];
  setSelectedPoints: (updater: (selectedPoints: City[]) => City[]) => void;
  selectedLifestyle: string[];
  setSelectedLifestyle: (selectedLifestyle: string[]) => void;
  validationPointsInteres: () => boolean;
  mustHavesSelect: string[];
  setMustHavesSelect: (mustHavesSelect: string[]) => void;
  niceHavesSelect: string[];
  setNiceHavesSelect: (niceHavesSelect: string[]) => void;
  validationNeedsVsWants: () => boolean;
  priorities: Priorities[];
  setPriorities: (priorities: Priorities[]) => void;
}

const useHomeFeaturesStore = create<HomeFeaturesState>((set) => ({
  selectedBed: "Any",
  setSelectedBed: (selectedBed: string) => set({ selectedBed }),
  selectedBath: "Any",
  setSelectedBath: (selectedBath: string) => set({ selectedBath }),
  selectedParking: "Any",
  setSelectedParking: (selectedParking: string) => set({ selectedParking }),
  selectedDens: "Any",
  setSelectedDens: (selectedDens: string) => set({ selectedDens }),
  propertySearchingPrice: [500],
  setPropertySearchingPrice: (propertySearchingPrice: number[]) =>
    set({ propertySearchingPrice }),
  propertySearchingBeds: "Semi-Detached",
  setPropertySearchingBeds: (propertySearchingBeds: string) =>
    set({ propertySearchingBeds }),
  propertyCitySelectionError: false,
  setPropertyCitySelectionError: (propertyCitySelectionError: boolean) =>
    set({ propertyCitySelectionError }),
  selectedCities: [],
  setSelectedCities: (updater) =>
    set((state) => ({
      selectedCities:
        typeof updater === "function" ? updater(state.selectedCities) : updater,
    })),

  selectedAddresses: [],
  setSelectedAddresses: (updater) =>
    set((state) => ({
      selectedAddresses:
        typeof updater === "function"
          ? updater(state.selectedAddresses)
          : updater,
    })),

  selectedPoints: [],
  setSelectedPoints: (updater) =>
    set((state) => ({
      selectedPoints:
        typeof updater === "function" ? updater(state.selectedPoints) : updater,
    })),
  selectedStation: "",
  selectedStationLat: 0,
  selectedStationLon: 0,
  setSelectedStation: (selectedStation: string, lat: number, lon: number) =>
    set({
      selectedStation,
      selectedStationLat: lat,
      selectedStationLon: lon,
    }),

  selectedSchool: "",
  selectedSchoolLat: 0,
  selectedSchoolLon: 0,
  
  setSelectedSchool: (selectedSchool: string, lat: number, lon: number) =>
    set({
      selectedSchool,
      selectedSchoolLat: lat,
      selectedSchoolLon: lon,
    }),
  placeWorship: "",
  setPlaceWorship: (placeWorship: string) => set({ placeWorship }),

  selectedWorkAddress: "",
  selectedWorkLat: 0,
  selectedWorkLon: 0,
  setSelectedWorkAddress: (
    selectedWorkAddress: string,
    lat: number,
    lon: number
  ) => set({ selectedWorkAddress, selectedWorkLat: lat, selectedWorkLon: lon }),

  remainingRecommendedCities: recommendedCities,
  setRemainingRecommendedCities: (updater) =>
    set((state) => ({
      remainingRecommendedCities:
        typeof updater === "function"
          ? updater(state.remainingRecommendedCities)
          : updater,
    })),
  pointsInterestError: false,
  setPointsInterestError: (pointsInterestError: boolean) =>
    set({ pointsInterestError }),
  remainingPopularPoints: pointsOfInterest,
  setRemainingPopularPoints: (updater) =>
    set((state) => ({
      remainingPopularPoints:
        typeof updater === "function"
          ? updater(state.remainingPopularPoints)
          : updater,
    })),

  selectedLifestyle: [],
  setSelectedLifestyle: (selectedLifestyle: string[]) =>
    set({ selectedLifestyle }),

  mustHavesSelect: [],
  setMustHavesSelect: (mustHavesSelect: string[]) => set({ mustHavesSelect }),
  niceHavesSelect: [],
  setNiceHavesSelect: (niceHavesSelect: string[]) => set({ niceHavesSelect }),
  priorities: [],
  setPriorities: (priorities: Priorities[]) => set({ priorities }),

  validationNeedsVsWants: () => {
    const { mustHavesSelect, niceHavesSelect } =
      useHomeFeaturesStore.getState();
    let isValid = true;
    if (niceHavesSelect.length > 2 && mustHavesSelect.length > 2) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  },

  validationPointsInteres: () => {
    const {
      setPointsInterestError,
      selectedAddresses,
      selectedStation,
      selectedSchool,
      selectedWorkAddress,
      placeWorship,
    } = useHomeFeaturesStore.getState();
    let isValid = true;
    if (
      selectedAddresses.length > 0 ||
      selectedStation !== "" ||
      selectedSchool !== "" ||
      selectedWorkAddress !== "" ||
      placeWorship !== ""
    ) {
      isValid = true;
      setPointsInterestError(false);
    } else {
      isValid = false;
      setPointsInterestError(true);
    }
    return isValid;
  },

  validationPropertyCitySelection: () => {
    const { selectedCities, setPropertyCitySelectionError } =
      useHomeFeaturesStore.getState();
    let isValid = true;
    if (selectedCities.length > 0) {
      setPropertyCitySelectionError(false);
      isValid = true;
    } else {
      setPropertyCitySelectionError(true);
      isValid = false;
    }
    return isValid;
  },
}));

interface Step {
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
}

const useStep = create<Step>((set) => ({
  currentStep: 0,
  setCurrentStep: (state: number) =>
    set({
      currentStep: state,
    }),
}));

export { useHomeFeaturesStore, useStep };

import { BiChevronDown, BiChevronLeft } from "react-icons/bi";
import { BungalowHouse } from "../../assets/FinancesImg";
import { type ReactNode, useEffect, useRef, useState } from "react";
import { getTrackBackground, Range } from "react-range";
import { formatPrice } from "../../utils/formatPrice";
import { GrLocation } from "react-icons/gr";
import clsx from "clsx";
import { useModalStore } from "../../stores/handleModalStore";
import { financeData } from "../../mocks/financeData";

const STEP = 1;
const MIN = 0;
const MAX = 100;

interface FinancialContainerProps {
  bgBase: string;
  bgLabel: string;
  title: string;
  color?: string;
  price: number;
  desc: string;
  children: ReactNode;
  suffix?: string;
}

const FinancialContainer = ({
  bgBase,
  suffix,
  color = "white",
  bgLabel,
  title,
  price,
  desc,
  children,
}: FinancialContainerProps) => {
  return (
    <div
      style={{ background: bgBase }}
      className="shadow-[0_0_6px_0_rgba(0,0,0,0.20)] rounded-lg bg-[#F5FEEF] p-4"
    >
      <div className="flex items-center justify-between">
        <span className="font-semibold text-sm">{title}</span>
        <div
          style={{ color: color, background: bgLabel }}
          className="px-3 py-1 rounded-2xl text-white font-semibold text-sm"
        >
          ${formatPrice(price.toString())}
          {suffix}
        </div>
      </div>
      <div className="text-xs mt-3">{desc}</div>
      {children}
    </div>
  );
};

const FinancialReport = () => {
  const { setFinancialReportOverPage, financialReportOverPage } =
    useModalStore();
  const [incomeRange, setIncomeRange] = useState<number[]>(
    financeData?.financeReport?.financialData[0].incomeRange ?? [0]
  );
  const [savingsRange, setSavingsRange] = useState<number[]>(
    financeData?.financeReport?.financialData[1].savingRange ?? [30, 60]
  );
  const [feesIsOpen, setFeesIsOpen] = useState<boolean>(true);
  const [left, setLeft] = useState(130);
  const draggableRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current && draggableRef.current) {
      const hammer = new Hammer(draggableRef.current);
      hammer.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL });

      const onPan = (event:HammerInput) => {
        if (!containerRef.current || !draggableRef.current) return;
        const containerWidth = containerRef.current.offsetWidth;
        
        let newLeft = event.deltaX;

        if (newLeft < 0) newLeft = 0;
        if (newLeft > containerWidth - draggableRef.current.offsetWidth) {
          newLeft = containerWidth - draggableRef.current.offsetWidth;
        }

        setLeft(newLeft);
      };

      hammer.on('pan', onPan);
     
    }
  }, [left]);

  const path = financeData?.financeReport?.financialData || []

  return (
    <>
      {financialReportOverPage && (
        <div
          className={`fixed inset-0 overflow-y-auto bg-white p-6 z-30 text-z-shark w-full ${
            window.bridge ? "pt-24 pb-bridge-inset-bottom" : "pt-20 pb-6"
          }`}
        >
          <div
            className={`absolute  left-5  ${
              window.bridge ? "top-bridge-inset-top" : "top-5"
            }`}
            onClick={() => setFinancialReportOverPage(false)}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                setFinancialReportOverPage(false);
              }
            }}
          >
            <BiChevronLeft className="text-3xl" />
          </div>
          <div className="flex flex-col">
            <div className="text-sm">Your personalized financial report</div>
            <div className="mt-2 text-3xl font-semibold">
              Let’s break it down
            </div>
            <div className="shadow-[0_0_6px_0_rgba(0,0,0,0.20)] rounded-lg p-4 flex items-center justify-between mt-6 mb-3">
              <div className="flex flex-col">
                <div className="font-semibold text-sm">
                  Your current home goal is
                </div>
                <div className="bg-z-dark-moss-green py-1 px-2 text-white text-3xl font-bold w-fit rounded-xl mt-2">
                  ${formatPrice(financeData?.financeReport?.currentHomeGoal ?? 0)}
                </div>
              </div>
              <img src={BungalowHouse} width={110} alt="bungalow house" />
            </div>
            <div className="space-y-3">
              <FinancialContainer
                bgLabel="#456930"
                bgBase="#F5FEEF"
                title={path[0].title}
                price={path[0].price}
                desc={path[0].desc}
              >
                <Range
                  values={incomeRange}
                  step={STEP}
                  min={MIN}
                  max={MAX}
                  disabled={true}
                  onChange={(values) => setIncomeRange(values)}
                  renderTrack={({ props, children }) => {
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    const { key, ...restProps } = props as any;
                    return (
                      <div
                        key={key}
                        {...restProps}
                        className="w-full h-3 rounded-3xl bg-gray-300 mt-4"
                        style={{
                          background: getTrackBackground({
                            values: incomeRange,
                            colors: ["#456930", "#E8E8E8"],
                            min: MIN,
                            max: MAX,
                          }),
                        }}
                      >
                        {children}
                      </div>
                    );
                  }}
                  renderThumb={({ props }) => {
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    const { key, ...restProps } = props as any;
                    return <div key={key} {...restProps} className="" />;
                  }}
                />
                <div className="bg-white mt-4 rounded-md px-6 py-3 flex flex-col space-y-3">
                  <div className="text-sm font-semibold">
                    Income Requirements
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-opacity-70 text-xs">
                      Required Income
                    </div>
                    <div className="text-sm font-semibold">${formatPrice(path[0]?.incomeRequirements?.requiredIncome ?? 0)}/YR</div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-opacity-70 text-xs">
                      Current Income
                    </div>
                    <div className="text-sm font-semibold">${formatPrice(path[0]?.incomeRequirements?.currentIncome ?? 0)}/YR</div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-opacity-70 text-xs">Gap</div>
                    <div className="text-[#DE1212E5] text-sm font-semibold">
                      -${formatPrice(financeData?.financeReport?.financialData[0]?.incomeRequirements?.gap ?? 0)}/YR
                    </div>
                  </div>
                </div>
              </FinancialContainer>

              <FinancialContainer
                bgLabel="#012A43"
                bgBase="#EBF7FE"
                title={path[1].title}
                price={path[1].price}
                desc={path[1].desc}
              >
                <Range
                  values={savingsRange}
                  step={STEP}
                  min={MIN}
                  max={MAX}
                  disabled={true}
                  onChange={(values) => setSavingsRange(values)}
                  renderTrack={({ props, children }) => {
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    const { key, ...restProps } = props as any;
                    return (
                      <div
                        key={key}
                        {...restProps}
                        className="w-full h-3 rounded-3xl bg-gray-300 mt-4"
                        style={{
                          background: getTrackBackground({
                            values: savingsRange,
                            colors: ["#012A43", "#456930", "#E8E8E8"],
                            min: MIN,
                            max: MAX,
                          }),
                        }}
                      >
                        {children}
                      </div>
                    );
                  }}
                  renderThumb={({ props }) => {
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    const { key, ...restProps } = props as any;
                    return <div key={key} {...restProps} className="" />;
                  }}
                />
                <div className="bg-white mt-4 rounded-md px-6 py-3 flex flex-col space-y-3">
                  <div className="text-sm font-semibold">Home Based Costs</div>
                  <div className="flex items-center justify-between">
                    <div className="text-opacity-70 text-xs">
                      Minimum Down Payment
                    </div>
                    <div className="text-sm font-semibold">${formatPrice(path[1].homeBasedCosts?.minimumDownPayment ?? 0)}</div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-opacity-70 text-xs">
                      Down Payment Boost
                    </div>
                    <div className="text-sm font-semibold">-${formatPrice(path[1].homeBasedCosts?.downPaymentBoost ?? 0)}</div>
                  </div>
                  <div
                    className={clsx(
                      "border border-[#BBB] shadow-[0_0_4px_0_rgba(0,0,0,0.12)] rounded-xl overflow-hidden transition-height duration-300 ease-in-out",
                      feesIsOpen ? "h-[145px]" : "h-[40px]"
                    )}
                  >
                    <div className="flex justify-between pr-4 pl-2 items-center h-[40px] border-b border-[#BBB]" 
                      onClick={() => setFeesIsOpen((prev) => !prev)} 
                      onKeyDown={(event) => {
                        if (event.key === "Enter" || event.key === " ") {
                          setFeesIsOpen((prev) => !prev)
                        }
                      }}
                    >
                      <div
                        className="items-center flex space-x-1"
                      >
                        <div
                          className={clsx(
                            !feesIsOpen && "rotate-180",
                            "duration-300 ease-in-out"
                          )}
                        >
                          <BiChevronDown className="text-2xl" />
                        </div>
                        <span className="text-xs">Other Fees*</span>
                      </div>
                      <span className="text-sm">${formatPrice(path[1].otherFeesPrice ?? 0)}</span>
                    </div>
                    <div className="text-xs p-4 space-y-3 bg-[#FAFAFA]">
                      <div className="flex items-center justify-between">
                        <div className="text-z-shark text-opacity-70">
                          Legal Costs & Title Insurance
                        </div>
                        <div className="">${formatPrice(path[1].otherFees?.legalCosts ?? 0)}</div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="text-z-shark text-opacity-70">
                          PST on CMHC Insurance
                        </div>
                        <div className="">${formatPrice(path[1].otherFees?.PST ?? 0)}</div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="text-z-shark text-opacity-70">
                          Land Transfer Tax
                        </div>
                        <div className="">${formatPrice(path[1].otherFees?.landTransferTax ?? 0)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </FinancialContainer>

              <FinancialContainer
                suffix={"/m"}
                color="#32302F"
                bgLabel="#FECD07"
                bgBase="#FEECEB"
                title={path[2].title}
                price={path[2].price}
                desc={path[2].desc}
              >
                <div className="bg-white px-4 h-[60px] flex items-center justify-center w-full rounded-2xl mt-4">
                  <div className="flex items-center w-full relative mt-5" ref={containerRef}>
                    <div className="w-1/3 flex flex-col">
                      <div className="bg-[#37C206] h-3 rounded-l-2xl"></div>
                      <div className="text-[8px] text-center mt-[2px]">
                        Affordable
                      </div>
                    </div>
                    <div className="w-1/3 flex flex-col">
                      <div className="bg-[#FECD07] h-3"></div>
                      <div className="text-[8px] text-center mt-[2px]">
                        Stretching
                      </div>
                    </div>
                    <div className="w-1/3 flex flex-col">
                      <div className="bg-[#F00] h-3 rounded-r-2xl"></div>
                      <div className="text-[8px] text-center mt-[2px]">
                        Aggressive
                      </div>
                    </div>
                    <div
                      ref={draggableRef}
                      style={{ left: `${left}px` }}
                      className="absolute -top-5 w-[2px]"
                    >
                        <div className="items-center flex flex-col justify-center relative">
                          <GrLocation />
                          <span className="text-[6px] whitespace-nowrap flex items-center pt-[2px] absolute top-0 left-[9px]">
                            You are here
                          </span>
                          <div className="h-3 mt-1 w-1 border-dashed border-l border-[#656463]"/>
                        </div>
                        
                    </div>
                  </div>
                </div>
              </FinancialContainer>
              <div className="w-full h-[50px] flex items-center justify-between bg-z-dark-moss-green text-white px-4 rounded-lg">
                <div className="text-sm font-semibold">
                  Est. Monthly Payments
                </div>
                <div className="font-bold">${formatPrice(financeData?.financeReport?.monthlyPayments ?? 0)}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FinancialReport;

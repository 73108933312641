import { type ChangeEvent, useEffect, useRef, useState } from "react";
import { InfoIcon, MinusIcon, MoneyIcon, PlusIcon } from "../../assets/icons";

import { Button, Select } from "../common";
import { formatPrice } from "../../utils/formatPrice";
import { getTrackBackground, Range } from "react-range";
import { setupHammer } from "./setupHammer";
import { FiTrash2 } from "react-icons/fi";
import { useModalStore } from "../../stores/handleModalStore";
import { Finance, FinancialItemDetail } from "../../interfaces/finance";
import { finance } from "../../services/finance";



const stationOptions = [
	{ value: "Friend", label: "Friend" },
	{ value: "Family", label: "Family" },
	{ value: "Other", label: "Other" },
];

const STEP = 1;
const MIN = 0;
const MAX = 100000;

const IncomeSection = () => {
	const {setFinanceIncomeModal} = useModalStore()
	const [incomeIsOpen, setIncomeIsOpen] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [values, setValues] = useState<number[]>([60000]);

	const [fullName, setFullName] = useState("");
	const [yearlyIncome, setyearlyIncome] = useState("");
	const [relationshipSelect, setRelationshipSelect] = useState("");

	const [addAnotherCoBuyer, setAddAnotherCoBuyer] = useState(true);

	const [addGiftList, setAddGiftList] = useState<FinancialItemDetail[]>([]);

	const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const numericValue = e.target.value
			.replace(/[^0-9]/g, "")
			.replace(/^0+(?=\d)/, "");
		if (numericValue) {
			const formattedAmout = formatPrice(numericValue);
			setyearlyIncome(formattedAmout);
		} else {
			setyearlyIncome("");
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (fullName !== "" && yearlyIncome !== "" && relationshipSelect !== "") {
			setDisableButton(true);
		} else {
			setDisableButton(false);
		}
	}, [fullName, yearlyIncome, relationshipSelect]);

	const handleSaveData = async() => {
		setAddAnotherCoBuyer(false);
		const newGiftData: FinancialItemDetail = {
			id: Date.now(),
			fullName,
			amount:yearlyIncome,
			relationshipSelect,
		};
		setAddGiftList((prev) => [...prev, newGiftData]);
		setFullName("");
		setyearlyIncome("");
		setRelationshipSelect("");
		const payload = {
			financeIncome: [...addGiftList, newGiftData]
		}
		console.log(payload);
		
		await finance(payload)
	};

	const [activeSwipe, setActiveSwipe] = useState<string | null>(null);

	const handleDelete = async(id: number) => {
		setAddGiftList((prevList) =>
			prevList.filter((item) => item.id !== id),
		);
		setActiveSwipe(null);
		const updatedPayload: Finance = {
			financeIncome: [...addGiftList.filter(item => item.id !== id)],
		};
		console.log(updatedPayload);
    	await finance(updatedPayload);
	};

	const handleSwipeLeft = (el: HTMLDivElement) => {
		const fullName = el.querySelector(".font-bold")?.textContent;
		if (fullName) {
			setActiveSwipe(fullName);
		}
	};

	const handleSwipeRight = () => {
		setActiveSwipe(null);
	};

	const incomeRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (incomeIsOpen) {
				incomeRef?.current?.scrollIntoView({behavior:'smooth'})
		} else {
			incomeRef?.current?.scrollIntoView({behavior:'smooth', block:'center'})
		}
	} , [incomeIsOpen])

	return (
		<div className="shadow-[0_0_6px_0_rgba(0,0,0,0.15)] rounded-[20px] w-full py-5 px-6 mt-10">
			<div className="flex items-center justify-between">
				<div className="flex items-center space-x-1">
					<img width={28} src={MoneyIcon} alt="money icon" />
					<div className="text-z-dark-moss-green text-xl font-semibold">
						Income
					</div>
				</div>
				<div className="flex items-center">
					<div className="flex items-center mr-1">
						<span className="text-z-shark text-lg font-bold">$60,000</span>
						<div className="px-2 text-[#828282]">/</div>
						<span className="font-medium text-[#828282]">$100,000</span>
					</div>
					<div
						onClick={() => setFinanceIncomeModal(true)}
						onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								setFinanceIncomeModal(true)
							}
						}}
						>
						<InfoIcon fill="#456930"/>
					</div>
				</div>
			</div>
			<Range
				values={values}
				step={STEP}
				min={MIN}
				max={MAX}
				disabled={true}
				onChange={(values) => setValues(values)}
				renderTrack={({ props, children }) => {
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					const { key, ...restProps } = props as any;
					return (
						<div
							key={key}
							{...restProps}
							className="w-full h-6 rounded-3xl bg-gray-300 mt-9 mb-5"
							style={{
								background: getTrackBackground({
									values,
									colors: ["#456930", "#B7D0A8"],
									min: MIN,
									max: MAX,
								}),
							}}
						>
							{children}
						</div>
					);
				}}
				renderThumb={({ props }) => {
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					const { key, ...restProps } = props as any;
					return <div key={key} {...restProps} className="" />;
				}}
			/>
			{incomeIsOpen ? (
				<div className="bg-[#E7F1E2] rounded-2xl flex items-center overflow-hidden flex-col px-4 py-5 relative h-auto mt-3" ref={incomeRef}>
					<div className="w-full">
						<div className="flex items-center justify-between">
							<div className="text-z-dark-moss-green font-bold">
								Add a co-buyer to reach goal
							</div>
							<div
							className="w-8 h-8 flex items-center justify-end"
							onClick={() => {
								setIncomeIsOpen((prev) => !prev)
								if (addGiftList.length > 0) {
									setAddAnotherCoBuyer(false)
								} else {
									setAddAnotherCoBuyer(true)
								}
							}}
							onKeyUp={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									setIncomeIsOpen((prev) => !prev)
									if (addGiftList.length > 0) {
										setAddAnotherCoBuyer(false)
									} else {
										setAddAnotherCoBuyer(true)
									}
								}
							}}
						>
							<MinusIcon fill="#456930" />
						</div>
						</div>
						{addAnotherCoBuyer ? (
							<>
								<div className="mt-7 space-y-4">
									<label className="text-z-shark font-medium">Full Name</label>
									<input
										type="text"
										spellCheck="false"
										autoComplete="off"
										className="w-full rounded-xl px-2 py-3 bg-[#fff]"
										value={fullName}
										onChange={(e: ChangeEvent<HTMLInputElement>) =>
											setFullName(e.target.value)
										}
									/>
								</div>
								<div className="mt-7 space-y-4">
									<div className="text-z-shark font-medium">Relationship</div>
									<Select
										options={stationOptions}
										value={relationshipSelect}
										onChange={setRelationshipSelect}
										placeholder="Select one"
										customClassName="bg-white h-[52px]"
										customClassNameUl="!top-[55px]"
										iconColor="#456930"
									/>
								</div>
								<div className="mt-7 space-y-4">
									<label className="text-z-shark font-medium">
										Yearly Income{" "}
										<span className="text-[#828282] text-xs">(before tax)</span>
									</label>
									<input
										type="tel"
										spellCheck="false"
										autoComplete="off"
										className="w-full rounded-xl px-2 py-3 bg-[#fff]"
										value={
											yearlyIncome === ""
												? `${yearlyIncome}`
												: `$${yearlyIncome}`
										}
										onChange={handleAmountChange}
										placeholder="$50,000"
									/>
								</div>
								<Button
									onClick={handleSaveData}
									width="full"
									height="md"
									radius="50px"
									disable={!disableButton}
									customClassName="font-semibold mt-5"
									disableClass="!bg-[#D9D9D9] !text-[#9D9D9D]"
								>
									Save
								</Button>
							</>
						) : (
							<div className="flex items-center flex-col">
								{addGiftList.map((item) => (
									<div
										key={item.fullName}
										className={`flex items-center justify-between text-z-shark w-full mt-6 relative transition-transform duration-300 ${
											activeSwipe === item.fullName
												? "transform -translate-x-[60px]"
												: ""
										}`}
										ref={(el) => {
											if (el) {
												setupHammer(
													el,
													() => {},
													handleSwipeLeft,
													handleSwipeRight,
												);
											}
										}}
									>
										<div className="flex flex-col space-y-1">
											<div className="font-bold">{item.fullName}</div>
											<div className="font-normal text-xs">
												{item.relationshipSelect}
											</div>
										</div>
										<div className="font-bold">${item.amount}</div>
										<div
											className="delete-icon w-[60px] h-[50px] flex items-center justify-center bg-red-500 text-white absolute -right-[80px] top-0"
											onClick={() => handleDelete(item.id)}
											onKeyUp={(e) => {
												if (e.key === "Enter" || e.key === " ") {
													handleDelete(item.id);
												}
											}}
										>
											<FiTrash2 className="text-2xl" />
										</div>
									</div>
								))}
								<div
									className="w-full border-t-2 border-[#91B979] flex items-center mt-10 pt-5 space-x-3"
									onClick={() => setAddAnotherCoBuyer(true)}
									onKeyUp={(event) => {
										if (event.key === "Enter" || event.key === " ") {
											setAddAnotherCoBuyer(true);
										}
									}}
								>
									<PlusIcon strokeWidth="2.5px" fill="#456930" />
									<div className="text-z-dark-moss-green font-semibold text-sm text">
										Add another co-buyer
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			) : (
				<div
					className="flex items-center bg-[#E7F1E2] h-[60px] px-4 rounded-2xl space-x-4"
					onClick={() => setIncomeIsOpen(true)}
					onKeyUp={(event) => {
						if (event.key === "Enter" || event.key === " ") {
							setIncomeIsOpen(true);
						}
					}}
				>
					<PlusIcon strokeWidth="2.5" fill="#456930" />
					<div className="text-z-dark-moss-green text-sm font-semibold">
						Add a co-buyer to reach goal
					</div>
				</div>
			)}
		</div>
	);
};

export { IncomeSection };

import { useModalStore } from "../../../stores/handleModalStore";
import { Button } from "../../common";
import { Modal } from "../../common/modal";

const StartHomeShoppingModal = () => {
  const { setStartShoppingModal, startShoppingModal } = useModalStore();
  return (
    <Modal
      closeIcon={true}
      setIsOpen={setStartShoppingModal}
      isOpen={startShoppingModal}
    >
      <div className="flex flex-col">
        <div className="text-z-shark text-xl font-bold text-[14px]">
          Start Home Shopping
        </div>
        <p className="text-[#828282] mt-5 leading-5 font-light">
          Begin your pre-approval process through our trusted mortgage partners,
          connected to A-lender banks. A pre-approval is necessary for you to go
          on showings with our realtor network, so you’re one step closer to
          finding your new home.
        </p>
        <Button
          radius="50px"
          height="md"
          width="full"
          customClassName="font-bold text-lg mt-5"
          onClick={() => setStartShoppingModal(false)}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export { StartHomeShoppingModal };

import clsx from "clsx";
import type { ReactNode } from "react";

interface ButtonOptions {
	radius?: string;
	children: ReactNode;
	onClick?: () => void;
	width: "fit" | "full" | string;
	variant?: "primary" | "dark" | "blue";
	isOutlined?: boolean;
	height?: "md" | "lg" | string;
	startIcon?: ReactNode;
	endIcon?: ReactNode;
	textAlign?: string;
	customClassName?: string;
	disable?: boolean;
	disableClass?: string;
}

const Button = ({
	radius = "20px",
	children,
	onClick,
	width,
	variant = "primary",
	isOutlined,
	height,
	startIcon,
	endIcon,
	textAlign = "justify-center",
	customClassName,
	disable,
	disableClass,
}: ButtonOptions) => {
	const variantStyles = isOutlined
		? {
				primary:
					"border-2 border-z-dark-moss-green text-z-dark-moss-green bg-transparent",
				dark: "border-2 border-z-shark text-z-shark bg-transparent",
				blue: "border-2 border-[#0075FF] text-[#0075FF] bg-transparent",
			}
		: {
				primary: "bg-z-dark-moss-green text-white",
				dark: "bg-z-shark text-white",
				blue: "bg-[#0075FF] text-white",
			};

	return (
		<button
			disabled={disable}
			type="button"
			className={clsx(
				"flex items-center px-4 py-2",
				variantStyles[variant],
				disable && "!bg-[#F4F4F4] !text-[#6B6B6B] pointer-events-none",
				disable && disableClass,
				textAlign,
				!isOutlined && "text-white",
				height === "md" && "h-[56px]",
				height === "lg" && "h-[66px]",
				width === "full" && "w-full",
				width === "fit" && "w-fit",
				customClassName,
			)}
			onClick={onClick}
			style={{ borderRadius: radius, width: width }}
		>
			{startIcon && (
				<img className="mr-4" src={`${startIcon}`} alt="start icon" />
			)}
			{children}
			{endIcon && <img className="ml-4" src={`${endIcon}`} alt="end icon" />}
		</button>
	);
};

export { Button };

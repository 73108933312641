import clsx from "clsx";
import { useFinanceStore } from "../../../stores/financeStore";
import { VerticalLine } from "./verticalLine";
import { CheckMarkerIcon } from "../../../assets/icons";

const ManageSteps = () => {
	const { completedSteps } = useFinanceStore();
	return (
		<div className=" flex items-cente">
			{[1, 2, 3, 4].map((step) => (
				<div
					key={step}
					className={clsx(
						"w-6 h-6 mr-5 bg-white last:mr-0 relative rounded-full border-2 border-[#B5C9A9] flex items-cente justify-center",
						completedSteps[step] &&
							"bg-z-dark-moss-green border-z-dark-moss-green",
					)}
				>
					<img src={CheckMarkerIcon} width={12} alt="" />
					{step !== 4 && <VerticalLine stepType={true} />}
				</div>
			))}
		</div>
	);
};

export { ManageSteps };

import type { ChangeEvent } from "react";
import ArrowRightBlack from "../../../assets/arrowRightBlack.svg";
import { formatPrice } from "../../../utils/formatPrice";
import { Button } from "../../common";
import { useFinanceStore } from "../../../stores/financeStore";
import { useModalStore } from "../../../stores/handleModalStore";

interface OverPageProps {
  setTotalDebt: (totalDebt: string) => void;
}

type FormValues = {
  carLoans: string;
  studentLoans: string;
  creditCards: string;
  lineOfCredit: string;
};

const DebtOverPage = ({ setTotalDebt }: OverPageProps) => {
  const { debtFormValues, setDebtFormValue } = useFinanceStore();
  const { debtOverPageState, setDebtOverPageState } = useModalStore();

  const inputFields: { label: string; name: keyof FormValues }[] = [
    { label: "Car Loans", name: "carLoans" },
    { label: "Student Loans", name: "studentLoans" },
    { label: "Credit Cards", name: "creditCards" },
    { label: "Line of Credit", name: "lineOfCredit" },
  ];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDebtFormValue(name as keyof typeof debtFormValues, value);
  };

  const calculateTotal = () => {
    const total = Object.values(debtFormValues).reduce((acc, value) => {
      const numericValue = Number.parseFloat(value.replace(/[^\d.-]/g, ""));

      if (numericValue) {
        return acc + numericValue;
      }
      return acc;
    }, 0);
    return formatPrice(total);
  };

  const handleSave = () => {
    const total = calculateTotal();
    setTotalDebt(total);
    setDebtOverPageState(false);
  };

  return (
    <>
      {debtOverPageState && (
        <div
          className={`fixed inset-0 bg-white p-6 z-30 text-z-shark ${
            window.bridge ? "pt-bridge-inset-top pb-36" : "pt-6 pb-36"
          }`}
        >
          <div className="h-8 mb-4 relative w-full flex items-center justify-center">
            <span className="text-center text-xl font-semibold">
              Get To Your Goal
            </span>
            <img
              onClick={() => setDebtOverPageState(false)}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  setDebtOverPageState(false);
                }
              }}
              className="absolute left-0 rotate-180"
              src={ArrowRightBlack}
              alt="arrow left icon"
            />
          </div>
          <div className="overflow-y-auto h-full px-1 py-2">
            <div className="font-thin mt-4">
              Tell us about your{" "}
              <span className="underline font-semibold">monthly</span> debt
            </div>
            {inputFields.map((field) => (
              <div key={field.name} className="space-y-5 mt-8">
                <label className="text-z-shark">{field.label}</label>
                <input
                  type="tel"
                  name={field.name}
                  spellCheck="false"
                  autoComplete="off"
                  className="shadow-[0_0_6px_0_rgba(0,0,0,0.10)] px-7 rounded-2xl bg-white w-full h-16"
                  value={`$ ${debtFormValues[field.name]}`}
                  onChange={handleChange}
                />
              </div>
            ))}
          </div>
          <div
            className={`absolute bottom-5 w-full left-1/2 -translate-x-1/2 px-6 ${
              window.bridge ? "bottom-bridge-inset-bottom" : "bottom-5"
            }`}
          >
            <Button
              radius="60px"
              customClassName="font-bold text-lg"
              width="full"
              height="md"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export { DebtOverPage };

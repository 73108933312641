import { xIcon } from "../../../assets/icons";
import { Button, SingleSelectGroup } from "../../common";
import { useFinanceStore } from "../../../stores/financeStore";
import { useEffect, useRef, useState } from "react";
import { useModalStore } from "../../../stores/handleModalStore";
import {DatePickerComponent} from "../../common/DatePicker/datePicker";
import { finance } from "../../../services/finance";
import { Finance } from "../../../interfaces/finance";

const whenBuyHomeList = [
  { value: "0-3 months" },
  { value: "3-6 months" },
  { value: "Within 1 year" },
  { value: "Within 2 years" },
];

const WhenBuyHome = () => {
  const {
    selectDate,
    setSelectDate,
    setWhenBuyHomeData,
    whenBuyHomeSelect,
    setWhenBuyHomeSelect,
  } = useFinanceStore();

  const saveHandle = async() => {
    setWhenBuyHomeState(false);
    
    const whenBuyHomeData = selectDate !== '' ? selectDate : whenBuyHomeSelect;
    setWhenBuyHomeData(whenBuyHomeData);
    const payload:Finance = {
      whenBuyHomeData:whenBuyHomeData
    }
    console.log(payload);
    await finance(payload)
  };

  const [dateValue , setDateValue] = useState('')

  const now = new Date();
  const threeMonthsLater = new Date(now);
  threeMonthsLater.setMonth(now.getMonth() + 3);

  const formatDate = (date: Date) => date.toLocaleString("default", {
    month: "short",
    year: "numeric",
  });
  
  const calculateDate = () => {
    let endDate = new Date(now);
    switch (whenBuyHomeSelect) {
      case '0-3 months':
        endDate.setMonth(now.getMonth() + 3);
        setDateValue(`${formatDate(endDate)}`);
        break;
      case '3-6 months':
        endDate.setMonth(now.getMonth() + 6);
        setDateValue(`${formatDate(endDate)}`);
        break;
      case 'Within 1 year':
        endDate.setFullYear(now.getFullYear() + 1);
        setDateValue(`${formatDate(endDate)}`);
        break;
      case 'Within 2 years':
        endDate.setFullYear(now.getFullYear() + 2);
        setDateValue(`${formatDate(endDate)}`);
        break;
      default:
        setDateValue('');
        break;
    }
  };

  useEffect(() => {
    calculateDate();
  }, [whenBuyHomeSelect]);

  const { whenBuyHomeState, setWhenBuyHomeState } = useModalStore();

  const calendarRef = useRef<HTMLDivElement>(null);

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  return (
    <>
      {whenBuyHomeState && (
        <div
          className={`fixed inset-0 bg-white p-6 z-30 ${
            window.bridge ? "pt-bridge-inset-top pb-bridge-inset-bottom" : ""
          } text-z-shark`}
        >
          <h3 className="text-z-shark text-2xl font-bold mb-2">
            When would you like
            <br /> to buy your home?
          </h3>
          <img
            onClick={() => setWhenBuyHomeState(false)}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                setWhenBuyHomeState(false);
              }
            }}
			className={`absolute  ${
				window.bridge ? " top-bridge-inset-top" : "top-5"
			  } right-5`}
            width={16}
            src={xIcon}
            alt="arrow left icon"
          />

          {calendarIsOpen && (
            <div
              ref={calendarRef}
              className="relative"
            >
              <DatePickerComponent isOpen={calendarIsOpen} setIsOpen={setCalendarIsOpen} date={selectDate} setDate={setSelectDate}/>
            </div>
          )}
          <div className="flex flex-col w-full px-2 mt-8">
            <SingleSelectGroup
              flexCol={true}
              activeBg="#fff"
              customBorderColor="#456930"
              gap="12px"
              selectedValue={whenBuyHomeSelect}
              width="fit"
              onChange={(value) => {
                if (!calendarIsOpen) {
                  setWhenBuyHomeSelect(value);
                  setWhenBuyHomeData(value);
                }
                setSelectDate("");
              }}
              options={whenBuyHomeList}
              customClassName="px-3 !text-z-shark !justify-start w-full min-h-[60px] !rounded-2xl"
            />
            <div className="border-2 border-[rgba(0,0,0,0.03)] min-h-[60px] flex items-center rounded-2xl px-3 relative">
              <div
                className="text-base font-bold w-full flex items-center justify-between"
                onClick={() => {
                  setCalendarIsOpen((prev) => !prev)
                  setWhenBuyHomeSelect('')
                }}
              >
                <span>Select a date</span>
                <span className="text-z-dark-moss-green text-sm">
                  {selectDate && selectDate}
                </span>
              </div>
            </div>
          </div>
          <div className="absolute bottom-7 left-1/2 text-center -translate-x-1/2">
            {(selectDate || dateValue ) && (
              <span className="text-lg font-thin">
                We’ll set your goal for{" "}
                <span className="font-bold">{selectDate || dateValue}</span>
              </span>
            )}
            <Button
              onClick={saveHandle}
              customClassName="text-lg font-semibold mt-8"
              radius="60px"
              width="330px"
              height="lg"
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export { WhenBuyHome };

import { formatPrice } from "../../utils/formatPrice";
import { useInputFlowStore } from "../../stores/inputFlowStore";

function calculatePrequalification(
	income: number,
	savings: number,
	debts: number,
): number {
	return income * 4.5 - debts + savings;
}
const AffordabilityCheckView = () => {
	const { debt, savings, income } = useInputFlowStore();
	const convertedIncome = Number.parseInt(income, 10);
	const convertedSavings = Number.parseInt(savings, 10);
	const convertedDebt = Number.parseInt(debt, 10);

	const affordability = calculatePrequalification(
		convertedIncome,
		convertedSavings,
		convertedDebt,
	);
	return (
		<div className="pb-2 h-full px-4">
			<div className="h-full flex flex-col justify-center">
				<h2 className="text-z-dark-moss-green text-2xl font-bold">
					Congratulations!
				</h2>
				<h3 className="text-z-shark text-2xl font-medium">
					You've qualified for up to
				</h3>
				<div className="text-white bg-z-dark-moss-green w-fit py-1 px-5 rounded-xl flex items-start mt-6 mb-8">
					<span className="text-[48px] font-bold">$</span>
					<div className="text-[48px] font-bold">
						{formatPrice(affordability)}
					</div>
					<span className="text-[20px]">*</span>
				</div>
				<div className="text-z-shark text-[20px] font-normal">
					Now, let's take the next step to unlock these funds and bring you
					closer to your new home.{" "}
				</div>
			</div>
			<div className="absolute bottom-1.5 left-0 right-0 text-[#9E9E9E] text-xs px-4">
				*Your Down Payment Assistance (DPA) is estimated at 1.5% of your maximum
				affordability, based on your income, savings, and debt. The actual DPA
				may vary with the final purchase price and the buyer's agent commission
				we receive, which is typically 2.5%.
			</div>
		</div>
	);
};

export { AffordabilityCheckView };

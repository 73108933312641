import type { JSX } from "react";
import useNavbarStore from "../stores/navBarStatus";
import { Outlet, useMatches } from "react-router-dom";
import { LogOutModal } from "../components/logOutModal";
import { useFinanceStore } from "../stores/financeStore";
import { useFilterPanelStore } from "../stores/filterStore";
import { CartTopBar } from "../components/TopBars/cartTopBar";
import { CartModal } from "../components/Finances/Modal/cartModal";
import { NavigationBar } from "../components/common/navigationBar";
import { ProfileTopBar } from "../components/TopBars/profileTopBar";
import { FinanceTopBar } from "../components/TopBars/financeTopBar";
import { WatchVideo } from "../components/Finances/Modal/watchVideo";
import { useLogOutModalStore } from "../stores/showLogOutModalStore";
import FinancialReport from "../components/Finances/financialReport";
import { MainViewTopBar } from "../components/TopBars/mainViewTopBar";
import { FlinksModal } from "../components/Finances/Modal/flinksModal";
import { YourTopMatchesModal } from "../components/yourTopMatchesModal";
import { WhenBuyHome } from "../components/Finances/OverPage/whenBuyHome";
import { FilterPanel } from "../components/common/FilterPanel/filterPanel";
import { DebtOverPage } from "../components/Finances/OverPage/debtOverPage";
import { PlanSpendHome } from "../components/Finances/OverPage/planSpendHome";
import { useEffect, useRef, useState, useCallback, cloneElement } from "react";
import { PlanBuyingHome } from "../components/Finances/OverPage/planBuyingHome";
import { useShowTopMatchesModalStore } from "../stores/showTopMatchesModalStore";
import { BuyHomePlanModal } from "../components/Finances/Modal/buyHomePlanModal";
import { FinanceIncomeModal } from "../components/Finances/Modal/financeIncomeModal";
import { FinanceSavingsModal } from "../components/Finances/Modal/financeSavingsModal";
import { AnotherDebtOverPage } from "../components/Finances/OverPage/anotherDebtOverPage";
import { StartHomeShoppingModal } from "../components/Finances/Modal/startHomeShoppingModal";
import { EditHouseGoalOverPage } from "../components/Finances/OverPage/editHouseGoalOverPage";
import { ConnectBankAccountModal } from "../components/Finances/Modal/connectBankAccountModal";
import { PaymentAssistanceInfoModal } from "../components/Finances/Modal/paymentAssistanceInfoModal";

const topBarMapping = (
	setTopBarHeight: (height: number) => void,
): { [key: string]: JSX.Element } => ({
	"/": <MainViewTopBar setTopBarHeight={setTopBarHeight} />,
	"/cart": <CartTopBar setTopBarHeight={setTopBarHeight} />,
	"/cart/comparison": <CartTopBar setTopBarHeight={setTopBarHeight} />,
	"/profile": (
		<ProfileTopBar
			setTopBarHeight={setTopBarHeight}
			title="Profile"
			showNotification={true}
			showChat={true}
		/>
	),
	"/finances": <FinanceTopBar setTopBarHeight={setTopBarHeight} />,
});

const MainLayout = () => {
	const bottomBarRef = useRef<HTMLDivElement | null>(null);
	const [bottomBarHeight, setBottomBarHeight] = useState(0);
	const { showLogOutModal } = useLogOutModalStore();
	const { showTopMatchesModal } = useShowTopMatchesModalStore();
	const { isNavbarVisible } = useNavbarStore();
	const [topBarHeight, setTopBarHeight] = useState(0);
	const matches = useMatches();
	const currentRoute = matches[matches.length - 1] || null;
	const { setTotalDebt } = useFinanceStore();

	const { filterPanelVisible } = useFilterPanelStore();

	const updateBottomBarHeight = useCallback(() => {
		if (bottomBarRef.current) {
			setBottomBarHeight(bottomBarRef.current.offsetHeight + 16);
		}
	}, []);

	useEffect(() => {
		updateBottomBarHeight();
		window.addEventListener("resize", updateBottomBarHeight);
		return () => {
			window.removeEventListener("resize", updateBottomBarHeight);
		};
	}, [updateBottomBarHeight]);

	useEffect(() => {
		if (!topBarMapping(setTopBarHeight)[currentRoute?.pathname]) {
			setTopBarHeight(0);
		}
	}, [currentRoute]);

	const renderTopBar = () => {
		const topBar = topBarMapping(setTopBarHeight)[currentRoute?.pathname];
		return topBar ? cloneElement(topBar, { setTopBarHeight }) : null;
	};

	return (
		<div
			className={`absolute inset-0 h-full overflow-hidden flex flex-col justify-center items-center  ${
				currentRoute?.pathname === "/profile" ? "bg-[#F5F5F5]" : "bg-white"
			}`}
		>
			{renderTopBar()}
			<div
				className={`relative text-white ${
					currentRoute?.pathname === "/profile" ? "bg-[#F5F5F5]" : "bg-white"
				} overflow-auto h-full w-full z-10 ${
					currentRoute?.pathname === "/" ? "rounded-t-[20px]" : ""
				}
       `}
				style={{
					marginTop: `${topBarHeight}px`,
					paddingBottom: `${
						currentRoute?.pathname === "/explore" ||
						currentRoute?.pathname?.startsWith("/buyer-input-flow")
							? 0
							: window.bridge
								? bottomBarHeight +
									window.bridge?.adjustedContentInset.bottom -
									20
								: bottomBarHeight
					}px`,
				}}
			>
				<Outlet />
			</div>
			{isNavbarVisible && (
				<div
					className={`fixed z-20 h-16 ${
						window.bridge ? "bottom-bridge-inset-bottom" : "bottom-4"
					}  w-full max-w-[90vw] sm:max-w-lg -translate-x-1/2 left-1/2  bg-white rounded-full shadow-md drop-shadow-lg py-2`}
					ref={bottomBarRef}
				>
					<NavigationBar />
				</div>
			)}
			{showLogOutModal && <LogOutModal />}
			{showTopMatchesModal && <YourTopMatchesModal />}
			<ConnectBankAccountModal />
			<PaymentAssistanceInfoModal />
			<WatchVideo />
			<StartHomeShoppingModal />
			<CartModal />
			<BuyHomePlanModal />
			<FinanceIncomeModal />
			<FinanceSavingsModal />
			<FlinksModal />

			<DebtOverPage setTotalDebt={setTotalDebt} />
			<EditHouseGoalOverPage />
			<PlanBuyingHome />
			<PlanSpendHome />
			<WhenBuyHome />
			<FinancialReport />
			<AnotherDebtOverPage />
			{filterPanelVisible && <FilterPanel panelOpen={filterPanelVisible} />}
		</div>
	);
};

export { MainLayout };

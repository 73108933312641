import { useState, useEffect, useRef } from "react";
import { useNavigate, useMatches } from "react-router-dom";
import ArrowBackIcon from "../../assets/arrowDownward.svg";

interface ShowingTopBarProps {
  setTopBarHeight: (height: number) => void;
}

const ShowingTopBar = ({ setTopBarHeight }: ShowingTopBarProps) => {
  const navigate = useNavigate();
  const matches = useMatches();
  const [activeTab, setActiveTab] = useState("upcoming");

  useEffect(() => {
    const currentRoute = matches[matches.length - 1];

    if (currentRoute?.pathname.includes("/profile/showing/completed")) {
      setActiveTab("completed");
    } else if (currentRoute?.pathname.includes("/profile/showing")) {
      setActiveTab("upcoming");
    }
  }, [matches]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    if (tab === "upcoming") {
      navigate("/profile/showing");
    } else if (tab === "completed") {
      navigate("/profile/showing/completed");
    }
  };

  const topBarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (topBarRef.current) {
      setTopBarHeight(topBarRef.current.offsetHeight);
    }
  }, [setTopBarHeight]);

  return (
    <div
      ref={topBarRef}
      className={`flex flex-col items-center w-full absolute top-0 bg-white z-10 ${
        window.bridge ? "pt-bridge-inset-top" : "pt-6"
      }`}
    >
      <div className="w-full px-2">
        <button
          type="button"
          onClick={() => navigate("/profile")}
          className="w-full"
        >
          <img src={ArrowBackIcon} alt="Back" className="rotate-90" />
        </button>
      </div>
      <div className="text-sm font-medium text-center border-b border-[#D6E9FC] w-full">
        <ul className="flex -mb-px justify-center">
          <button
            type="button"
            onClick={() => handleTabChange("upcoming")}
            className={`inline-block w-full p-4 border-b-2 rounded-t-lg ${
              activeTab === "upcoming"
                ? "text-z-dark-moss-green border-z-dark-moss-green"
                : "border-[#F2F2F2] text-[#B4B4B4]"
            }`}
          >
            Upcoming
          </button>

          <button
            type="button"
            onClick={() => handleTabChange("completed")}
            className={`inline-block p-4 w-full border-b-2 rounded-t-lg ${
              activeTab === "completed"
                ? "text-z-dark-moss-green border-z-dark-moss-green"
                : "border-[#F2F2F2] text-[#B4B4B4]"
            }`}
          >
            Completed
          </button>
        </ul>
      </div>
    </div>
  );
};

export { ShowingTopBar };

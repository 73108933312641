import { useEffect, useState } from "react";
import {
  CheckMarkerIcon,
  ConnectIcon,
  EyeSlashIcon,
  InfoIcon,
  LockIcon,
} from "../../../assets/icons";
import { Button } from "../../common";
import { useFinanceStore } from "../../../stores/financeStore";
import flinksLogos from "../../../assets/flinksLogos.jpg";
import { CurrentQualification } from "./currentQualification";
import { useModalStore } from "../../../stores/handleModalStore";
import { useFlinksStore } from "../../../stores/flinksStore";
import { FlinksModal } from "../Modal/flinksModal";

const StepTwo = () => {
  const { completedSteps , qualification, setQualification } = useFinanceStore();
  const { setBankConnectionModal } = useModalStore();
  const [continueFlinks, setContinueFlinks] = useState(false);
  const {setConnectFlinks , connectFlinks} = useFlinksStore()
  const {setFlinksModal} = useModalStore()
  return (
    <div className="text-z-shark flex items-center justify-center flex-col">
      {completedSteps[2] ? (
        <div className="flex items-center space-x-2">
          <div className="bg-z-dark-moss-green rounded-full w-4 h-4 flex items-center justify-center">
            <img src={CheckMarkerIcon} width={10} alt="check marker icon" />
          </div>
          <div className="text-sm">Step 2 Complete</div>
        </div>
      ) : (
        <div className="text-sm mb-1">Step 2</div>
      )}
      <div className="text-xl font-bold mb-3">See your home buying power</div>
      <div className="flex items-center space-x-2">
        <div className="text-[#828282] font-medium text-[14px]">
          Connect your bank accounts
        </div>
        <div
          onClick={() => setBankConnectionModal(true)}
          onKeyUp={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setBankConnectionModal(true);
            }
          }}
        >
          <InfoIcon fill="#828282" />
        </div>
      </div>
      {completedSteps[1] && !completedSteps[2] && (
        <div className="flex items-center justify-center overflow-hidden w-full shadow-[0_0_6.3px_0_rgba(0,0,0,0.20)] rounded-[20px] mt-5">
          {connectFlinks || qualification ? (
            <CurrentQualification />
          ) : (
            <>
              {continueFlinks ? (
                <div className="h-[600px] w-full">
                  <FlinksModal />
                </div>
              ) : (
                <div>
                  <div className="flex flex-col items-center justify-center px-8 py-6">
                    <img width={65} src={flinksLogos} alt="flinks logos" />
                    <div className="font-thin text-xl text-center my-10">
                      Zown uses{" "}
                      <span className="font-bold text-black">Flinks</span> to
                      connect your bank
                    </div>
                    <div className="border border-[#E2E2E2] space-y-8 rounded-[20px] w-full p-6">
                      <div className="flex items-start space-x-4 text-z-shark">
                        <img src={ConnectIcon} alt="connect icon" />
                        <div className="flex flex-col space-y-2">
                          <div className="font-semibold text-sm">
                            Connect effortlessly
                          </div>
                          <span className="text-xs font-thin">
                            Flinks lets you securely connect your financial
                            accounts in seconds
                          </span>
                        </div>
                      </div>
                      <div className="flex items-start space-x-4">
                        <img src={EyeSlashIcon} alt="eye slash icon" />
                        <div className="flex flex-col space-y-2">
                          <div className="font-semibold text-sm">
                            Your data belongs to you
                          </div>
                          <span className="text-xs font-thin">
                            Flinks doesn’t sell personal info, and will only use
                            it with your permission
                          </span>
                        </div>
                      </div>
                      <div className="flex items-start space-x-4">
                        <img src={LockIcon} alt="lock icon" />
                        <div className="flex flex-col space-y-2">
                          <div className="font-semibold text-sm">
                            Protect your accounts
                          </div>
                          <span className="text-xs font-thin">
                            Flinks helps minimize fraud and risk by using
                            account info, transaction history, and connection
                            history
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center text-z-shark py-5 border-t border-[#E8E8E8]">
                    <span className="text-xs">
                      By continuing, you agree to Flinks Privacy Policy
                    </span>
                    <div className="px-9 w-full">
                      <Button
                        onClick={() => {
                          setContinueFlinks(true)
                          setFlinksModal(true)
                        }}
                        radius="60px"
                        customClassName="font-bold text-lg mt-5 mb-7"
                        width="full"
                        height="md"
                      >
                        Continue
                      </Button>
                    </div>
                    <span
                      className="font-semibold underline"
                      onClick={() => setQualification(true)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          setQualification(true)
                        }
                      }}
                    >
                      Enter details manually instead
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {!completedSteps[1] && (
        <Button
          disable={true}
          disableClass="!text-[#9D9D9D] !bg-[#D9D9D9] font-normal !text-base font-semibold"
          customClassName="font-bold text-lg mt-5"
          radius="60px"
          height="md"
          width="320px"
          startIcon={LockIcon}
        >
          Complete step 1 to unlock
        </Button>
      )}
      {completedSteps[2] && (
        <div className="mt-5">
          <CurrentQualification />
        </div>
      )}
    </div>
  );
};

export { StepTwo };

import type { Feature, Point, GeoJsonProperties } from "geojson";
import millify from "millify";
import type { LngLatBounds } from "react-map-gl";
import type { SearchListingInterface } from "../services/exploreListings";
// REDUCER FOR MAPBOX

export function calcHaversine(
  lon1: number,
  lat1: number,
  lon2: number,
  lat2: number
): number {
  const toRadians = (degrees: number): number => {
    return degrees * (Math.PI / 180);
  };

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in kilometers

  const distanceM = distance * 1000;

  const roundedVal = Math.ceil(distanceM);

  return roundedVal;
}

export function populateGeoJsonFeatures(searchTestData: any[]) {
  // console.log("test geojson", searchTestData);

  // Set to keep track of unique listingIds
  const seenListingIds = new Set();

  return searchTestData
    .filter((item) => {
      // Check that the item has the required properties and is unique
      const isUnique = !seenListingIds.has(item.listingId);
      if (isUnique) {
        seenListingIds.add(item.listingId); // Add to the set
      }
      return (
        isUnique &&
        item.coordinates &&
        item.coordinates.long !== undefined &&
        item.coordinates.lat !== undefined &&
        item.listPrice !== undefined &&
        item.listingId !== undefined
      );
    })
    .map(
      (item) =>
        ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [item.coordinates.long, item.coordinates.lat],
          },
          properties: {
            name: `${item.address}`,
            price: `$${millify(item.listPrice / 100)}`,
            icon: "house",
            listingId: item.listingId,
          },
        } as Feature<Point, GeoJsonProperties>)
    );
}

export function filterListingsByLngLat(
  bounds: LngLatBounds | null,
  searchTestData: SearchListingInterface[]
) {
  if (!bounds) {
    return [];
  }

  // EASTWARD DIRECTION = LESS LNG
  // WESTWARD DIRECTION = MORE LNG
  // NORTHWARD DIRECTION = MORE LAT
  // SOUTHWARD DIRECTION = LESS LAT

  const northBoundary = bounds.getNorthEast().lat;
  const eastBoundary = bounds.getNorthEast().lng;
  const southBoundary = bounds.getSouthWest().lat;
  const westBoundary = bounds.getSouthWest().lng;

  return searchTestData.filter((listing) => {
    // const { longitude, latitude } = listing.map;
    let longitude;
    let latitude;

    // const longitude = listing.map.coordinates[0];
    // const latitude = listing.map.coordinates[1];

    if (listing.map) {
      longitude = listing.map.coordinates[0];
      latitude = listing.map.coordinates[1];
    } else if (listing.coordinates) {
      longitude = listing.coordinates.long;
      latitude = listing.coordinates.lat;
    }

    return (
      longitude < eastBoundary &&
      longitude > westBoundary &&
      latitude < northBoundary &&
      latitude > southBoundary
    );
  });
}

export function filterListingsByListingId(
  listingIds: string[],
  searchTestData: SearchListingInterface[]
) {
  const idsSet = new Set(listingIds);

  const filteredListings = searchTestData.filter((listing) =>
    idsSet.has(listing.listingId)
  );

  return filteredListings;
}

// export function filterListingsOnClick(
//   lng: number,
//   lat: number,
//   viewState: number,
//   searchTestData: SearchListingInterface[]
// ) {
//   // CALCULATE BOUNDARY RADIUS FOR FILTERING
//   // 0.001 DEG OF LAT / LONG ~= 1.11KM

//   const baseViewState = 14;
//   const baseDistance = 1.4; // km at baseViewState 14
//   const scalingFactor = 0.3; // Factor to tighten the radius (e.g., 0.8 means 80% of the calculated distance)

//   function calculateDistance(viewState: number): number {
//     const distanceAtCurrentViewState =
//       baseDistance * (2.5 / 1.4) ** (baseViewState - viewState);
//     return distanceAtCurrentViewState * scalingFactor;
//   }

//   const distance = calculateDistance(viewState);
//   const offset = distance / 111;

//   const northBoundary = lat + offset;
//   const southBoundary = lat - offset;
//   const eastBoundary = lng + offset;
//   const westBoundary = lng - offset;

//   return searchTestData.filter((listing) => {
//     const { longitude, latitude } = listing.map;

//     return (
//       longitude < eastBoundary &&
//       longitude > westBoundary &&
//       latitude < northBoundary &&
//       latitude > southBoundary
//     );
//   });
// }

import TrashIcon from "../../assets/trashIcon.svg";
import CheckIcon from "../../assets/checkIcon.svg";
import DocumentIcon from "../../assets/documentIcon.svg";
import { useState, useRef, type ChangeEvent } from "react";
import {
	emailUploadPreApproval,
	uploadPreApprovalDoc,
} from "../../services/account";
import { useUserProfileStore } from "../../stores/profileStore";

interface UploadedFile {
	name: string;
	size: number;
	progress: number;
	isComplete: boolean;
	isUploading: boolean;
	showCheck: boolean;
	showFinalState: boolean;
	fileUrl: string | null;
	file: File;
	isError?: boolean;
}

const PreApprovalDocsUploadView = () => {
	const [emailSent, setEmailSent] = useState(false);
	const { userProfile } = useUserProfileStore();
	const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const handleUploadDoc = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files) {
			setErrorMessage(null);
			const newFiles: UploadedFile[] = [];
			for (const file of files) {
				const fileSizeInMb = file.size / (1024 * 1024);

				if (fileSizeInMb > 25) {
					setErrorMessage(
						`The file "${file.name}" exceeds the 25 MB size limit.`,
					);
					continue;
				}

				const newFile: UploadedFile = {
					name: file.name,
					size: file.size,
					progress: 0,
					isComplete: false,
					isUploading: false,
					showCheck: false,
					showFinalState: false,
					fileUrl: URL.createObjectURL(file),
					file: file,
					isError: false,
				};

				newFiles.push(newFile);
			}

			if (newFiles.length > 0) {
				setIsSubmitted(false);
			}

			setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);

			event.target.value = "";
		}
	};

	const readFileAsDataURL = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				resolve(reader.result as string);
			};
			reader.onerror = (error) => {
				reject(error);
			};
		});
	};

	const handleSubmit = async () => {
		const filesToUpload = uploadedFiles.filter(
			(file) => !file.isComplete && !file.isError && !file.isUploading,
		);

		if (filesToUpload.length === 0) {
			return;
		}

		setUploadedFiles((prevFiles) =>
			prevFiles.map((file) =>
				filesToUpload.find((f) => f.name === file.name)
					? { ...file, isUploading: true, progress: 0 }
					: file,
			),
		);

		const updateProgress = setInterval(() => {
			setUploadedFiles((prevFiles) =>
				prevFiles.map((file) => {
					if (filesToUpload.find((f) => f.name === file.name)) {
						return {
							...file,
							progress: Math.min(file.progress + 10, 100),
						};
					}
					return file;
				}),
			);
		}, 300);

		try {
			const base64Strings = await Promise.all(
				filesToUpload.map((file) => readFileAsDataURL(file.file)),
			);

			await uploadPreApprovalDoc(base64Strings);

			setUploadedFiles((prevFiles) =>
				prevFiles.map((file) =>
					filesToUpload.find((f) => f.name === file.name)
						? {
								...file,
								isComplete: true,
								isUploading: false,
								showCheck: false,
								showFinalState: true,
								progress: 100,
							}
						: file,
				),
			);

			setIsSubmitted(true);
		} catch (error) {
			console.error("Error uploading files", error);

			setUploadedFiles((prevFiles) =>
				prevFiles.map((file) =>
					filesToUpload.find((f) => f.name === file.name)
						? {
								...file,
								isError: true,
								isUploading: false,
							}
						: file,
				),
			);

			setErrorMessage("Failed to upload files. Please try again.");
		} finally {
			clearInterval(updateProgress);
		}
	};

	const handleDeleteFile = (fileName: string) => {
		setUploadedFiles((prevFiles) =>
			prevFiles.filter((file) => file.name !== fileName),
		);
	};

	const handleRetry = (fileName: string) => {
		setUploadedFiles((prevFiles) =>
			prevFiles.map((file) =>
				file.name === fileName
					? { ...file, isError: false, progress: 0, isUploading: false }
					: file,
			),
		);
	};

	const handleEmailMe = async () => {
		setEmailSent(true);
		await emailUploadPreApproval();
		setTimeout(() => {
			setEmailSent(false);
		}, 5000);
	};

	return (
		<div className="flex flex-col w-full space-y-6 text-black px-2 pt-2 py-6">
			<div className="space-y-2">
				<div className="font-bold">📎 Upload your pre-approval</div>
				<div className="text-[#828282] font-medium text-xs">
					Already have a pre-approval? Upload it here so we can verify
				</div>
			</div>
			<button
				onClick={handleUploadDoc}
				type="button"
				className="bg-white border-dashed border-2 border-[#CACACA] h-[201px] w-full rounded-[7.21px] flex items-center flex-col justify-center"
			>
				<div className="font-medium text-z-dark-moss-green">
					Click to Upload
				</div>
				<div className="text-sm">(Max. File size: 25 MB)</div>
			</button>
			<input
				type="file"
				ref={fileInputRef}
				onChange={handleFileChange}
				style={{ display: "none" }}
				multiple={true}
			/>

			{errorMessage && (
				<div className="text-red-500 text-sm text-center">{errorMessage}</div>
			)}

			{uploadedFiles.map((file) => (
				<div
					key={file.name}
					className={`border ${
						file.isError ? "border-red-500 text-red-500" : "border-[#CACACA]"
					} space-y-2 rounded-lg p-4 bg-white relative w-full`}
				>
					{file.isError ? (
						<div className="flex flex-col items-start space-y-2">
							<span className="font-medium">
								Failed to upload <strong>{file.name}</strong>
							</span>
							<button
								type="button"
								onClick={() => handleRetry(file.name)}
								className="text-z-dark-moss-green underline"
							>
								Retry
							</button>
						</div>
					) : (
						<>
							<div className="flex justify-between items-start">
								<div className="flex flex-col">
									<span className="font-medium flex items-start gap-2">
										<img src={DocumentIcon} alt="" />
										<div className="flex flex-col break-all">
											{file.name}
											<span className="text-xs text-[#989692]">
												{file.size >= 1024 * 1024
													? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
													: `${(file.size / 1024).toFixed(2)} KB`}
											</span>
										</div>
									</span>
								</div>

								{file.showCheck ? (
									<button type="button">
										<img src={CheckIcon} alt="Check" />
									</button>
								) : !file.isComplete && file.showFinalState ? (
									<button
										type="button"
										onClick={() => handleDeleteFile(file.name)}
									>
										<img src={TrashIcon} alt="Delete" />
									</button>
								) : null}
							</div>

							{file.isUploading && (
								<div
									className={`relative flex items-center gap-2 ${
										file.showFinalState ? "hidden" : "block"
									}`}
								>
									<div className="overflow-hidden h-2 text-xs flex rounded bg-[#F5F5F5] w-full">
										<div
											style={{ width: `${file.progress}%` }}
											className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#50C878]"
										/>
									</div>
									<span className="text-sm">{file.progress}%</span>
								</div>
							)}
						</>
					)}
				</div>
			))}

			{uploadedFiles.length > 0 && (
				<button
					type="button"
					onClick={handleSubmit}
					className={`py-3.5 rounded-full font-bold text-lg ${
						isSubmitted
							? "bg-z-abbey text-black"
							: "bg-z-dark-moss-green text-white"
					}`}
					disabled={
						isSubmitted && !uploadedFiles.some((file) => !file.isComplete)
					}
				>
					{isSubmitted && !uploadedFiles.some((file) => !file.isComplete)
						? "Submitted"
						: "Submit"}
				</button>
			)}

			<div className="w-full flex flex-col items-center justify-center space-y-2">
				<div>{emailSent ? "File sent to" : "File not on your phone?"}</div>
				<div>
					{emailSent ? (
						<div>{userProfile?.emailAddress}</div>
					) : (
						<div>
							We'll <span className="font-bold">email</span> you a link to
							upload it to.
						</div>
					)}
				</div>
				<button
					type="button"
					onClick={handleEmailMe}
					disabled={emailSent}
					className={`rounded-full font-bold py-3.5 w-[182px] ${
						emailSent
							? "bg-z-abbey text-black"
							: "bg-z-dark-moss-green text-white"
					}`}
				>
					{emailSent ? "Sent" : "Email me"}
				</button>
			</div>
		</div>
	);
};

export { PreApprovalDocsUploadView };

import { universalFetch } from "./common";
// import { cmaData } from "../mocks/cmaData";
import type { CmaProperty } from "../interfaces/cmaInterface";

export const fetchCMA = async (listingId: string): Promise<CmaProperty[]> => {
  try {
    console.log("fetchCMA listingId:", listingId);
    const response = await universalFetch(
      `/listings/${listingId}/comparables`,
      "GET"
    );
    console.log("fetchOuting response:", response);

    return response as CmaProperty[];
  } catch (error) {
    console.error("Failed fetchCartProperties", error);
    throw error;
  }
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BedIcon from "../../assets/cardBedIcon.svg";
import SqftIcon from "../../assets/carSqftIcon.svg";
import { formatPrice } from "../../utils/formatPrice";
import ShowerIcon from "../../assets/cardShowerIcon.svg";
import { convertToFormattedAmount } from "../../utils/convertAmount";
import type { MainMatchingProperties } from "../../interfaces/cartProperties";

interface PropertiesCardProps {
	property: MainMatchingProperties;
}

export const PropertiesCard = ({ property }: PropertiesCardProps) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const navigate = useNavigate();

	const handleNextImage = () => {
		setCurrentImageIndex(
			(prevIndex) => (prevIndex + 1) % property.images.length,
		);
	};
	const handlePreviousImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === 0 ? property.images.length - 1 : prevIndex - 1,
		);
	};

	const handleOpenListing = () => {
		navigate(`/listing/${property.listingId}`, {
			state: { origin: "matchingListing" },
		});
	};

	return (
		<div
			className="swiper--card bg-white w-full h-full rounded-[20px] overflow-hidden absolute will-change-transform transition-all duration-300 ease-in-out cursor-grab"
			data-zlid={property.listingId}
		>
			<div className="absolute top-5 left-5 text-white text-[40px] font-bold love-label bg-[#0A9D57] rounded-[12.29px] px-4 opacity-0 z-20">
				LIKE
			</div>
			<div className="absolute top-5 right-5 text-white text-[40px] font-bold nope-label bg-[#FF5757] rounded-[12.29px] px-4 opacity-0 z-20">
				PASS
			</div>
			<button
				type="button"
				className="w-1/2 h-full  left-0 absolute z-20"
				onClick={handlePreviousImage}
				aria-label="Previous Image"
			/>
			<button
				type="button"
				className="w-1/2 h-full right-0 absolute z-20"
				onClick={handleNextImage}
				aria-label="Next Image"
			/>
			<button
				type="button"
				className="h-fit w-full bottom-0 right-0 absolute z-20"
				onClick={handleOpenListing}
				aria-label="Open Listing"
			>
				<div className="relative flex flex-col items-start space-y-1 text-start pb-14 px-4 pt-2">
					<div
						className="text-2xl font-semibold"
						style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
					>
						${formatPrice(convertToFormattedAmount(property.listPrice.amount))}
					</div>
					<div
						className="font-medium text-sm"
						style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
					>
						{property.displayAddress}
					</div>
					<div
						className="flex gap-3"
						style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
					>
						<div
							className="flex items-center gap-1.5 text-sm"
							style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
						>
							<img src={BedIcon} alt="Beds" />
							{property.bedrooms ? `${property.bedrooms}` : "N/A"}
						</div>
						<div
							className="flex items-center gap-1.5 text-sm"
							style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
						>
							<img src={ShowerIcon} alt="Baths" />
							{property.bathrooms ? `${property.bathrooms}` : "N/A"}
						</div>
						<div
							className="flex items-center gap-1.5 text-sm"
							style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
						>
							<img src={SqftIcon} alt="Square footage" />
							{property.squareFeet
								? `${formatPrice(property.squareFeet)}`
								: "N/A"}{" "}
							sqft
						</div>
					</div>
					{property.downPaymentGiven.amount && (
						<div className="bg-white rounded-full text-z-dark-moss-green text-sm font-extrabold px-2 py-1 flex gap-2 h-full">
							<div>Down Payment Contribution</div>
							<div>${formatPrice(convertToFormattedAmount(property.downPaymentGiven.amount))}</div>
						</div>
					)}
				</div>
			</button>
			<div className="absolute top-2 left-1/2 transform -translate-x-1/2 flex space-x-1 z-20">
				{property.images.map((imgSrc, index) => (
					<div
						key={imgSrc}
						className={`h-1.5 bg-black rounded-full will-change-transform transition-all duration-300 ease-in ${
							currentImageIndex === index ? "w-5" : "w-1.5"
						}`}
					/>
				))}
			</div>
			<img
				src={property.images[currentImageIndex]}
				className="w-full h-full object-cover rounded-t-lg z-10"
				alt={`Property view ${currentImageIndex + 1} of ${
					property.images.length
				}`}
			/>

			<div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-[#012A43] rounded-[12.33px] z-10 pointer-events-none" />
		</div>
	);
};

import { universalFetch } from "./common";
// import { notificationItems } from "../mocks/notificationData";
import type { NotificationItem } from "../interfaces/notifications";

export const fetchNotifications = async (): Promise<NotificationItem[]> => {
  try {
    const response = await universalFetch("/notifications", "GET");
    console.log("fetchNotifications response:", response);
    return response as NotificationItem[];
  } catch (error) {
    console.error("Failed to fetchNotifications", error);
    throw error;
  }
};

export const clearNotifications = async () => {
  try {
    const response = await universalFetch("/notifications", "DELETE");
    console.log("clearNotifications response", response);
    return response;
  } catch (error) {
    console.error("Failed clearNotifications", error);
    throw error;
  }
};

import { fetchCMA } from "../../services/cma";
import MapIcon from "../../assets/mapIcon.svg";
import { LoadingCircle } from "../common/loading";
import PinIcon from "../../assets/Cart/pinIcon.svg";
import { formatPrice } from "../../utils/formatPrice";
import ArrowIcon from "../../assets/arrowRightBlack.svg";
import { useNavigate, useParams } from "react-router-dom";
import type { CmaProperty } from "../../interfaces/cmaInterface";
import { convertToFormattedAmount } from "../../utils/convertAmount";
import { useCallback, useEffect, useState, type KeyboardEvent } from "react";

const fieldTitles: { [key: string]: string } = {
	city: "City",
	propertyType: "Property Type",
	bedrooms: "Bedrooms",
	bathrooms: "Bathrooms",
	squareFootage: "Square Footage",
	soldDate: "Sold Date",
	dom: "Days on Market (DOM)",
	listPrice: "List Price",
	soldPrice: "Sold Price",
	type: "Type",
	lotSquareFootage: "Lot Square Footage",
	basement: "Basement",
	maintenanceFee: "Maintenance Fee",
	propertyTax: "Property Tax",
	rooms: "Rooms",
	kitchens: "Kitchens",
	familyRoom: "Family Room",
	firePlace: "Fireplace",
	heatType: "Heat Type",
	ac: "A/C",
	garage: "Garage",
	parking: "Parking",
	locker: "Locker",
	exposure: "Exposure",
	hydroInc: "Hydro Included",
	waterInc: "Water Included",
	comElemInc: "Common Elements Included",
	heatInc: "Heat Included",
	cableInc: "Cable Included",
};

const sections = [
	{
		title: "Quick Facts",
		fields: [
			"city",
			"propertyType",
			"bedrooms",
			"bathrooms",
			"squareFootage",
			"soldDate",
			"dom",
			"listPrice",
			"soldPrice",
		],
	},
	{
		title: "Details",
		fields: [
			"type",
			"lotSquareFootage",
			"basement",
			"maintenanceFee",
			"propertyTax",
			"rooms",
			"kitchens",
			"familyRoom",
			"firePlace",
			"heatType",
			"ac",
			"garage",
			"parking",
			"locker",
			"exposure",
			"hydroInc",
			"waterInc",
			"comElemInc",
			"heatInc",
			"cableInc",
		],
	},
];

const ComparativeMarketAnalysisView = () => {
	const [properties, setProperties] = useState<CmaProperty[]>([]);
	const { zlid } = useParams<{ zlid: string }>();

	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const [expandedSections, setExpandedSections] = useState(() =>
		sections.reduce(
			(acc, section) => {
				acc[section.title] = true;
				return acc;
			},
			{} as { [key: string]: boolean },
		),
	);

	const toggleSection = (sectionTitle: string) => {
		setExpandedSections((prev) => ({
			...prev,
			[sectionTitle]: !prev[sectionTitle],
		}));
	};

	const handleSectionKeyDown = (
		event: KeyboardEvent<HTMLElement>,
		sectionTitle: string,
	) => {
		if (event.key === "Enter" || event.key === " ") {
			toggleSection(sectionTitle);
		}
	};

	const fetchProperties = useCallback(async () => {
		if (!zlid) {
			console.error("ZLID is undefined");
			return;
		}
		try {
			setLoading(true);
			console.log("fetching properties");
			const response = await fetchCMA(zlid);

			const reorderedProperties = [...response];
			const index = reorderedProperties.findIndex(
				(property) => property.listingId === zlid,
			);

			if (index > -1) {
				const [matchedProperty] = reorderedProperties.splice(index, 1);
				reorderedProperties.unshift(matchedProperty);
			}

			const uniqueProperties = reorderedProperties.filter(
				(property, index, self) =>
					index === self.findIndex((p) => p.listingId === property.listingId),
			);

			setProperties(uniqueProperties);
			setLoading(false);
		} catch (error) {
			console.error("Failed to fetch listing data", error);
			setLoading(false);
		}
	}, [zlid]);

	useEffect(() => {
		fetchProperties();
	}, [fetchProperties]);

	const getLowPrice = () => {
		return Math.min(
			...properties.map((property) =>
				Number(convertToFormattedAmount(property.listPrice)),
			),
		);
	};

	const getHighPrice = () => {
		return Math.max(
			...properties.map((property) =>
				Number(convertToFormattedAmount(property.listPrice)),
			),
		);
	};

	const getMedianPrice = () => {
		const sortedPrices = properties
			.map((property) => Number(convertToFormattedAmount(property.listPrice)))
			.sort((a, b) => a - b);
		const middle = Math.floor(sortedPrices.length / 2);
		return sortedPrices.length % 2 === 0
			? (sortedPrices[middle - 1] + sortedPrices[middle]) / 2
			: sortedPrices[middle];
	};

	const getMeanPrice = () => {
		const total = properties.reduce(
			(sum, property) =>
				sum + Number(convertToFormattedAmount(property.listPrice)),
			0,
		);
		return total / properties.length;
	};

	const getAvgDom = () => {
		const totalDom = properties.reduce(
			(sum, property) => sum + Number(property.dom),
			0,
		);
		return totalDom / properties.length;
	};

	const getComparableSales = () => properties.length;
	const primaryProperty = properties.length > 0 ? properties[0] : null;

	return (
		<div
			className={`w-full text-black space-y-6  pb-6 ${
				loading || properties.length === 0 ? "h-full" : ""
			} bg-white`}
		>
			{loading && (
				<div className="flex items-center justify-center h-full">
					<LoadingCircle />
				</div>
			)}
			{!loading && (
				<>
					<div className="px-2 space-y-10">
						<div className="space-y-4 flex flex-col items-center w-full">
							{primaryProperty && (
								<div className="font-bold">{primaryProperty.address}</div>
							)}
							<div className="rounded-full font-bold w-fit text-sm bg-z-abbey py-1 px-2">
								Comparable Statistics
							</div>
							<div className="grid grid-cols-2 gap-3 w-full">
								{[
									{
										label: "Low Price",
										value: `$${formatPrice(getLowPrice())}`,
									},
									{
										label: "High Price",
										value: `$${formatPrice(getHighPrice())}`,
									},
									{
										label: "Median Price",
										value: `$${formatPrice(getMedianPrice())}`,
									},
									{
										label: "Mean Price",
										value: `$${formatPrice(getMeanPrice())}`,
									},
									{ label: "Comparable Sales", value: getComparableSales() },
									{
										label: "Avg Days on Market",
										value: Math.round(getAvgDom()),
									},
								].map((item) => (
									<div
										key={item.label}
										className="border border-[#E6E6E] py-1 flex flex-col items-center text-center w-full rounded-[9px]"
									>
										<div className="text-[#878787] font-light text-sm">
											{item.label}
										</div>
										<div className="font-semibold text-[#32302F] text-[20px]">
											{item.value}
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="rounded-full font-bold w-fit text-sm bg-z-abbey py-1 px-2">
							Comparative Market Analysis
						</div>
					</div>
					<div className="overflow-x-auto no-scrollbar ml-2 pr-2">
						<table className="border-collapse w-full">
							<thead>
								<tr className="bg-white  w-full">
									<th className="sticky w-[100px] h-[100px] left-0 top-0 bg-white rounded-r-[10px] z-30">
										<button
											type="button"
											className="flex flex-col w-full h-full justify-center items-center bg-[#EEFAFF] rounded-[10px]"
											onClick={() => navigate("/explore")}
										>
											<img src={MapIcon} alt="" />
											<div className="text-sm font-bold">Map</div>
										</button>
									</th>
									{properties.map((property) => (
										<th
											key={property.listingId}
											className={`pl-4 w-full ${
												properties[0].listingId === property.listingId
													? "sticky left-[100px] bg-white z-20 rounded-r-[10px]"
													: ""
											}`}
										>
											<div className="relative h-[100px] min-w-[140px] rounded-r-[10px]">
												<button
													type="button"
													onClick={() =>
														navigate(`/listing/${property.listingId}`)
													}
													className=""
												>
													<img
														src={property.imageSrc}
														alt={property.address}
														className="w-full min-w-[140px] h-[100px] rounded-[10px] object-cover"
													/>
												</button>
												{properties[0].listingId === property.listingId && (
													<div className="absolute cursor-pointer bottom-0.5 left-1 w-[32px] flex items-center justify-start h-[32px] rounded-full">
														<div className="w-[22px] drop-shadow-lg h-[22px] rounded-full flex items-center justify-center bg-z-abbey ">
															<img src={PinIcon} alt="Pin Property" />
														</div>
													</div>
												)}

												<div className="text-white font-bold text-xs absolute top-1 px-1 flex justify-between w-full items-center">
													<div
														className="truncate max-w-[calc(100%-26px)]"
														style={{
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}
													>
														{property.address}
													</div>
												</div>
											</div>
										</th>
									))}
								</tr>
							</thead>
							{sections.map((section) => (
								<tbody key={section.title} className="w-full">
									<tr className="w-full">
										<th
											colSpan={properties.length + 1}
											className="p-4 text-left flex items-center w-full gap-2 sticky top-0 left-0 bg-white z-20 cursor-pointer"
											onClick={() => toggleSection(section.title)}
											onKeyDown={(e) => handleSectionKeyDown(e, section.title)}
										>
											{section.title}
											<img
												src={ArrowIcon}
												alt=""
												className={`transform transition-transform ${
													expandedSections[section.title] ? "rotate-90" : ""
												}`}
											/>
										</th>
									</tr>
									{expandedSections[section.title] &&
										section.fields.map((field, fieldIndex) => (
											<tr
												key={field}
												className={
													fieldIndex % 2 === 1
														? "bg-white w-full"
														: "bg-[#EEFAFF] w-full"
												}
											>
												<td
													className={`p-4 rounded-l-[10px] font-bold text-xs sticky w-full left-0 z-20 ${
														fieldIndex % 2 === 1 ? "bg-white" : "bg-[#EEFAFF]"
													}`}
												>
													{fieldTitles[field] || field}
												</td>
												{properties.map((property, i) => (
													<td
														key={property.listingId}
														className={`p-4 text-sm w-full ${
															fieldIndex % 2 === 1 ? "bg-white" : "bg-[#EEFAFF]"
														} ${
															i === properties.length - 1
																? "rounded-r-[10px]"
																: ""
														} ${
															properties[0].listingId === property.listingId
																? "sticky left-[100px] z-20"
																: ""
														}`}
													>
														{field === "listPrice" || field === "soldPrice"
															? `$${formatPrice(
																	convertToFormattedAmount(
																		Number(
																			property[field as keyof CmaProperty],
																		),
																	),
																)}`
															: field === "propertyTax"
																? `$${formatPrice(
																		Number(
																			property[field as keyof CmaProperty],
																		),
																	)}`
																: property[field as keyof CmaProperty]}
													</td>
												))}
											</tr>
										))}
								</tbody>
							))}
						</table>
					</div>
				</>
			)}
		</div>
	);
};

export { ComparativeMarketAnalysisView };

import "./exploreListingsViewStyles.css";
import { FaSearch } from "react-icons/fa";
import { ListCard } from "./Card/listCard";
// import { MapCard } from "./Card/mapCard";
import { SearchField } from "./searchField";
import { LoadingCircle } from "../common/loading";
import { SortOptions } from "../common/sortOptions";
// import { Swiper, SwiperSlide } from "swiper/react";
import { SearchMapView } from "./SearchMap/searchMap";
import useNavbarStore from "../../stores/navBarStatus";
import { NoListingsCard } from "./NoListings/noListingsCard";
import { useCallback, useEffect, useRef, useState } from "react";
// import { testExploreHomesData } from "../../mocks/searchTestData";
import { useSearchedCitiesStore } from "../../stores/exploreListings";
// import type { SearchListingInterface } from "../../interfaces/searchListing";
import {
	useFilterPanelStore,
	useSearchFiltersStore,
} from "../../stores/filterStore";
import {
	type SearchListingInterface,
	// fetchExploreLisitings,
	fetchExploreListings,
} from "../../services/exploreListings";

import {
	useFilteredPropertiesStore,
	useListingStore,
} from "../../stores/exploreListings";

import "swiper/css";

const BuyerExploreListingsView = () => {
	const [loading, setLoading] = useState(false);
	const [properties, setProperties] = useState<SearchListingInterface[]>([]);
	const [showSortOptions, setShowSortOptions] = useState(false);
	const [selectedSortOption, setSelectedSortOption] = useState("Default");
	const [sortFilter, setSortFilter] = useState({
		page: "1",
		limit: "100",
		sortBy: "date",
		sortOrder: "desc",
	});
	const [mapOpen, setMapOpen] = useState<boolean>(false);
	// const [filteredProperties, setFilteredProperties] = useState<
	//   SearchListingInterface[]
	// >([]);
	const [clickedProperties, setClickedProperties] = useState<
		SearchListingInterface[]
	>([]);

	const { filterPanelVisible } = useFilterPanelStore();
	const { showNavbar, hideNavbar } = useNavbarStore();
	const { listings, setListings, getListings } = useListingStore();

	const optionsRef = useRef<HTMLDivElement | null>(null);

	const getAllPreferences = useSearchFiltersStore(
		(state) => state.getAllPreferences,
	);

	const filters = getAllPreferences();

	const { filteredProperties, setFilteredProperties, getFilteredProperties } =
		useFilteredPropertiesStore();

	const { cities } = useSearchedCitiesStore();

	const propertiesToDisplay: SearchListingInterface[] =
		filteredProperties?.length ? filteredProperties : properties;

	const toggleMap = () => {
		setMapOpen(!mapOpen);
	};

	const toggleOptions = () => {
		setShowSortOptions(!showSortOptions);
	};

	const fetchProperties = useCallback(async () => {
		setLoading(true);
		const fetchedProperties = await fetchExploreListings(
			sortFilter.page,
			sortFilter.limit,
			sortFilter.sortBy,
			sortFilter.sortOrder,
			undefined,
			undefined,
			undefined,
			cities && cities.length > 0 ? cities[0] : "",
			filters,
		);
		setListings([...fetchedProperties]);
		setProperties([...fetchedProperties]);
		// console.log("properties", fetchedProperties);
		setLoading(false);
	}, [sortFilter, cities, filters, setListings]);

	useEffect(() => {
		const initializePage = async () => {
			await fetchProperties();
		};

		initializePage();
	}, [sortFilter]);

	useEffect(() => {
		mapOpen && !filterPanelVisible ? hideNavbar() : showNavbar();
	}, [mapOpen, hideNavbar, showNavbar, filterPanelVisible]);

	useEffect(() => {
		if (showSortOptions && optionsRef.current) {
			const optionsRect = optionsRef.current.getBoundingClientRect();
			const isOffScreen = optionsRect.right > window.innerWidth;

			if (isOffScreen) {
				optionsRef.current.style.left = `-${
					optionsRect.right - window.innerWidth + 20
				}px`;
			}
		}
	}, [showSortOptions]);

	useEffect(() => {
		const defaultPage = "1";
		const defaultLimit = "100";

		switch (true) {
			case selectedSortOption === "Default":
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "desc",
				});
				break;
			case selectedSortOption === "Newest":
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "desc",
				});
				break;
			case selectedSortOption === "Oldest":
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "asc",
				});
				break;
			case selectedSortOption === "Price Low to High":
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "price",
					sortOrder: "asc",
				});
				break;
			case selectedSortOption === "Price High to Low":
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "price",
					sortOrder: "desc",
				});
				break;
			default:
				break;
		}
	}, [selectedSortOption]);

	return (
		<div
			className={`relative ${mapOpen && "overflow-hidden"} ${!mapOpen && "mb-20"}`}
		>
			{mapOpen && (
				<div className={"h-[100vh]"}>
					<SearchMapView
						// setFilteredProperties={setFilteredProperties}
						setClickedProperties={setClickedProperties}
					/>
				</div>
			)}

			{/* GREEN HEADER */}

			{!mapOpen && (
				<div
					className={`green-header ${
						mapOpen ? "-translate-y-[120vh]" : "translate-y-0"
					}`}
				/>
			)}

			{/* SEARCH FIELD */}

			<div
				className={`search-field-container ${
					window.bridge ? "pt-bridge-inset-top" : "pt-10"
				} z-20`}
			>
				<SearchField />
			</div>

			{/* SHOW MAP TAB*/}
			<div
				onClick={toggleMap}
				onKeyDown={toggleMap}
				className={`show-map-tab
    ${
			mapOpen
				? "translate-y-[70vh] w-full h-[10vh] py-0 overflow-hidden "
				: "-translate-y-[3vh] show-map-tab-height"
		}`}
			>
				{mapOpen && (
					<hr className="w-1/4 absolute top-3 border-t-4 border-gray-300" />
				)}
				<div className="flex items-center">
					{!mapOpen && <FaSearch className="text-black mr-2" />}
					<span className="text-black font-[550]">
						{mapOpen
							? `${filteredProperties.length} Homes In Search`
							: "Show Map"}
					</span>
				</div>
			</div>

			{/* LISTINGS VIEW */}

			{!mapOpen && (
				<div
					className={`listings-container ${
						mapOpen ? "translate-y-full h-full" : "translate-y-0"
					}`}
				>
					<div
						className={`w-full flex flex-col gap-4 relative ${
							filterPanelVisible ? "overflow-hidden" : "overflow-scroll"
						}`}
					>
						<div className="flex items-center justify-between p-4 shadow-sm">
							<span className="text-black font-semibold">
								{propertiesToDisplay?.length} Listings
							</span>
							<SortOptions
								showOptions={showSortOptions}
								optionsRef={optionsRef}
								onToggleOptions={toggleOptions}
								selectedOption={selectedSortOption}
								setSelectedOption={setSelectedSortOption}
							/>
						</div>
						{!loading &&
							propertiesToDisplay.length > 0 &&
							propertiesToDisplay.map((property) => (
								<div key={property.listingId} className="px-2">
									<ListCard key={property.listingId} property={property} />
								</div>
							))}

						{!loading && propertiesToDisplay.length === 0 && <NoListingsCard />}
						{loading && (
							<div className="flex items-center justify-center h-full w-full">
								<LoadingCircle />
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export { BuyerExploreListingsView };

import { useFinanceStore } from "../../stores/financeStore";
import { BasedBuyingGoalStep } from "./FinancesSteps/basedBuyingGoalStep";
import { StepFour } from "./FinancesSteps/stepFour";
import { StepOne } from "./FinancesSteps/stepOne";
import { StepThree } from "./FinancesSteps/stepThree";
import { StepTwo } from "./FinancesSteps/stepTwo";
import { VerticalLine } from "./FinancesSteps/verticalLine";
import { PaymentAssistance } from "./paymentAssistance";

const FinanceView = () => {
	const { completedSteps, basedBuyingGoal } = useFinanceStore();
	return (
		<div className="mx-4 mb-10">
			<PaymentAssistance />
			{!completedSteps[1] && (
				<>
					<StepOne />
					<VerticalLine />
				</>
			)}
			{!completedSteps[2] && (
				<>
					<StepTwo />
					<VerticalLine />
				</>
			)}
			{!completedSteps[3] && (
				<>
					<StepThree />
					<VerticalLine />
					<StepFour />
				</>
			)}

			{completedSteps[3] &&
				(basedBuyingGoal ? (
					<>
						<BasedBuyingGoalStep />
						<VerticalLine />
						<StepFour />
					</>
				) : (
					<StepFour />
				))}

			{completedSteps[1] && (
				<div className="border-t border-[#EEEEEE] text-red-400 pt-10 mt-10">
					<StepOne />
				</div>
			)}
			{completedSteps[2] && (
				<>
					<VerticalLine />
					<StepTwo />
				</>
			)}
			{completedSteps[3] && !basedBuyingGoal && (
				<>
					<VerticalLine />
					<StepThree />
				</>
			)}
		</div>
	);
};

export { FinanceView };

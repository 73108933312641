import { useNavigate } from "react-router-dom";
import { type ChangeEvent, useState } from "react";
import { getPreApproved } from "../../services/account";
import { isValidEmail } from "../../utils/emailValidator";
import PaperPlaneImage from "../../assets/Profile/paperPlane.png";

const GetPreApprovedEmailInputView = () => {
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const navigate = useNavigate();

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		setEmailError("");
	};

	const handleSendEmail = async () => {
		if (isValidEmail(email)) {
			try {
				await getPreApproved(email);
				navigate("/profile/get-preapproved/check-inbox");
			} catch (error) {
				console.error("Error sending pre-approval email", error);
				setEmailError("Failed to send email. Please try again later.");
			}
		} else {
			setEmailError("Please enter a valid email address.");
		}
	};

	return (
		<div className="flex flex-col space-y-8 justify-center items-center text-black pb-6 pt-2 px-2">
			<div className="space-y-2 flex flex-col justify-center items-center h-full w-full">
				<img src={PaperPlaneImage} alt="Paper plane" />
				<div className="space-y-4 w-full flex flex-col items-center text-center justify-center">
					<div className="font-bold text-3xl">Enter your email</div>
					<div className=" w-full flex flex-col items-start space-y-2">
						<input
							type="email"
							value={email}
							onChange={handleEmailChange}
							className="w-full bg-white h-[70px] rounded-[15px] px-2"
							style={{
								boxShadow:
									"0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
							}}
							placeholder="Enter your email"
						/>
						{emailError && (
							<div className="text-red-500 text-sm">{emailError}</div>
						)}
					</div>
				</div>
			</div>
			<div className="w-full">
				<button
					type="button"
					className="text-white bg-z-dark-moss-green rounded-full py-3.5 font-bold w-full"
					onClick={handleSendEmail}
				>
					Send email
				</button>
			</div>
		</div>
	);
};

export { GetPreApprovedEmailInputView };

import { create } from "zustand"
import { formatPrice } from "../utils/formatPrice"
import { convertToFormattedAmount } from "../utils/convertAmount"
import { PhoneValidator } from "../utils/phoneValidator"

interface InputFlowState {
    firstName:string
    setFirstName:(firstName:string) => void
    lastName:string
    setLastName:(firstName:string) => void
    phoneNumber:string
    setPhoneNumber:(firstName:string) => void
    firstNameError: string,
    lastNameError: string,
    phoneNumberError: string,
    setFirstNameError:(firstNameError:string) => void
    setLastNameError:(lastNameError:string) => void
    setPhoneNumberError:(phoneNumberError:string) => void
    savings:string;
    setSavings: (savings:string) => void
    income:string;
    setIncome: (income:string) => void
    debt:string;
    setDebt: (debt:string) => void   
    validateDetailsInputForm:() => boolean 
}

const useInputFlowStore = create<InputFlowState>((set) => ({
    firstName:'',
    setFirstName:(firstName:string) => set({firstName}),
    lastName:'',
    setLastName:(lastName:string) => set({lastName}),
    phoneNumber:'',
    setPhoneNumber:(phoneNumber:string) => set({phoneNumber}),
    firstNameError: '',
    lastNameError: '',
    phoneNumberError: '',
    setFirstNameError: (firstNameError:string) => set({ firstNameError }),
    setLastNameError: (lastNameError:string) => set({ lastNameError }),
    setPhoneNumberError: (phoneNumberError:string) => set({ phoneNumberError }),
    savings: formatPrice(convertToFormattedAmount(0)),
    setSavings: (savings: string) => set({savings}),
    income: formatPrice(convertToFormattedAmount(0)),
    setIncome:(income:string) => set({income}),
    debt: formatPrice(convertToFormattedAmount(0)),
    setDebt:(debt:string) => set({debt}),

    validateDetailsInputForm: () => {
        const { firstName, lastName, phoneNumber, setFirstNameError, setLastNameError, setPhoneNumberError } = useInputFlowStore.getState();
    
        let isValid = true;
    
        if (!firstName.trim() || /\d/.test(firstName)) {
          setFirstNameError("Please enter a value");
          isValid = false;
        } else {
          setFirstNameError("");
        }
    
        if (!lastName.trim() || /\d/.test(lastName)) {
          setLastNameError("Please enter a value");
          isValid = false;
        } else {
          setLastNameError("");
        }
    
        try {
          isValid = PhoneValidator.isValid(phoneNumber, "CA");
        } catch {
          isValid = false;
        }
        setPhoneNumberError(isValid ? "" : "Please enter a value");
    
        return isValid;
      },
  
}))

export {useInputFlowStore}
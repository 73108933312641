import type {
  CircleLayerSpecification,
  SymbolLayerSpecification,
} from "mapbox-gl";

// NOTE: EACH SYMBOL ON THE MAP IS COMPRISED OF TWO LAYERS

/**
 * - Defines the LABEL style for Single Listing points on the map.
 *
 * @constant {SymbolLayerSpecification} singleLabelLayerStyle - The configuration for a map layer.
 *
 * @property {string} id - The unique ID of the layer.
 * @property {string} type - The type of layer. In this case, it's a "symbol" layer.
 * @property {Array} filter - Filters out clustered points (i.e., only unclustered points will be displayed).
 * @property {Object} layout - Layout properties for the layer.
 * @property {string} layout.icon-image - The icon ID to associate with the map on map load.
 * @property {number} layout.icon-size - The size of the icon.
 * @property {boolean} layout.icon-allow-overlap - Whether icons are allowed to overlap.
 * @property {number} layout.icon-rotate - The rotation of the icon in degrees.
 * @property {string} layout.text-field - Text field to be displayed, using the 'price' property from GeoJSON data.
 * @property {number} layout.text-size - The size of the text.
 * @property {Array} layout.text-font - The font family for the text.
 * @property {boolean} layout.text-allow-overlap - Whether the text is allowed to overlap.
 * @property {string} layout.text-anchor - The position of the text relative to the symbol.
 * @property {Array} layout.text-offset - Offset of the text in relation to the icon.
 * @property {Object} paint - Paint properties for the layer.
 * @property {string} paint.text-color - The color of the text.
 * @property {string} source - The source of the data for this layer.
 *
 * @example
 *
 * // Use the singleLabelLayerStyle as a map layer style in a Mapbox GL map
 * <Layer {...singleLabelLayerStyle} />
 */

export const singleLabelLayerStyle: SymbolLayerSpecification = {
  id: "unclustered-point",
  type: "symbol",
  filter: ["!has", "cluster"], // Filter out clustered points
  layout: {
    "icon-image": "singleMapListing", // ID TO ASSOCIATE WITH MAP ON MAP LOAD //
    "icon-size": 0.9,
    "icon-allow-overlap": true,
    // "icon-rotate": 180,
    "text-field": "{price}", // Uses the 'price' property from GeoJSON data
    "text-size": 14,
    "text-font": ["Arial Unicode MS Bold"],
    "text-allow-overlap": true,
    "text-anchor": "top",
    "text-offset": [0.8, -0.9],
  },
  paint: {
    "text-color": "#ffffff",
  },
  source: "",
};

/**
 * - Defines the SYMBOL style for Single Listing points on the map.
 *
 * @constant {SymbolLayerSpecification} singleSymbolLayerStyle - The configuration for a symbol layer with dynamic icons.
 *
 * @property {string} id - The unique ID of the layer.
 * @property {string} type - The type of layer. In this case, it's a "symbol" layer.
 * @property {Array} filter - Filters out clustered points (i.e., only unclustered points will be displayed).
 * @property {Object} layout - Layout properties for the layer.
 * @property {Array} layout.icon-image - Dynamically uses the 'icon' property from the GeoJSON data to set the icon.
 * @property {number} layout.icon-size - The size of the icon.
 * @property {boolean} layout.icon-allow-overlap - Whether icons are allowed to overlap.
 * @property {Array<number>} layout.icon-offset - The offset of the icon in [x, y] coordinates, in pixels.
 * @property {string} source - The source of the data for this layer.
 *
 * @example
 * // Use the singleSymbolLayerStyle as a map layer style in a Mapbox GL map
 * <Layer {...singleSymbolLayerStyle}>
 */

export const singleSymbolLayerStyle: SymbolLayerSpecification = {
  id: "dynamic-icons",
  type: "symbol",
  filter: ["!has", "cluster"], // Filter out clustered points
  layout: {
    "icon-image": ["get", "icon"], // Uses the 'icon' property from GeoJSON data
    "icon-size": 1,
    "icon-allow-overlap": true,
    "icon-offset": [-27, -5],
  },
  source: "",
};

/**
 * Defines the SYMBOL style for displaying clusters of points on the map.
 *
 * @constant {CircleLayerSpecification} clusterSymbolLayerStyle - The configuration for a circle layer representing clusters.
 *
 * @property {string} id - The unique ID of the layer.
 * @property {string} type - The type of layer. In this case, it's a "circle" layer.
 * @property {string} source - The source of the data for this layer. In this case, it's "my-data".
 * @property {Array} filter - Filters out single points, displaying only clusters.
 * @property {Object} paint - Paint properties for the circle layer.
 * @property {string} paint.circle-color - The color of the cluster circles.
 * @property {number} paint.circle-radius - The radius of the cluster circles.
 * @property {number} paint.circle-stroke-width - The width of the stroke around the cluster circles.
 * @property {string} paint.circle-stroke-color - The color of the stroke around the cluster circles.
 *
 * @example
 * // Use the clusterSymbolLayerStyle as a map layer style in a Mapbox GL map
 * <Layer {...clusterSymbolLayerStyle}>
 */

export const clusterSymbolLayerStyle: CircleLayerSpecification = {
  id: "clusters",
  type: "circle",
  source: "my-data",
  filter: ["has", "cluster"], // Filter out single points
  paint: {
    "circle-color": "#456930",
    "circle-radius": 24,
    "circle-stroke-width": 4,
    "circle-stroke-color": "#FFFFFF",
  },
};

/**
 * Defines the LABEL style for displaying the {point_count} of clusters on the map.
 *
 * @constant {SymbolLayerSpecification} clusterLabelLayerStyle - The configuration for a symbol layer representing the cluster count labels.
 *
 * @property {string} id - The unique ID of the layer.
 * @property {string} type - The type of layer. In this case, it's a "symbol" layer.
 * @property {Object} layout - Layout properties for the symbol layer.
 * @property {string} layout.text-field - Renders the count of clustered points using the `point_count` property from GeoJSON data.
 * @property {number} layout.text-size - The size of the text displaying the cluster count.
 * @property {Array<string>} layout.text-font - The font family for the text (a combination of "DIN Offc Pro Bold" and "Arial Unicode MS Bold").
 * @property {boolean} layout.text-allow-overlap - Allows the text to overlap with other symbols.
 * @property {string} layout.text-anchor - Anchors the text in the center of the cluster.
 * @property {Object} paint - Paint properties for the text layer.
 * @property {string} paint.text-color - The color of the text (white in this case).
 * @property {string} source - The source of the data for this layer.
 *
 * @example
 * // Use the clusterLabelLayerStyle as a map layer style in a Mapbox GL map
 * <Layer {...clusterLabelLayerStyle} />
 */

export const clusterLabelLayerStyle: SymbolLayerSpecification = {
  id: "cluster-count",
  type: "symbol",
  layout: {
    "text-field": "{point_count}", // Render count of clustered points
    "text-size": 24,
    "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
    "text-allow-overlap": true,
    "text-anchor": "center",
  },
  paint: {
    "text-color": "#FFFFFF",
  },
  source: "",
};

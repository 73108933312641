import { create } from "zustand";

interface ShowLogOutModalStoreState {
  showLogOutModal: boolean;
  setShowLogOutModal: (show: boolean) => void;
}

const useLogOutModalStore = create<ShowLogOutModalStoreState>((set) => ({
    showLogOutModal: false,
  setShowLogOutModal: (show) => set({ showLogOutModal: show }),
}));

export { useLogOutModalStore };

import { useNavigate } from "react-router-dom";
import ClockIcon from "../../assets/clockIcon.svg";
import { useModalStore } from "../../stores/handleModalStore";
import { useShowingCartStore } from "../../stores/showingCartStore";
import { PropertyCard } from "../CartView/propertyCard";
import { useState } from "react";

const CheckoutCartPropertiesView = () => {
	const navigate = useNavigate();
	const { setCartModal, setMinCartModal } = useModalStore();
	const { setZlids, cartProperties } = useShowingCartStore();
	const [selectedProperties, setSelectedProperties] = useState<string[]>([]);

	const handleCheckboxChange = (listingId: string, isChecked: boolean) => {
		setSelectedProperties((prevSelected) =>
			isChecked
				? [...prevSelected, listingId]
				: prevSelected.filter((id) => id !== listingId),
		);
	};

	const saveChanges = () => {
		try {
			if (selectedProperties.length > 8) {
				setMinCartModal(false);
				setCartModal(true);
			} else if (selectedProperties.length < 2) {
				setMinCartModal(true);
				setCartModal(true);
			} else {
				setZlids(selectedProperties);
				navigate("/cart/date-selection");
			}
		} catch (error) {
			console.error("Failed to save changes", error);
		}
	};

	return (
		<div className="w-full text-black space-y-6 px-2 pt-2 pb-6">
			<div className="w-full items-start flex flex-col text-start text-z-black space-y-2">
				<div className="font-semibold flex text-start text-3xl items-start justify-start w-full text-z-prussian-blue">
					Choose the Homes You Want to See
				</div>
				<div className="text-z-prussian-blue">
					Select up to 8 properties. The rest will remain in your cart.
				</div>
			</div>
			{cartProperties.map((property, index) => (
				<div key={property.listingId} className="space-y-6">
					<PropertyCard
						property={property}
						checkoutProperty={true}
						isChecked={selectedProperties.includes(property.listingId)}
						onCheckboxChange={handleCheckboxChange}
					/>
					{index !== cartProperties.length - 1 && <hr />}
				</div>
			))}
			<button
				type="button"
				onClick={saveChanges}
				className="bg-z-dark-moss-green w-full font-semibold text-[20px] text-white flex items-center justify-center gap-2 rounded-full py-3.5 shadow-md drop-shadow-md"
			>
				<img src={ClockIcon} alt="" />
				Choose a date
			</button>
		</div>
	);
};

export { CheckoutCartPropertiesView };

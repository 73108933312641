import { useRef, useState } from "react";
import type { KeyboardEvent } from "react";
import { ZownForecast } from "./zownForecast";
// import { PropertyCard } from "./propertyCard";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { ComparableSales } from "./comparableSales";
import type { Listing } from "../../../interfaces/listing";
import { MortgageCalculatore } from "./mortgageCalculator";
import GrayArrowDownIcon from "../../../assets/grayArrowDownIcon.svg";
import { convertToFormattedAmount } from "../../../utils/convertAmount";
import PropertyDetailsIcon from "../../../assets/Listing/propertyDetailsIcon.svg";

import "swiper/css";
import type { StatisticsResponse } from "../../../interfaces/statistics";

interface AnalysisProps {
	listing: Listing;
	statistics?: StatisticsResponse | null;
}

const Analysis = ({ listing, statistics }: AnalysisProps) => {
	const [isExpanded, setIsExpanded] = useState(true);
	// const calculateMedian = (values: number[]) => {
	//   values.sort((a, b) => a - b);
	//   const mid = Math.floor(values.length / 2);
	//   return values.length % 2 !== 0
	//     ? values[mid]
	//     : (values[mid - 1] + values[mid]) / 2;
	// };

	// const calculateAverage = (values: number[]) => {
	//   const sum = values.reduce((acc, value) => acc + value, 0);
	//   return values.length > 0 ? sum / values.length : 0;
	// };

	// const getMostRecentDate = (dates: Date[]) => {
	//   return new Date(Math.max(...dates.map((date) => date.getTime())));
	// };

	// const comparableSales = listing.comparableSales;

	// const medianSalePrice = calculateMedian(
	//   comparableSales.map((sale) =>
	//     Number.parseInt(sale.soldPrice.replace(/[^0-9]/g, ""), 10)
	//   )
	// );

	// const avgDom = Math.round(
	//   calculateAverage(
	//     comparableSales.map((sale) => Number.parseInt(sale.dom, 10))
	//   )
	// );

	// const mostRecentSale = getMostRecentDate(
	//   comparableSales.map((sale) => new Date(sale.soldDate))
	// );

	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};

	return (
		<div className="w-full bg-white flex justify-between rounded-[20px] py-4 items-start shadow-md drop-shadow-lg">
			<div className="w-full">
				<div
					className="text-black font-semibold text-xl flex items-center justify-between w-full px-4 cursor-pointer"
					onClick={toggleSection}
					onKeyDown={handleKeyDown}
				>
					<div className="flex items-center gap-2">
						<img src={PropertyDetailsIcon} alt="" />
						Analysis
					</div>

					<img
						src={GrayArrowDownIcon}
						alt="Toggle Section"
						className={`transition-transform duration-300 ${
							isExpanded ? "rotate-180" : ""
						}`}
					/>
				</div>

				<div
					id="analysis-content"
					className={`overflow-hidden transition-[max-height] duration-300 ease-in-out space-y-6 ${
						isExpanded ? "max-h-auto" : "max-h-0"
					}`}
				>
					<hr className="h-px bg-[#F3F3F3] border-0 mt-6" />

					<MortgageCalculatore
						listingPrice={convertToFormattedAmount(
							listing.listingPrice,
						).toString()}
						mortgageRate={listing.mortgageRate}
						minDownPaymentNeeded={convertToFormattedAmount(
							listing.minDownPaymentNeeded,
						)}
					/>

					{statistics?.zownForecast && statistics.zownForecast.length > 0 && (
						<>
							<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
							<ZownForecast
								listing={listing}
								zownForecast={statistics.zownForecast}
							/>
						</>
					)}
					{/* <hr className="h-px mx-4 bg-[#F3F3F3] border-0" /> */}

					{/* <ComparableSales
            numberComparableSales={comparableSales.length}
            medianSalePrice={medianSalePrice}
            avgDom={avgDom}
            mostRecentSale={mostRecentSale}
          /> */}

					{/* <Swiper spaceBetween={16} slidesPerView={1.2} className="pb-10 px-4">
            {listing.comparableSales.map((property) => (
              <SwiperSlide key={property.listingId}>
                <PropertyCard
                  propertyType={property.propertyType}
                  status={"SOLD"}
                  price={property.soldPrice}
                  downPayment={property.downPaymentProvided}
                  date={property.soldDate}
                  beds={property.bed}
                  baths={property.bath}
                  similarListings={false}
                  imageUrl={property.imageUrl}
                  address={property.address}
                  sqft={property.sqft}
                />
              </SwiperSlide>
            ))}
          </Swiper> */}
					{/* <hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
          <div className="space-y-2">
            <div className="text-black text-lg font-semibold px-4">
              Similar Listings
            </div>
            <Swiper
              spaceBetween={16}
              slidesPerView={1.2}
              className="pb-10 px-4"
            >
              {listing.similarListings.map((property) => (
                <SwiperSlide key={property.listingId}>
                  <PropertyCard
                    propertyType={property.propertyType}
                    status={"FOR SALE"}
                    price={property.listPrice}
                    downPayment={property.downPaymentContribution}
                    date={property.listDate}
                    beds={property.bed}
                    baths={property.bath}
                    similarListings={true}
                    imageUrl={property.imageUrl}
                    address={property.address}
                    sqft={property.sqft}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}
				</div>
			</div>
		</div>
	);
};

export { Analysis };

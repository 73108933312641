import { type ChangeEvent, useEffect, useRef, useState } from "react";
import { MinusIcon, PlusIcon, xIcon } from "../../../assets/icons";
import { Button } from "../../common";
import { useFinanceStore } from "../../../stores/financeStore";
import { useModalStore } from "../../../stores/handleModalStore";
import { finance } from "../../../services/finance";
import { Finance } from "../../../interfaces/finance";

const cities = [
  { id: 1, name: "New York" },
  { id: 2, name: "Los Angeles" },
  { id: 3, name: "Chicago" },
  { id: 4, name: "Houston" },
  { id: 5, name: "Phoenix" },
  { id: 6, name: "Philadelphia" },
  { id: 7, name: "San Antonio" },
  { id: 8, name: "San Diego" },
  { id: 9, name: "Dallas" },
  { id: 10, name: "San Jose" },
];

const recommendedCities = [
  { id: 11, name: "Pickering, ON" },
  { id: 12, name: "Ajax, ON" },
  { id: 13, name: "Whitby, ON" },
  { id: 14, name: "Oshawa, ON" },
];

interface City {
  id: number;
  name: string;
}

const PlanBuyingHome = () => {
  const {
    setBuyingHomeData,
    selectedCities,
    setSelectedCities,
    remainingRecommendedCities,
    setRemainingRecommendedCities,
  } = useFinanceStore();

  const { buyingHomeState, setBuyingHomeState } = useModalStore();
  const [query, setQuery] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);

  const [filteredCities, setFilteredCities] = useState<City[]>([]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setQuery(query);
    setFilteredCities(
      query
        ? cities.filter((city) =>
            city.name.toLowerCase().includes(query.toLowerCase())
          )
        : []
    );
  };

  const handleRecommended = (city: City) => {
    setRemainingRecommendedCities((prev) =>
      prev.filter((item) => item.id !== city.id)
    );

    setSelectedCities((prev) => [...prev, city]);
  };

  const removeSelectedCities = (city: City) => {
    setSelectedCities((prev) => prev.filter((item) => item.id !== city.id));
  };

  const checkSelectedCities = (city: City) => {
    const isRecommendedCity = recommendedCities.some(
      (item) => item.name === city.name
    );
    if (isRecommendedCity) {
      setRemainingRecommendedCities((prev) => [...prev, city]);
    }
    removeSelectedCities(city);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        ulRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        !ulRef.current.contains(event.target as Node)
      ) {
        setQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const saveData = async() => {
    const cityNames = selectedCities.map((city) => city.name);
    setBuyingHomeData(cityNames);
    setBuyingHomeState(false);
    const payload:Finance = {
      homeGoalSelectedCities: selectedCities
    }
    console.log(payload);
    await finance(payload)
  };

  return (
    <>
      {buyingHomeState && (
        <div
          className={`fixed inset-0 bg-white p-6 z-30 ${
            window.bridge
              ? "pt-bridge-inset-top pb-bridge-inset-bottom"
              : "pb-24"
          } text-z-shark w-full`}
        >
          <h3 className="text-z-shark text-2xl font-bold mb-2">
            Where do you plan
            <br /> on buying a home?
          </h3>
          <span className="text-z-shark text-sm">
            Don’t worry, your best guess is fine, and you can always change it
            later.
          </span>
          <img
            onClick={() => setBuyingHomeState(false)}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                setBuyingHomeState(false);
              }
            }}
            className={`absolute  ${
              window.bridge ? " top-bridge-inset-top" : "top-5"
            } right-5`}
            width={16}
            src={xIcon}
            alt="arrow left icon"
          />
          <div className="mt-3 relative mb-3">
            <input
              ref={inputRef}
              className="h-16 w-full text-z-shark font-medium text-sm px-3 rounded-xl border border-[#EAEAEA] placeholder:text-[#D1D1D1]"
              type="text"
              onChange={handleSearch}
              value={query}
              placeholder="City or Postal Code"
            />
            {query && (
              <ul
                ref={ulRef}
                className="shadow-md bg-white w-full min-h-12 max-h-48 overflow-y-auto border border-[#EAEAEA] rounded-xl absolute top-[74px] z-10"
              >
                {filteredCities.length > 0
                  ? filteredCities.map((city) => (
                      <li
                        className="h-12 flex items-center border-t border-t-[#EAEAEA] first:border-t-0"
                        key={city.id}
                        onClick={() => {
                          setSelectedCities((prev) => [...prev, city]);
                          setQuery("");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            e.preventDefault();
                            setSelectedCities((prev) => [...prev, city]);
                            setQuery("");
                          }
                        }}
                      >
                        <span className="px-3">{city.name}</span>
                      </li>
                    ))
                  : query && (
                      <li className="absolute font-semibold top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        No cities found
                      </li>
                    )}
              </ul>
            )}
          </div>

          <div className="overflow-y-auto h-[calc(100%_-_220px)]">
            {remainingRecommendedCities.length > 0 && (
              <div className="mt-6 space-y-3">
                <span className="text-sm font-semibold text-z-shark">
                  Recommended Cities
                </span>
                <div className="flex items-center flex-wrap gap-4">
                  {remainingRecommendedCities.map((city) => (
                    <div
                      onClick={() => handleRecommended(city)}
                      key={city.id}
                      className="px-5 space-x-6 py-3 rounded-full bg-[#EFF6FF] flex items-center justify-between"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          e.preventDefault();
                          handleRecommended(city);
                        }
                      }}
                    >
                      <span className="text-z-shark text-sm font-semibold">
                        {city.name}
                      </span>
                      <PlusIcon />
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="mt-10 space-y-3">
              <span className="text-sm font-semibold text-z-shark">
                Selected Cities
              </span>
              {selectedCities.length > 0 && (
                <ul className="flex flex-col gap-4">
                  {selectedCities.map((city) => (
                    <li
                      onClick={() => checkSelectedCities(city)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          e.preventDefault();
                          checkSelectedCities(city);
                        }
                      }}
                      key={city.id}
                      className="flex max-h-[58px] flex-1 items-center justify-between bg-[#FCF9EA] rounded-lg px-3 py-3 text-xl text-z-shark font-semibold"
                    >
                      <span className="">{city.name}</span>
                      <MinusIcon />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <Button
            customClassName={`text-lg font-semibold absolute left-1/2 -translate-x-1/2 ${
              window.bridge ? "bottom-bridge-inset-bottom" : "bottom-5"
            }`}
            onClick={saveData}
            radius="60px"
            width="330px"
            height="lg"
          >
            Save
          </Button>
        </div>
      )}
    </>
  );
};

export { PlanBuyingHome };

interface IconProps {
	fill?: string;
}
const HeartIcon = ({ fill }: IconProps) => {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
		<svg
			width="21"
			height="18"
			viewBox="0 0 21 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.0612 3.13128L10.5 3.93453L10.9388 3.13128C11.7927 1.56809 13.477 0.5 15.4219 0.5C18.2359 0.5 20.5 2.73253 20.5 5.46429C20.5 8.92674 18.0295 11.9319 15.428 14.122C14.1405 15.2058 12.8505 16.065 11.8811 16.6535C11.397 16.9475 10.9942 17.1731 10.7136 17.3246C10.6316 17.3689 10.5601 17.4069 10.5 17.4383C10.4399 17.4069 10.3684 17.3689 10.2864 17.3246C10.0058 17.1731 9.60299 16.9475 9.11886 16.6535C8.14953 16.065 6.8595 15.2058 5.57201 14.122C2.97052 11.9319 0.5 8.92674 0.5 5.46429C0.5 2.73253 2.76406 0.5 5.57812 0.5C7.52295 0.5 9.20727 1.56809 10.0612 3.13128Z"
				fill={fill}
				stroke="#FF5757"
			/>
		</svg>
	);
};

export { HeartIcon };

import SortIcon from "../../assets/sortBlackIcon.svg";
import { type RefObject, useEffect, useRef } from "react";

interface SortOptionsProps {
	showOptions: boolean;
	optionsRef: RefObject<HTMLDivElement>;
	onToggleOptions: () => void;
	selectedOption: string;
	setSelectedOption: (option: string) => void;
}

export const SortOptions = ({
	showOptions,
	optionsRef,
	onToggleOptions,
	selectedOption,
	setSelectedOption,
}: SortOptionsProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);

	const handleOptionClick = (option: string) => {
		setSelectedOption(option);
		onToggleOptions();
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target as Node)
			) {
				onToggleOptions();
			}
		};

		if (showOptions) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showOptions, onToggleOptions]);

	const sortOptions = [
		"Default",
		"Newest",
		"Oldest",
		"Price Low to High",
		"Price High to Low",
		// "Lowest price/sqft",
		// "Highest price/sqft",
	];

	return (
		<div className="relative z-40" ref={containerRef}>
			<div
				onClick={onToggleOptions}
				onKeyDown={(e) => {
					if (e.key === "Enter" || e.key === " ") {
						onToggleOptions();
					}
				}}
				className="cursor-pointer flex items-center gap-2 text-black"
				aria-expanded={showOptions}
			>
				<img src={SortIcon} alt="Sort Icon" />
				{selectedOption}
			</div>
			{showOptions && (
				<div
					ref={optionsRef}
					className="absolute top-full mt-2 shadow-lg drop-shadow-md rounded-[10px] w-[199px] bg-white z-10"
				>
					{sortOptions.map((option, i) => (
						<button
							type="button"
							onClick={() => handleOptionClick(option)}
							key={option}
							className={`px-2 w-full text-start py-3 cursor-pointer text-black ${
								i === sortOptions.length - 1
									? "rounded-b-[10px]"
									: "border-b border-[#DCDCDC]"
							} ${i === 0 ? "rounded-t-[10px]" : ""} ${
								selectedOption === option
									? "bg-z-dark-moss-green text-white"
									: ""
							}`}
						>
							{option}
						</button>
					))}
				</div>
			)}
		</div>
	);
};

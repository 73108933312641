import { useEffect, useRef } from "react";
import Chart, { type TooltipItem } from "chart.js/auto";
import type { BestMonthsToSellInterface } from "../../../interfaces/statistics";

interface BestMonthsToSellStatistics {
	bestMonthsToSell: BestMonthsToSellInterface;
}

const BestMonthsToSell = ({ bestMonthsToSell }: BestMonthsToSellStatistics) => {
	const chartRef = useRef<Chart | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	useEffect(() => {
		if (canvasRef.current) {
			const months = Object.keys(bestMonthsToSell.statistics.soldPrice.mth);
			const counts = months.map(
				(month) => bestMonthsToSell.statistics.soldPrice.mth[month].count,
			); 
			const averages = months.map(
				(month) => bestMonthsToSell.statistics.soldPrice.mth[month].avg,
			); 

			if (chartRef.current) {
				chartRef.current.destroy();
			}

			const ctx = canvasRef.current.getContext("2d");
			if (ctx) {
				const gradient = ctx.createLinearGradient(0, 0, 0, 600);

				chartRef.current = new Chart(ctx, {
					type: "line",
					data: {
						labels: months,
						datasets: [
							{
								fill: true,
								backgroundColor: gradient,
								pointRadius: 0,
								borderColor: "#FECD07",
								tension: 0.5,
								data: counts,
							},
						],
					},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							legend: {
								display: false,
							},
							tooltip: {
								mode: "index",
								intersect: false,
								callbacks: {
									label: (tooltipItem: TooltipItem<"line">) => {
										const index = tooltipItem.dataIndex;
										const avg = averages[index];
										const count = tooltipItem.parsed.y;
										return `Count: ${count}, Avg Price: $${avg.toLocaleString()}`;
									},
								},
							},
						},
						scales: {
							x: {
								position: "bottom",
								grid: {
									display: false,
								},
							},
							y: {
								beginAtZero: true,
								title: {
									display: false,
									text: "Count",
								},
							},
						},
					},
				});
			}
		}

		return () => {
			chartRef.current?.destroy();
		};
	}, [bestMonthsToSell]);

	return (
		<div className="space-y-4 px-4">
			<div className="font-semibold text-lg">Best Months To Sell</div>
			<div className="text-sm text-[#676473]">
				This chart offers a clear visualization of how average selling prices
				fluctuate throughout the year - use these valuable insights to
				strategically plan the perfect time to list your property.
			</div>
			<canvas ref={canvasRef} id="myChart" className="h-auto max-h-[200px]" />
		</div>
	);
};

export { BestMonthsToSell };

import { Modal } from "../../common/modal"
import { useModalStore } from "../../../stores/handleModalStore"
import { FlinksBox } from "../FinancesSteps/flinks"
import { useFlinksStore } from "../../../stores/flinksStore"
import { xIcon } from "../../../assets/icons"
import { useFinanceStore } from "../../../stores/financeStore"
const FlinksModal = () => {
    const {setFlinksModal , flinksModal} = useModalStore()
	const {setConnectFlinks} = useFlinksStore()
	const { setQualification } = useFinanceStore();

	const handleClose = () => {
		setQualification(true);
		setFlinksModal(false);
	  };
	  
  return (
    <Modal disableOutsideClick setIsOpen={setFlinksModal} isOpen={flinksModal} customClassName="!px-3 max-h-[600px]" className="w-full">
		<div
			className="absolute top-3 right-4 text-xl"
			onClick={handleClose}
		>
			<img src={xIcon} width={14} alt="close icon" />
		</div>
		<div className="h-[550px] w-full">
			<FlinksBox setConnectFlinks={setConnectFlinks} />
		</div>
	</Modal>
  )
}

export {FlinksModal}
import type { JSX, SetStateAction } from "react";
import { useSwipeable } from "react-swipeable";
import { cloneElement, useState } from "react";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import { ProfileTopBar } from "../components/TopBars/profileTopBar";
import { ShowingTopBar } from "../components/TopBars/showingTopBar";
import { AddressLookupModal } from "../components/common/AddressLookup/addressLookupModal";
import { useAddressLookupStore } from "../stores/addressLookupStore";
import { CartModal } from "../components/Finances/Modal/cartModal";

const topBarMapping = (
	setTopBarHeight: (height: number) => void,
): { [key: string]: JSX.Element } => ({
	"/profile/liked-homes": (
		<ProfileTopBar
			showBackButton={true}
			bottomBorder={true}
			setTopBarHeight={setTopBarHeight}
			title="Liked Homes"
		/>
	),
	"/profile": (
		<ProfileTopBar
			setTopBarHeight={setTopBarHeight}
			title="Profile"
			showNotification={true}
			showChat={true}
		/>
	),
	"/profile/showing": <ShowingTopBar setTopBarHeight={setTopBarHeight} />,
	"/profile/showing/completed": (
		<ShowingTopBar setTopBarHeight={setTopBarHeight} />
	),
	"/profile/upload-preapproved": (
		<ProfileTopBar
			setTopBarHeight={setTopBarHeight}
			showBackButton={true}
			showNotification={true}
			showChat={true}
		/>
	),
	"/profile/overview": (
		<ProfileTopBar
			setTopBarHeight={setTopBarHeight}
			showBackButton={true}
			showNotification={true}
			showChat={true}
		/>
	),
	"/profile/notification": (
		<ProfileTopBar
			setTopBarHeight={setTopBarHeight}
			showBackButton={true}
			title="Notification"
		/>
	),
	"/profile/get-preapproved/email-view": (
		<ProfileTopBar
			setTopBarHeight={setTopBarHeight}
			showBackButton={true}
			closeButton={true}
		/>
	),
	"/profile/get-preapproved/check-inbox": (
		<ProfileTopBar
			setTopBarHeight={setTopBarHeight}
			showBackButton={true}
			closeButton={true}
		/>
	),
});

const SubRouteLayout = () => {
	const [topBarHeight, setTopBarHeight] = useState(0);
	const matches = useMatches();
	const currentRoute = matches[matches.length - 1];
	const navigate = useNavigate();
	const { addressLookupModalOpen } = useAddressLookupStore();

	const renderTopBar = () => {
		const topBar = topBarMapping(setTopBarHeight)[currentRoute?.pathname];
		return topBar ? (
			cloneElement(topBar, { setTopBarHeight })
		) : (
			<ProfileTopBar setTopBarHeight={setTopBarHeight} showBackButton={true} />
		);
	};

	const handlers = useSwipeable({
		onSwipedRight: (eventData) => {
			if (eventData.deltaX > 100) {
				const pathSegments = currentRoute?.pathname.split("/");
				const lastSegment = pathSegments?.[pathSegments.length - 1];
				if (lastSegment !== "cma") {
					navigate(-1);
				}
			}
		},
		preventScrollOnSwipe: true,
		trackMouse: true,
	});

	return (
		<div
			{...handlers}
			className={`absolute inset-0 h-full overflow-hidden flex flex-col justify-center items-center ${
				currentRoute?.pathname === "/profile/notification" ||
				currentRoute?.pathname === "/profile/upload-preapproved" ||
				currentRoute?.pathname === "/profile/overview"
					? "bg-[#F5F5F5]"
					: "bg-white"
			} ${
				currentRoute?.pathname === "/profile/delete-account/miss-you"
					? "bg-z-dark-moss-green"
					: ""
			}`}
		>
			{renderTopBar()}
			<div
				className={`relative text-white overflow-auto h-full w-full z-10 ${
					currentRoute?.pathname === "/" ? "rounded-t-[20px]" : ""
				}`}
				style={{
					marginTop: `${topBarHeight}px`,
					paddingBottom: `${
						(window.bridge?.adjustedContentInset.bottom ?? 0) - 20
					}px`,
				}}
			>
				<Outlet />
			</div>
			{addressLookupModalOpen && <AddressLookupModal />}
			<CartModal />
		</div>
	);
};

export { SubRouteLayout };

import {
	forwardRef,
	type ChangeEvent,
	type FocusEvent,
	type ReactNode,
} from "react";
import clsx from "clsx";

interface InputOptions {
	label?: string;
	placeholder?: string;
	startIcon?: ReactNode;
	value?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
	onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
	borderColor?: "grayType" | "darkType" | string;
	radius?: string;
	inputType?: string;
	name?: string;
	isError?: string;
}

const Input = forwardRef<HTMLInputElement, InputOptions>(
	(
		{
			label,
			placeholder,
			startIcon,
			value,
			onChange,
			onBlur,
			onFocus,
			borderColor = "darkType",
			radius = "rounded-lg",
			inputType = "text",
			name,
			isError,
		},
		ref,
	) => {
		return (
			<div
				style={{ borderRadius: radius }}
				className={clsx(
					"flex flex-col p-4 border-2 focus-within:ring-0 relative",
					borderColor === "grayType" && "border-[#EAEAEA]",
					borderColor === "darkType" && "border-[#32302F]",
					borderColor,
					isError && "!border-red-500",
				)}
			>
				{label && (
					<div className="text-sm mb-2 font-semibold text-[#5D5E5A]">
						{label}
					</div>
				)}
				<div className="flex items-center">
					{startIcon && (
						<img className="pr-3" alt="start icon" src={`${startIcon}`} />
					)}
					<input
						ref={ref}
						name={name}
						type={inputType}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
						className="w-full text-base text-gray-900 placeholder-gray-400 outline-none focus:outline-none"
					/>
				</div>
				{isError && (
					<div className="text-[#E31D1C] text-xs absolute -bottom-6 left-0">
						{isError}
					</div>
				)}
			</div>
		);
	},
);

export { Input };

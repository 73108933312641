import { useEffect, useRef } from "react";
import { BellIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { openLink } from "../../services/openLink";
import xButtonIcon from "../../assets/xButtonIcon.svg";
import ArrowBackIcon from "../../assets/arrowDownward.svg";
import ChatBlackIcon from "../../assets/icons/chatBlackIcon.svg";

interface ProfileTopBarProps {
	setTopBarHeight: (height: number) => void;
	title?: string;
	showBackButton?: boolean;
	showNotification?: boolean;
	showChat?: boolean;
	bottomBorder?: boolean;
	closeButton?: boolean;
}

const ProfileTopBar = ({
	setTopBarHeight,
	title,
	showBackButton,
	showNotification,
	showChat,
	bottomBorder,
	closeButton,
}: ProfileTopBarProps) => {
	const topBarRef = useRef<HTMLDivElement | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (topBarRef.current) {
			setTopBarHeight(topBarRef.current.offsetHeight);
		}
	}, [setTopBarHeight]);

	return (
		<div
			ref={topBarRef}
			className={`flex items-center justify-between w-full absolute top-0 pb-2 bg-transparent z-10 px-2 ${
				bottomBorder ? "border-b-2 py-6 border-b-[#EFEFEF]" : "py-2"
			}`}
			style={{
				paddingTop: `${
					(window.bridge?.adjustedContentInset?.top ?? 24) + 10
				}px`,
			}}
		>
			<div className="w-full ">
				{showBackButton && (
					<button
						type="button"
						onClick={() => navigate(-1)}
						className="h-[40px] w-[60px]"
					>
						<img src={ArrowBackIcon} alt="Back" className="rotate-90" />
					</button>
				)}
			</div>

			<div className="font-bold  text-xl flex items-center justify-center w-full whitespace-nowrap overflow-hidden text-ellipsis px-2">
				{title}
			</div>

			<div className="flex gap-4 items-center justify-end w-full">
				{showChat && (
					<button
						type="button"
						onClick={() =>
							openLink(
								"https://wa.me/12898166416?text=Hello,%20I%20am%20looking%20for%20assistance%20with%20the%20Zown%20mobile%20app.%20Can%20you%20help%20me%20with%20this",
							)
						}
					>
						<img src={ChatBlackIcon} alt="Chat" />
					</button>
				)}

				{showNotification && (
					<button
						type="button"
						onClick={() => navigate("/profile/notification")}
					>
						<img src={BellIcon} alt="Notifications" className="w-[25px]" />
					</button>
				)}
				{closeButton && (
					<button
						type="button"
						className="h-[40px] w-[60px] flex items-center justify-end"
						onClick={() => navigate("/")}
					>
						<img src={xButtonIcon} alt="Close" />
					</button>
				)}
			</div>
		</div>
	);
};

export { ProfileTopBar };

import { LoadingCircle } from "../common";
import { PlusIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import ArrowOpen from "../../assets/arrowOpen.svg";
import PinIcon from "../../assets/Cart/pinIcon.svg";
import ArrowIcon from "../../assets/arrowRightBlack.svg";
import ListingXbutton from "../../assets/listingXButton.svg";
import CirclePlusIcon from "../../assets/circlePlusIcon.svg";
import { fetchCartComparisonProperties } from "../../services/outings";
import NoMorePropertiesIcon from "../../assets/noMorePropertiesIcon.svg";
import type { CartComparisonProperty } from "../../interfaces/cartComparison";
import {
	useState,
	useCallback,
	useEffect,
	useRef,
	type KeyboardEvent,
} from "react";
import { formatPrice } from "../../utils/formatPrice";

const fieldTitles: { [key: string]: string } = {
	"address.city": "City",
	// transitDistance: "Distance to Transit",
	// walkScore: "Walk Score",
	// bikeScore: "Bike Score",
	// busScore: "Bus Score",
	totalZownSavings: "Total Savings",
	downPaymentGiven: "Down Payment Assistance",
	rateBuydownSavings: "Rate Buydown Savings",
	propertyType: "Property Type",
	listPrice: "Price",
	suggestedOfferPrice: "Suggested Offer Price",
	maintenanceFee: "Maintenance Fee",
	propertyTax: "Property Tax",
	bedrooms: "Bedrooms",
	bathrooms: "Bathrooms",
	squareFeet: "Square Footage",
	squareFeetLot: "Lot Square Footage",
	basement: "Basement",
	numberOfParkingSpaces: "Parking",
	backyard: "Backyard",
	pricePerSqft: "Price/SQFT",
	listToSoldRatio: "List-to-Sold Ratio",
	dom: "Days on Market",
	openHouseDate: "Open House Date",
};

const sections = [
	{
		title: "Location",
		fields: [
			"address.city",
			//  "transitDistance",
			//   "walkScore",
			//    "bikeScore",
			//     "busScore"
		],
	},
	{
		title: "Details",
		fields: [
			"totalZownSavings",
			"downPaymentGiven",
			"rateBuydownSavings",
			"propertyType",
			"listPrice",
			"suggestedOfferPrice",
			"maintenanceFee",
			"propertyTax",
		],
	},
	{
		title: "Features",
		fields: [
			"bedrooms",
			"bathrooms",
			"squareFootage",
			"lotSquareFootage",
			"basement",
		],
	},
	{
		title: "More Details",
		fields: [
			"numberOfParkingSpaces",
			"backyard",
			"pricePerSqft",
			"listToSoldRatio",
			"dom",
			"openHouseDate",
		],
	},
];

const CartComparison = () => {
	const [properties, setProperties] = useState<CartComparisonProperty[]>([]);

	const [pinnedProperty, setPinnedProperty] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [expandedSections, setExpandedSections] = useState(() =>
		sections.reduce(
			(acc, section) => {
				acc[section.title] = true;
				return acc;
			},
			{} as { [key: string]: boolean },
		),
	);
	const [showOptions, setShowOptions] = useState(false);
	const [compareProperties, setCompareProperties] = useState<
		CartComparisonProperty[]
	>([]);

	const fetchProperties = useCallback(async () => {
		try {
			setLoading(true);
			const response = await fetchCartComparisonProperties();
			setProperties(response);
			setLoading(false);
		} catch (error) {
			console.error("Failed to fetch listing data", error);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchProperties();
	}, [fetchProperties]);

	const handlePin = (zlid: string) => {
		if (pinnedProperty === zlid) {
			setPinnedProperty(null);
		} else {
			setPinnedProperty(zlid);
		}

		const updatedProperties = properties.filter(
			(property) => property.listingId !== zlid,
		);
		const pinned = properties.find((property) => property.listingId === zlid);
		if (pinned) {
			setProperties([pinned, ...updatedProperties]);
		}
	};

	const toggleSection = (sectionTitle: string) => {
		setExpandedSections((prev) => ({
			...prev,
			[sectionTitle]: !prev[sectionTitle],
		}));
	};

	const toggleOptions = useCallback(() => {
		setShowOptions((prev) => !prev);
	}, []);

	const handleKeyDown = (event: KeyboardEvent, zlid: string) => {
		if (event.key === "Enter" || event.key === " ") {
			event.preventDefault();
			handlePin(zlid);
		}
	};

	const handleSectionKeyDown = (
		event: KeyboardEvent<HTMLElement>,
		sectionTitle: string,
	) => {
		if (event.key === "Enter" || event.key === " ") {
			toggleSection(sectionTitle);
		}
	};

	const handleAddProperty = async (zlid: string) => {
		const propertyToAdd = compareProperties.find(
			(property) => property.listingId === zlid,
		);
		if (propertyToAdd) {
			setProperties((prevProperties) => [
				...prevProperties.filter((property) => property.listingId !== zlid),
				propertyToAdd,
			]);
			setCompareProperties((prevCompareProperties) =>
				prevCompareProperties.filter((property) => property.listingId !== zlid),
			);
		}
	};

	const optionsRef = useRef<HTMLDivElement | null>(null);

	const handleDeletePropertyToCompare = (zlid: string) => {
		const propertyToRemove = properties.find(
			(property) => property.listingId === zlid,
		);
		if (propertyToRemove) {
			setCompareProperties((prevCompareProperties) => [
				...prevCompareProperties.filter(
					(property) => property.listingId !== zlid,
				),
				propertyToRemove,
			]);
			setProperties((prevProperties) =>
				prevProperties.filter((property) => property.listingId !== zlid),
			);
		}
	};

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (
				optionsRef.current &&
				!optionsRef.current.contains(event.target as Node)
			) {
				toggleOptions();
			}
		},
		[toggleOptions],
	);

	useEffect(() => {
		if (showOptions) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showOptions, handleClickOutside]);

	return (
		<div
			className={`w-full text-black space-y-6 pt-5 pb-6 ${
				loading || properties.length === 0 ? "h-full" : ""
			} bg-white`}
		>
			{loading && (
				<div className="flex items-center justify-center h-full">
					<LoadingCircle />
				</div>
			)}
			{!loading && properties.length === 0 && (
				<div className="flex items-center text-center justify-center flex-col h-full w-full px-4 space-y-8">
					<div className="space-y-3">
						<div className="font-bold text-3xl">Oops !</div>
						<div className="text-2xl">
							no <span className="text-[#FF5757]">properties</span> to compare
						</div>
					</div>
					<img src={NoMorePropertiesIcon} alt="No More Properties" />
					<div className="w-full space-y-1">
						<div className="text-xl">add properties to your cart</div>
						<button
							type="button"
							className="bg-z-dark-moss-green text-white font-bold text-xl py-3.5 w-full rounded-full"
							onClick={() => navigate("/profile/liked-homes")}
						>
							My liked homes
						</button>
					</div>
				</div>
			)}
			{!loading && properties.length !== 0 && (
				<>
					<div className="flex items-center justify-between px-2">
						<div className="rounded-full font-bold text-sm bg-z-abbey py-1 px-2">
							Checkout Comparison
						</div>
					</div>
					<div className="overflow-x-auto no-scrollbar ml-2 pr-2">
						<table className="border-collapse w-full">
							<thead className="w-full">
								<tr className="bg-white w-full">
									<th className="sticky w-[100px] h-[100px] left-0 top-0 bg-white rounded-r-[10px] z-30">
										<button
											type="button"
											className="flex w-full h-full justify-center items-center bg-[#EEFAFF] rounded-[10px]"
											onClick={toggleOptions}
										>
											<PlusIcon />
										</button>
										{showOptions && (
											<div
												ref={optionsRef}
												className="absolute top-full mt-2 shadow-lg drop-shadow-md rounded-[10px] text-sm w-[301px] bg-white text-start z-20"
											>
												{compareProperties.map((property) => (
													<button
														type="button"
														key={property.listingId}
														onClick={() =>
															handleAddProperty(property.listingId)
														}
														className="py-3 px-2 border-b w-full border-[#DCDCDC] cursor-pointer flex items-center justify-between"
													>
														<div>{property.address.address1}</div>

														<div className="text-xl font-thin rounded-full flex items-center justify-center text-center">
															<img src={CirclePlusIcon} alt="Add Property" />
														</div>
													</button>
												))}
											</div>
										)}
									</th>
									{properties.map((property) => (
										<th
											key={property.listingId}
											className={`pl-4 w-full ${
												pinnedProperty === property.listingId
													? "sticky left-[100px] bg-white z-20 rounded-r-[10px]"
													: ""
											}`}
										>
											<div className="relative   h-[100px] min-w-[140px]">
												<button
													type="button"
													onClick={() =>
														navigate(`/listing/${property.listingId}`)
													}
													className="rounded-[10px] w-full"
												>
													<img
														src={property.displayImageUrl}
														alt={property.address.address1}
														className="w-full min-w-[140px] h-[100px] object-cover rounded-[10px]"
													/>
												</button>
												<div className="absolute p-2.5 right-0 bottom-0 bg-z-abbey rounded-br-[10px] rounded-tl-[10px]">
													<img src={ArrowOpen} alt="Arrow Open Icon" />
												</div>
												<div
													className="absolute cursor-pointer bottom-0.5 left-1 w-[32px] flex items-center justify-start h-[32px] rounded-full"
													onClick={() => handlePin(property.listingId)}
													onKeyDown={(e) =>
														handleKeyDown(e, property.listingId)
													}
												>
													<div
														className={`w-[22px] h-[22px] drop-shadow-lg rounded-full flex items-center justify-center  ${
															pinnedProperty === property.listingId
																? "bg-z-abbey"
																: "bg-white"
														}`}
													>
														<img src={PinIcon} alt="Pin Property" />
													</div>
												</div>
												<div className="text-white font-bold text-xs absolute top-1 px-1 flex justify-between w-full items-center">
													<div
														className="truncate max-w-[calc(100%-26px)]"
														style={{
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}
													>
														{property.address.address1}
													</div>
													<button
														type="button"
														className="h-[22px] drop-shadow-lg w-[22px]"
														onClick={() =>
															handleDeletePropertyToCompare(property.listingId)
														}
													>
														<img src={ListingXbutton} alt="Remove Property" />
													</button>
												</div>
											</div>
										</th>
									))}
								</tr>
							</thead>
							{sections.map((section) => (
								<tbody key={section.title}>
									<tr>
										<th
											colSpan={properties.length + 1}
											className="p-4 text-left flex items-center w-full gap-2 sticky top-0 left-0 bg-white z-20 cursor-pointer"
											onClick={() => toggleSection(section.title)}
											onKeyDown={(e) => handleSectionKeyDown(e, section.title)}
										>
											{section.title}
											<img
												src={ArrowIcon}
												alt=""
												className={`transform transition-transform ${
													expandedSections[section.title] ? "rotate-90" : ""
												}`}
											/>
										</th>
									</tr>
									{expandedSections[section.title] &&
										section.fields.map((field, fieldIndex) => (
											<tr
												key={field}
												className={
													fieldIndex % 2 === 1 ? "bg-white" : "bg-[#EEFAFF]"
												}
											>
												<td
													className={`p-4 rounded-l-[10px] font-bold text-xs sticky left-0 z-20 ${
														fieldIndex % 2 === 1 ? "bg-white" : "bg-[#EEFAFF]"
													}`}
												>
													{fieldTitles[field] || field}
												</td>
												{properties.map((property, i) => (
													<td
														key={property.listingId}
														className={`p-4 text-sm ${
															fieldIndex % 2 === 1 ? "bg-white" : "bg-[#EEFAFF]"
														} ${
															i === properties.length - 1
																? "rounded-r-[10px]"
																: ""
														} ${
															pinnedProperty === property.listingId
																? "sticky left-[100px] z-20"
																: ""
														}`}
													>
														{[
															"listPrice",
															"totalZownSavings",
															"downPaymentGiven",
															"rateBuydownSavings",
															"propertyTax",
															"pricePerSqft",
															"suggestedOfferPrice",
														].includes(field)
														? `$${formatPrice(property[field as keyof CartComparisonProperty])}`
														: field === "listToSoldRatio"
														? `${Number(property.listToSoldRatio).toFixed(2)}`
														: property[field as keyof CartComparisonProperty]}
													</td>
												))}
											</tr>
										))}
								</tbody>
							))}
						</table>
					</div>
				</>
			)}
		</div>
	);
};

export { CartComparison };

import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import FilterIcon from "../../assets/filterIcon.svg";
import useNavbarStore from "../../stores/navBarStatus";
import { useFilterPanelStore } from "../../stores/filterStore";

const SearchField = () => {
	const navigate = useNavigate();
	const { hideNavbar } = useNavbarStore();

	const { showFilterPanel, setFilterPanelOrigin } = useFilterPanelStore();

	const handleTextSearch = () => {
		navigate("/search");
		hideNavbar();
	};

	const handleFilterClick = () => {
		showFilterPanel();
		setFilterPanelOrigin("searchField");
		hideNavbar();
	};

	return (
		<div className="grid grid-cols-12 gap-2 items-center max-w-md w-full">
			<div className="col-span-1" />
			<div
				className="col-span-9 flex items-center bg-white rounded-full overflow-hidden shadow-lg"
				onClick={handleTextSearch}
				onKeyDown={handleTextSearch}
			>
				<div className="pl-4  bg-white">
					<FaSearch className="text-black text-lg" />
				</div>
				<input
					type="text"
					placeholder="Search..."
					className="flex-1 py-4 px-4 text-lg text-black focus:outline-none"
					readOnly={true}
				/>
			</div>

			<button
				type={"button"}
				onClick={handleFilterClick}
				onKeyDown={handleFilterClick}
				className="col-span-1 w-12 h-12 rounded-full drop-shadow-lg"
			>
				<img src={FilterIcon} alt="" />
			</button>
			<div className="col-span-1" />
		</div>
	);
};

export { SearchField };

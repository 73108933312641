import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { updatePreferences } from "../../services/preferences";
import { usePropertyCityStore } from "../../stores/propertyCitySelection";
import { useUserProfileStore } from "../../stores/profileStore";

interface CityChipProps {
	label: string;
	searchTerm?: string;
	onClick: (label: string) => void;
}

const SearchSelector = ({ label, searchTerm, onClick }: CityChipProps) => {
	const highlightMatchingText = (text: string, search: string | undefined) => {
		const searchTerm = typeof search === "string" ? search : "";

		if (!searchTerm.trim()) {
			return text;
		}

		const escapedSearch = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
		const regex = new RegExp(`(${escapedSearch})`, "gi");
		const parts = text.split(regex);

		return parts.map((part, index) =>
			regex.test(part) ? (
				<span key={`${label}-${index}`} className="font-extrabold text-black">
					{part}
				</span>
			) : (
				<span className="font-normal" key={`${label}-${index}`}>
					{part}
				</span>
			),
		);
	};

	return (
		<div
			className="flex m-2 border-b overflow-hidden font-medium py-3 text-sm cursor-pointer"
			onClick={() => onClick(label)}
			onKeyDown={() => onClick(label)}
		>
			<div className="flex items-center pr-2">
				<FaPlus className="text-sm" />
			</div>
			<div className="flex-1 flex items-center text-black">
				{highlightMatchingText(label, searchTerm)}
			</div>
		</div>
	);
};

interface CityChipProps {
	label: string;
	searchTerm?: string;
	onClick: (label: string) => void;
}

enum SelectedCityData {
	Hamilton = "Hamilton",
	Toronto = "Toronto",
	Tiny = "Tiny",
	Barrie = "Barrie",
	Kingston = "Kingston",
	Vaughan = "Vaughan",
	Ottawa = "Ottawa",
	Brampton = "Brampton",
	StCatharines = "St. Catharines",
	Collingwood = "Collingwood",
	Mississauga = "Mississauga",
	Brantford = "Brantford",
	Thorold = "Thorold",
	Innisfil = "Innisfil",
	Clearview = "Clearview",
	Guelph = "Guelph",
	Kitchener = "Kitchener",
	Cambridge = "Cambridge",
	NiagaraOnTheLake = "Niagara-on-the-Lake",
	NiagaraFalls = "Niagara Falls",
	DuttonDunwich = "Dutton/Dunwich",
	Minto = "Minto",
	Magnetawan = "Magnetawan",
	Midland = "Midland",
	Perry = "Perry",
	WellingtonNorth = "Wellington North",
	Lincoln = "Lincoln",
	Madoc = "Madoc",
	Milton = "Milton",
	TheBlueMountains = "The Blue Mountains",
	Caledon = "Caledon",
	RichmondHill = "Richmond Hill",
	GeorgianBluffs = "Georgian Bluffs",
	NorthFrontenac = "North Frontenac",
	NorthernBrucePeninsula = "Northern Bruce Peninsula",
	FortErie = "Fort Erie",
	NorthBay = "North Bay",
	Welland = "Welland",
	Huntsville = "Huntsville",
	CavanMonaghan = "Cavan Monaghan",
	KawarthaLakes = "Kawartha Lakes",
	Grimsby = "Grimsby",
	Hawkesbury = "Hawkesbury",
	Burlington = "Burlington",
	Beckwith = "Beckwith",
	LeedsAndTheThousandIslands = "Leeds and the Thousand Islands",
	Uxbridge = "Uxbridge",
	Severn = "Severn",
	Markham = "Markham",
	CentralHuron = "Central Huron",
	SouthHuron = "South Huron",
	London = "London",
}

const recommendedCities = ["Markham", "Toronto", "Brampton", "Richmond Hill"];

const testSearch = Object.values(SelectedCityData);

const CityChip = ({ label, onClick }: CityChipProps) => {
	return (
		<div
			onClick={() => onClick(label)}
			key={label}
			className="px-5 space-x-6 py-3 rounded-full bg-[#EFF6FF] flex items-center justify-between"
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					e.preventDefault();
					onClick(label);
				}
			}}
		>
			<span className="text-z-shark text-sm font-semibold">{label}</span>
			<FaPlus className="text-[#32302F]" />
		</div>
	);
};

const SelectedCity = ({ label, onClick }: CityChipProps) => {
	return (
		<div
			className="flex my-2 rounded-md bg-gray-100 border overflow-hidden"
			onClick={() => {
				onClick(label);
			}}
			onKeyDown={() => {
				onClick(label);
			}}
		>
			<div className="flex-1 py-3 px-4 text-sm flex items-center text-black font-bold">
				{label}
			</div>
			<div className="flex items-center pr-2">
				<FaMinus className="text-[#32302F]" />
			</div>
		</div>
	);
};

const BuyerSearchView = () => {
	const [searchActive, setSearchActive] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [searching, setSearching] = useState<boolean>(false);
	const [remainingRecommendedCities, setRemainingRecommendedCities] =
		useState<string[]>(recommendedCities);
	const [filteredCities, setFilteredCities] = useState<string[]>([]);
	const { selectedCities, setSelectedCities } = usePropertyCityStore();
	const location = useLocation();
	const origin = location.state?.origin;
	const { userProfile, setUserProfile } = useUserProfileStore();

	const inputRef = useRef<HTMLInputElement>(null);
	const buttonContainerRef = useRef<HTMLDivElement>(null);

	const navigate = useNavigate();

	const fetchCities = (term: string): Promise<string[]> => {
		return new Promise((resolve) => {
			setTimeout(() => {
				const results = testSearch.filter((city) =>
					city.toLowerCase().includes(term.toLowerCase()),
				);
				resolve(results);
			}, 500);
		});
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const handleAddCity = useCallback(
		(label: string) => {
			if (!label || typeof label !== "string") {
				console.warn("Invalid label:", label);
				return;
			}

			console.log("Adding city:", label);
			setRemainingRecommendedCities((prev) =>
				prev.filter((item) => item !== label),
			);

			setSelectedCities(label);
		},
		[setRemainingRecommendedCities, setSelectedCities],
	);

	useEffect(() => {
		const userSelectedCities =
			userProfile?.matchingPreferences?.filters?.selectedCities || [];

		console.log("User selected cities from profile:", userSelectedCities);

		for (const city of userSelectedCities) {
			if (city && typeof city === "string") {
				console.log("Adding city from profile:", city);
				handleAddCity(city);
			} else {
				console.warn("Skipping invalid city from profile:", city);
			}
		}
	}, [userProfile, handleAddCity]);

	console.log(selectedCities);

	const handleRemoveCity = (label: string) => {
		const recCities = new Set(recommendedCities);

		setSelectedCities(label);

		setRemainingRecommendedCities((prevCities) => {
			if (recCities.has(label)) {
				return [...prevCities, label];
			}
			return prevCities;
		});
	};

	const handleContinue = async () => {
		if (origin === "matchingFilter") {
			if (selectedCities) {
				const matchedEnumValues = selectedCities
					.map((city) => {
						const enumKey = Object.keys(SelectedCityData).find(
							(key) =>
								SelectedCityData[key as keyof typeof SelectedCityData] === city,
						);
						return enumKey
							? SelectedCityData[enumKey as keyof typeof SelectedCityData]
							: null;
					})
					.filter((city) => city !== null);

				const mappedFilters = {
					selectedCities:
						matchedEnumValues && matchedEnumValues.length > 0
							? matchedEnumValues
							: undefined,
				};

				await updatePreferences(undefined, undefined, mappedFilters);
			}
			navigate("/profile/points-interest");
		} else {
			navigate("/explore");
		}
	};

	return (
		<div className="bg-white flex flex-col h-full relative">
			<div className="flex flex-col w-full justify-start pt-2 space-y-2 px-4">
				<div className="text-black font-bold text-2xl">
					{searchActive ? "Where" : "Pick a City"}
				</div>
				<div className="items-center w-full">
					<div className="flex rounded-full border-2 overflow-hidden">
						{searchActive && (
							<div className="flex items-center pl-4">
								<FaSearch className="text-gray-500 text-lg" />
							</div>
						)}
						<input
							ref={inputRef}
							type="text"
							placeholder={"Enter a city"}
							className="flex-1 py-5 px-4 text-sm text-black"
							onFocus={() => setSearchActive(true)}
							onChange={(e) => {
								const value = e.target.value;
								setSearchTerm(value);
								fetchCities(value).then(setFilteredCities);
							}}
							value={searchTerm}
						/>
					</div>
				</div>
			</div>
			<div className="overflow-y-auto ">
				{!searchActive && (
					<div className="px-4">
						<div className="flex flex-col w-full py-6">
							<span className="text-black font-bold text-md pb-1">
								Recommended Cities
							</span>
							<div className="flex items-center flex-wrap gap-2">
								{remainingRecommendedCities.map((label, index) => (
									<CityChip
										key={`${label}-${index}`}
										label={label}
										onClick={handleAddCity}
									/>
								))}
							</div>
						</div>

						<div className="flex flex-col w-full py-6">
							<span className="text-black font-bold text-md">
								Selected Cities
							</span>
							{selectedCities.map((label, index) => (
								<SelectedCity
									key={`${label}-${index}`}
									label={label}
									onClick={handleRemoveCity}
								/>
							))}
						</div>
					</div>
				)}

				{searchActive &&
					filteredCities.length > 0 &&
					filteredCities.map((label) => (
						<SearchSelector
							key={label}
							label={label}
							searchTerm={searchTerm}
							onClick={(label) => {
								setSelectedCities(label);
								setSearchTerm("");
								setSearchActive(false);
							}}
						/>
					))}
			</div>

			<div
				ref={buttonContainerRef}
				className={`absolute bottom-0 ${
					window.bridge ? "pb-bridge-inset-bottom" : "pb-4"
				} left-0 w-full h-[10vh] z-50 flex justify-center items-center`}
			>
				<button
					type={"button"}
					className="bg-z-dark-moss-green w-3/4 p-4 rounded-3xl font-bold text-white"
					onClick={handleContinue}
				>
					Continue
				</button>
			</div>
		</div>
	);
};

export { BuyerSearchView };

import { useNavigate } from "react-router-dom";
import { ProfilePicture } from "./profilePicture";
import { formatPrice } from "../../utils/formatPrice";
import { PhoneValidator } from "../../utils/phoneValidator";
import { useUserProfileStore } from "../../stores/profileStore";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import { updateAccountData, updateFinancesData } from "../../services/account";
import {
	convertToFormattedAmount,
	convertToNumberAndMultiplyBy100,
} from "../../utils/convertAmount";
import {
	type ChangeEvent,
	type RefObject,
	type KeyboardEvent,
	useState,
	useRef,
	useEffect,
} from "react";

const ProfileOverviewView = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState(formatPhoneNumber(""));
	const [annualIncome, setAnnualIncome] = useState(
		formatPrice(convertToFormattedAmount(0)),
	);
	const [householdSavings, setHouseholdSavings] = useState(
		formatPrice(convertToFormattedAmount(0)),
	);
	const [areaOfWork, setAreaOfWork] = useState("");
	const [areaOfWorkError, setAreaOfWorkError] = useState("");
	const [image, setImage] = useState<string | ArrayBuffer | null>(null);
	const [firstNameError, setFirstNameError] = useState("");
	const [lastNameError, setLastNameError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [phoneNumberError, setPhoneNumberError] = useState("");
	const [annualIncomeError, setAnnualIncomeError] = useState("");
	const [householdSavingsError, setHouseholdSavingsError] = useState("");
	const [buttonText, setButtonText] = useState("Save changes");
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const annualIncomeRef = useRef<HTMLInputElement>(null);
	const householdSavingsRef = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();

	const handleAreaOfWorkChange = (e: ChangeEvent<HTMLInputElement>) => {
		setAreaOfWork(e.target.value);
	};

	const { userProfile, setUserProfile } = useUserProfileStore();

	useEffect(() => {
		setEmail(userProfile?.emailAddress || "");
		setFirstName(userProfile?.firstName || "");
		setLastName(userProfile?.lastName || "");
		setPhoneNumber(
			userProfile?.phoneNumber
				? formatPhoneNumber(userProfile.phoneNumber)
				: "",
		);
		setAnnualIncome(
			formatPrice(
				convertToFormattedAmount(
					userProfile?.finances?.income?.[0]?.amount.amount || 0,
				),
			),
		);
		setHouseholdSavings(
			formatPrice(
				convertToFormattedAmount(
					userProfile?.finances?.savings?.[0]?.amount.amount || 0,
				),
			),
		);
		setAreaOfWork(userProfile?.profession || "");
		setImage(userProfile?.profileImageUrl || null);
	}, [userProfile]);

	const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleRemoveImage = () => {
		setImage(null);
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	const validateInputs = () => {
		let isValid = true;

		if (firstName.trim()) {
			setFirstNameError("");
		} else {
			setFirstNameError("First name cannot be empty.");
			isValid = false;
		}

		if (lastName.trim()) {
			setLastNameError("");
		} else {
			setLastNameError("Last name cannot be empty.");
			isValid = false;
		}

		if (email.trim()) {
			setEmailError("");
		} else {
			setEmailError("Email cannot be empty.");
			isValid = false;
		}

		try {
			if (phoneNumber.trim()) {
				if (PhoneValidator.isValid(phoneNumber, "CA")) {
					setPhoneNumberError("");
				} else {
					setPhoneNumberError("Invalid phone number.");
					isValid = false;
				}
			} else {
				setPhoneNumberError("Phone number cannot be empty.");
				isValid = false;
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				setPhoneNumberError("Invalid phone number.");
			}
			isValid = false;
		}

		if (annualIncome.trim()) {
			setAnnualIncomeError("");
		} else {
			setAnnualIncomeError("Annual income cannot be empty.");
			isValid = false;
		}

		if (householdSavings.trim()) {
			setHouseholdSavingsError("");
		} else {
			setHouseholdSavingsError("Household savings cannot be empty.");
			isValid = false;
		}

		if (areaOfWork.trim()) {
			setAreaOfWorkError("");
		} else {
			setAreaOfWorkError("Area of work cannot be empty.");
			isValid = false;
		}

		return isValid;
	};

	const handleSend = async () => {
		if (!validateInputs()) {
			return;
		}

		try {
			const financesResponse = await updateFinancesData({
				totalIncome: {
					amount: convertToNumberAndMultiplyBy100(
						annualIncome.replace(/[^0-9]/g, "") || "0",
					),
					currency: "CAD",
				},
				totalSavings: {
					amount: convertToNumberAndMultiplyBy100(
						householdSavings.replace(/[^0-9]/g, "") || "0",
					),
					currency: "CAD",
				},
			});

			const response = await updateAccountData({
				phoneNumber: PhoneValidator.formatE164(phoneNumber, "CA"),
				firstName: capitalizeFirstLetter(firstName),
				lastName: capitalizeFirstLetter(lastName),
				profession: areaOfWork,
				profileImageUrl: image || undefined,
			});

			setUserProfile(response);
			setButtonText("Saved");
			setTimeout(() => {
				setButtonText("Save changes");
			}, 4000);
		} catch (error) {
			console.error("Failed to update account data", error);
		}
	};

	const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPhoneNumber(formatPhoneNumber(e.target.value));
	};

	const handleAnnualIncomeChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^\d]/g, "");
		setAnnualIncome(formatPrice(value || 0));
	};

	const handleHouseholdSavingsChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^\d]/g, "");
		setHouseholdSavings(formatPrice(value || 0));
	};

	const getDisplayName = (firstName: string, lastName: string) => {
		const maxNameLenght = 20;

		const fullName = `${capitalizeFirstLetter(
			firstName,
		)} ${capitalizeFirstLetter(lastName)}`;

		if (fullName.length > maxNameLenght) {
			return `${fullName.slice(0, maxNameLenght)}...`;
		}

		return fullName;
	};

	const handleKeyPress = (
		e: KeyboardEvent<HTMLDivElement>,
		ref: RefObject<HTMLInputElement>,
	) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			ref.current?.focus();
		}
	};

	return (
		<div className="flex flex-col items-center text-black space-y-8 pt-4 px-4 pb-6">
			<div className="space-y-2 text-center flex items-center flex-col">
				<ProfilePicture
					image={image}
					onImageUpload={handleImageUpload}
					onRemoveImage={handleRemoveImage}
					fileInputRef={fileInputRef}
				/>
				<div className="font-bold text-2xl">
					{getDisplayName(firstName, lastName)}
				</div>
			</div>
			<div className="space-y-6 w-full">
				<div className="font-bold">Personal Details</div>
				<div className=" space-y-4">
					<div className="flex flex-col items-start space-y-2">
						<div className="font-medium">Legal First Name</div>
						<input
							type="text"
							spellCheck="false"
							autoComplete="off"
							className="w-full rounded-[15px] px-2 py-4"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							style={{
								boxShadow:
									"0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
							}}
						/>
						{firstNameError && (
							<div className="text-red-500 text-sm">{firstNameError}</div>
						)}
					</div>
					<div className="flex flex-col items-start space-y-2">
						<div className="font-medium">Legal Last Name</div>
						<input
							type="text"
							spellCheck="false"
							autoComplete="off"
							className="w-full rounded-[15px] px-2 py-4"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							style={{
								boxShadow:
									"0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
							}}
						/>
						{lastNameError && (
							<div className="text-red-500 text-sm">{lastNameError}</div>
						)}
					</div>
					<div className="flex flex-col items-start space-y-2">
						<div className="font-medium">Email</div>
						<input
							type="email"
							spellCheck="false"
							autoComplete="off"
							disabled={true}
							className="w-full rounded-[15px] px-2 py-4 disabled:text-[#9B9B9B]"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							style={{
								boxShadow:
									"0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
							}}
						/>
						{emailError && (
							<div className="text-red-500 text-sm">{emailError}</div>
						)}
					</div>
					<div className="flex flex-col items-start space-y-2">
						<div className="font-medium">Phone Number</div>
						<input
							type="tel"
							spellCheck="false"
							autoComplete="off"
							className="w-full rounded-[15px] px-2 py-4"
							value={phoneNumber}
							onChange={handlePhoneNumberChange}
							style={{
								boxShadow:
									"0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
							}}
						/>
						{phoneNumberError && (
							<div className="text-red-500 text-sm">{phoneNumberError}</div>
						)}
					</div>
				</div>
				<div className="space-y-4">
					<div className="flex flex-col items-start space-y-2">
						<div className="font-medium">
							Annual household income{" "}
							<span className="text-xs text-[#828282]">(before tax)</span>
						</div>
						<div
							className="w-full rounded-[15px] bg-white px-2 py-4 flex gap-1 cursor-pointer"
							onClick={() => annualIncomeRef.current?.focus()}
							onKeyPress={(e) => handleKeyPress(e, annualIncomeRef)}
							style={{
								boxShadow:
									"0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
							}}
						>
							$
							<input
								ref={annualIncomeRef}
								spellCheck="false"
								type="tel"
								autoComplete="off"
								value={annualIncome}
								onChange={handleAnnualIncomeChange}
								onClick={(e) => e.stopPropagation()}
								className="flex-grow"
							/>
						</div>
						{annualIncomeError && (
							<div className="text-red-500 text-sm">{annualIncomeError}</div>
						)}
					</div>
					<div className="flex flex-col items-start space-y-2">
						<div className="font-medium">
							Total household savings{" "}
							<span className="text-xs text-[#828282]">
								(including RRSP & TFSA)
							</span>
						</div>
						<div
							className="w-full rounded-[15px] bg-white px-2 py-4 flex gap-1 cursor-pointer"
							onClick={() => householdSavingsRef.current?.focus()}
							onKeyPress={(e) => handleKeyPress(e, householdSavingsRef)}
							style={{
								boxShadow:
									"0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
							}}
						>
							$
							<input
								spellCheck="false"
								ref={householdSavingsRef}
								type="tel"
								autoComplete="off"
								value={householdSavings}
								onChange={handleHouseholdSavingsChange}
								onClick={(e) => e.stopPropagation()}
								className="flex-grow"
							/>
						</div>
						{householdSavingsError && (
							<div className="text-red-500 text-sm">
								{householdSavingsError}
							</div>
						)}
					</div>
					<div className="flex flex-col items-start space-y-2">
						<div className="font-medium">Area of Work</div>
						<input
							type="text"
							spellCheck="false"
							autoComplete="off"
							className="w-full rounded-[15px] px-2 py-4"
							value={areaOfWork}
							onChange={handleAreaOfWorkChange}
							style={{
								boxShadow:
									"0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
							}}
						/>
						{areaOfWorkError && (
							<div className="text-red-500 text-sm">{areaOfWorkError}</div>
						)}
					</div>
				</div>
			</div>

			<div className="w-full space-y-2 flex justify-center flex-col items-center">
				<div className="flex items-start flex-col text-start">
					<button
						type="button"
						className="w-full py-1.5 underline font-medium text-start text-[#FF6380]"
						onClick={() => {
							navigate("/profile/delete-account");
						}}
					>
						Delete account
					</button>
					<div className="text-sm">
						When you delete your account, all of your data and matches will be
						deleted.
					</div>
				</div>
				<button
					type="button"
					className="py-3 font-semibold rounded-full flex items-center px-4 gap-2 justify-center bg-z-dark-moss-green text-white"
					onClick={handleSend}
				>
					{buttonText}
				</button>
			</div>
		</div>
	);
};

export { ProfileOverviewView };

import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { verifyAuth } from "../../services/auth";
import { LoadingCircle } from "../common/loading";
import { useAuth } from "../../context/authProvider";
import { useAuthStore } from "../../stores/useAuthStore";
import { fetchAccountData } from "../../services/account";
import { useUserProfileStore } from "../../stores/profileStore";
import { useVerificationStore } from "../../stores/verificationStore";
import {
	type ChangeEvent,
	type KeyboardEvent,
	useRef,
	useState,
	useEffect,
	useCallback,
} from "react";

const LoginVerifyView = () => {
	const codeLength = 6;
	const { email } = useAuthStore();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const { setAuthenticated } = useAuth();
	const { setUserProfile } = useUserProfileStore();

	const { setVerificationFailed } = useVerificationStore();
	const [showError, setShowError] = useState<boolean>(false);
	const [localCode, setLocalCode] = useState<string[]>(
		Array(codeLength).fill(""),
	);
	const generateInputIds = () =>
		Array.from({ length: codeLength }, () => nanoid());
	const [inputIds] = useState<string[]>(generateInputIds);
	const onboardingButtonRef = useRef<HTMLButtonElement>(null);

	const handleCodeInput = (
		event: ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const value = event.target.value;
		if (/^\d?$/.test(value)) {
			const newCode = [...localCode];
			newCode[index] = value;
			setLocalCode(newCode);

			if (value && index < codeLength - 1) {
				const nextInput = document.getElementById(
					inputIds[index + 1],
				) as HTMLInputElement;
				nextInput?.focus();
			}
		}
	};

	const handleKeyDown = (
		event: KeyboardEvent<HTMLInputElement>,
		index: number,
	) => {
		if (event.key === "Backspace") {
			const newCode = [...localCode];
			if (index > 0 && !newCode[index]) {
				newCode[index - 1] = "";
				setLocalCode(newCode);
				const prevInput = document.getElementById(
					inputIds[index - 1],
				) as HTMLInputElement;
				prevInput?.focus();
			} else {
				newCode[index] = "";
				setLocalCode(newCode);
			}
		} else if (event.key === "ArrowLeft" && index > 0) {
			const prevInput = document.getElementById(
				inputIds[index - 1],
			) as HTMLInputElement;
			prevInput?.focus();
		} else if (event.key === "ArrowRight" && index < codeLength - 1) {
			const nextInput = document.getElementById(
				inputIds[index + 1],
			) as HTMLInputElement;
			nextInput?.focus();
		}
	};

	const inputs = inputIds.map((id, index) => (
		<input
			key={id}
			type="tel"
			id={id}
			maxLength={1}
			className="border-[#979797]  border-2 rounded-lg py-4 px-2 font-medium w-12 text-center"
			value={localCode[index]}
			onChange={(e) => handleCodeInput(e, index)}
			onKeyDown={(e) => handleKeyDown(e, index)}
		/>
	));

	const validateCodeInput = async () => {
		const inputCode = localCode.join("");

		try {
			setLoading(true);
			const response = await verifyAuth(email, inputCode);

			if (response.status !== 200) {
				setShowError(true);
				setVerificationFailed(true);
				navigate("/login/email-view");
				throw new Error("Verification failed");
			}

			const accountData = await fetchAccountData();
			setUserProfile(accountData);

			const { firstName, lastName, phoneNumber } = accountData;
			console.log("accountData:", accountData);
			const missingEssentialDetails = !(firstName && lastName) || !phoneNumber;
			console.log("missingEssentialDetails:", missingEssentialDetails);

			const missingFinances = !(accountData.finances && accountData.finances);

			if (missingEssentialDetails) {
				setAuthenticated(true);
				navigate("/ownership");
				return true;
			}

			if (missingFinances) {
				setAuthenticated(true);
				navigate("/buyer-input-flow/annual-income");
				return true;
			}

			setAuthenticated(true);
			navigate("/");
			return true;
		} catch (error) {
			console.error("Verification failed:", error);
			setShowError(true);
			setVerificationFailed(true);
			navigate("/login/email-view");
			return true;
		} finally {
			setLoading(false);
		}
	};

	const handleKeyDownOnPage = useCallback((e: WindowEventMap["keydown"]) => {
		if (e.key === "Enter") {
			if (onboardingButtonRef.current) {
				onboardingButtonRef.current.click();
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDownOnPage);
		return () => {
			window.removeEventListener("keydown", handleKeyDownOnPage);
		};
	}, [handleKeyDownOnPage]);

	return (
		<div className="flex flex-col items-center justify-center h-full space-y-4">
			<h2 className="font-bold text-2xl text-center">Verify Account</h2>
			<div className="justify-center text-center text-[15px]">
				Enter the one-time code we sent to
				<br />
				<span className="text-black font-bold">{email}</span>
			</div>
			{loading && (
				<div className="flex justify-center items-center">
					<LoadingCircle />
				</div>
			)}
			{!loading && (
				<>
					<div className="flex space-x-2 justify-center">{inputs}</div>

					{showError && (
						<div className="text-red-500">
							Incorrect code. Please try again.
						</div>
					)}
					<div className="space-y-8 w-full flex flex-col items-center">
						<button
							type="button"
							className="underline h-10 w-full max-w-[200px] text-sm text-black"
							onClick={() => navigate("/login/email-view")}
						>
							Didn't get a code?
						</button>

						<button
							ref={onboardingButtonRef}
							type="button"
							className="bg-z-dark-moss-green text-white disabled:bg-[#E0E0E0] disabled:text-[#939393] w-full py-4 rounded-full font-bold shadow-md"
							onClick={validateCodeInput}
						>
							Continue
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export { LoginVerifyView };

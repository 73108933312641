import { create } from "zustand";

interface NavbarState {
  isNavbarVisible: boolean;
  toggleNavbar: () => void;
  showNavbar: () => void;
  hideNavbar: () => void;
}

const useNavbarStore = create<NavbarState>((set) => ({
  isNavbarVisible: true,
  toggleNavbar: () =>
    set((state) => ({ isNavbarVisible: !state.isNavbarVisible })),
  showNavbar: () => set(() => ({ isNavbarVisible: true })),
  hideNavbar: () => set(() => ({ isNavbarVisible: false })),
}));

export default useNavbarStore;

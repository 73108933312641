import { BungalowHouse } from "../../../assets/FinancesImg";
import { useFinanceStore } from "../../../stores/financeStore";
import { Button } from "../../common";
import { BiChevronLeft } from "react-icons/bi";
import { useModalStore } from "../../../stores/handleModalStore";
import { EditIconStroke } from "../../../assets/icons";

const EditHouseGoalOverPage = () => {
  const { buyingHomeData, planSpendData, whenBuyHomeData } = useFinanceStore();
  const {
    setWhenBuyHomeState,
    setEditHouseGoalOverPageState,
    editHouseGoalOverPageState,
    setBuyingHomeState,
    setPlanSpendHomeState,
  } = useModalStore();
  return (
    <>
      {editHouseGoalOverPageState && (
        <div
          className={`fixed inset-0 bg-white p-6 z-30 pb-24 ${
            window.bridge ? "pt-bridge-inset-top pb-bridge-inset-bottom" : ""
          } text-z-shark w-full`}
        >
          <div
      	className={`absolute  ${
			window.bridge ? " top-bridge-inset-top" : "top-5"
		  } left-5`}
            onClick={() => setEditHouseGoalOverPageState(false)}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                setEditHouseGoalOverPageState(false);
              }
            }}
          >
            <BiChevronLeft className="text-3xl" />
          </div>

          <div className="flex flex-col space-y-6 mt-20">
            <img src={BungalowHouse} alt="bungalow house" width={120} />
            <div className="text-[26px] font-bold">Your home buying plan</div>
            <div className="">
              <div className="divide-y-[1px] mt-6">
                <div className="flex items-center justify-between">
                  <div className="font-medium">Location</div>
                  <div
                    className="text-z-dark-moss-green font-medium w-[calc(100%__-85px)] flex justify-end"
                    onClick={() => setBuyingHomeState(true)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        setBuyingHomeState(true);
                      }
                    }}
                  >
                    <div className="text-ellipsis overflow-hidden whitespace-nowrap">
                    {buyingHomeData.length <= 0 ? "Set goal" : buyingHomeData.map((location) => location.replace(", ON", "")).join(", ")}
                    </div>
                    <img className="ml-2" width={20} src={EditIconStroke} alt="edit icon" />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-5 pt-5">
                  <div className="font-medium">House price</div>
                  <div
                    className="text-z-dark-moss-green font-medium flex items-center"
                    onClick={() => setPlanSpendHomeState(true)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        setPlanSpendHomeState(true);
                      }
                    }}
                  >
                    {planSpendData === "Set goal"
                      ? planSpendData
                      : `$${planSpendData}`}
                      <img className="ml-2" width={20} src={EditIconStroke} alt="edit icon" />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-5 pt-5">
                  <div className="font-medium">Target Date</div>
                  <div
                    className="text-z-dark-moss-green font-medium flex items-center"
                    onClick={() => setWhenBuyHomeState(true)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        setWhenBuyHomeState(true);
                      }
                    }}
                  >
                    {whenBuyHomeData}
                    <img className="ml-2" width={20} src={EditIconStroke} alt="edit icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Button
            customClassName={`text-lg font-semibold absolute left-1/2 -translate-x-1/2 ${
              window.bridge ? "bottom-bridge-inset-bottom" : "bottom-5"
            }`}
            onClick={() => setEditHouseGoalOverPageState(false)}
            radius="60px"
            width="330px"
            height="lg"
          >
            Save changes
          </Button>
        </div>
      )}
    </>
  );
};

export { EditHouseGoalOverPage };

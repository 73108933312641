import type { TimePreference } from "../interfaces/timePreference";

export const generateInitialTimePreferences = (
	dates: string[],
): TimePreference[] => {
	return dates.map((date) => ({
		id: `${date}-${Math.random().toString(36).substring(2, 11)}`,
		date,
		startAt: "",
		endAt: "",
		error: "",
	}));
};

import clsx from "clsx";
import {
	ChevronDownIcon,
	FinancesDebtIcon,
	PlusIcon,
	SuggestionsIcon,
} from "../../assets/icons";
import { useEffect, useRef, useState } from "react";
import {
	DocumentCheckDebtIcon,
	FinancesEditIcon,
} from "../../assets/FinancesImg";
import { setupHammer } from "./setupHammer";
import { FiTrash2 } from "react-icons/fi";
import { useModalStore } from "../../stores/handleModalStore";
import { useFinanceStore } from "../../stores/financeStore";
import { finance } from "../../services/finance";
import type { Finance } from "../../interfaces/finance";

type FormValues = {
	carLoans: string;
	studentLoans: string;
	creditCards: string;
	lineOfCredit: string;
};

const DebtSection = () => {
	const [debtIsOpen, setDebtIsOpen] = useState(false);
	const { setAnotherDebtOverPageState } = useModalStore();
	const { anotherDebtFormValues, setAnotherDebtFormValues } = useFinanceStore();

	const [activeSwipe, setActiveSwipe] = useState<string | null>(null);

	const handleDelete = async (name: string) => {
		setAnotherDebtFormValues(name as keyof FormValues, "0");
		setActiveSwipe(null);
		const updatedDebt = Object.entries(anotherDebtFormValues)
			.filter(([debtName, value]) => debtName !== name && value !== "0")
			.map(([debtName, value]) => ({
				type: debtName,
				amount: value,
			}));
		const payload: Finance = {
			financeDebt: updatedDebt,
		};
		console.log(payload);
		await finance(payload);
	};

	const handleSwipeLeft = (el: HTMLDivElement) => {
		const fullName = el.querySelector(".font-bold")?.textContent;

		if (fullName) {
			setActiveSwipe(fullName);
		}
	};

	const handleSwipeRight = () => {
		setActiveSwipe(null);
	};

	const debtRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (debtIsOpen) {
			debtRef?.current?.scrollIntoView({ behavior: "smooth" });
		} else {
			debtRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [debtIsOpen]);

	function formatString(text: string) {
		let words = text.match(/[A-Z][a-z]+|^[a-z]+/g);
		if (!words) return;
		let formattedString = words
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
		return formattedString;
	}

	return (
		<div className="shadow-[0_0_6px_0_rgba(0,0,0,0.15)] rounded-[20px] w-full py-5 px-6 mt-10">
			<div className="flex flex-col">
				<div className="flex items-center space-x-1">
					<img src={FinancesDebtIcon} alt="saving icon" />
					<div className="text-[#FF7A00] text-xl font-semibold">Debt</div>
				</div>
				<div
					className={clsx(
						"bg-[#FFEDDD] rounded-2xl flex overflow-hidden flex-col px-4 py-5 relative transition-height duration-300 ease-in-out mt-3",
						debtIsOpen ? "min-h-[420px] h-auto" : "h-[180px]",
					)}
					ref={debtRef}
				>
					<div
						className={clsx(
							"absolute top-[160px] transition-all duration-300 left-1/2 -translate-x-1/2",
							debtIsOpen && "rotate-180",
						)}
						onClick={() => setDebtIsOpen((prev) => !prev)}
						onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								setDebtIsOpen((prev) => !prev);
							}
						}}
					>
						<ChevronDownIcon fill="#FF7A00" />
					</div>
					<div className="flex relative">
						<span className="text-z-shark text-3xl font-bold">$1,000</span>
						<div className="absolute bottom-0 left-[85px] flex items-center">
							<div className="px-2 text-[#828282]">/</div>
							<span className="font-medium text-[#828282]">month</span>
						</div>
						<div
							className="absolute bottom-1 left-[160px]"
							onClick={() => setAnotherDebtOverPageState(true)}
							onKeyUp={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									setAnotherDebtOverPageState(true);
								}
							}}
						>
							<img src={FinancesEditIcon} alt="finances edit icon" />
						</div>
					</div>
					<div
						className="flex items-center bg-white px-4 py-5 rounded-2xl mt-4"
						onClick={() => setDebtIsOpen((prev) => !prev)}
						onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								setDebtIsOpen((prev) => !prev);
							}
						}}
					>
						<img src={DocumentCheckDebtIcon} alt="document check icon" />
						<div className="text-[10px] font-medium ml-4">
							You’re doing 3 things really well - tackling
							<br /> the other 2 could boost your savings
						</div>
					</div>
					<div className="mt-12 w-full">
						<div className="flex items-center justify-between">
							<div className="text-[#FF7A00] font-bold">
								Current debt breakdown
							</div>
						</div>
					</div>

					<div className="flex items-center flex-col">
						{Object.entries(anotherDebtFormValues)
							.filter(([_, price]) => price !== "0")
							.map(([name, price]) => (
								<div
									key={name}
									className={clsx(
										"flex items-center justify-between text-z-shark w-full mt-6 relative transition-transform duration-300 h-[44px]",
										activeSwipe === price
											? "transform -translate-x-[60px]"
											: "",
									)}
									ref={(el) => {
										if (el) {
											setupHammer(
												el,
												() => {},
												handleSwipeLeft,
												handleSwipeRight,
											);
										}
									}}
								>
									<div className="flex flex-col space-y-1">
										<div className="font-bold text-sm">
											{formatString(name)}
										</div>
									</div>
									<div className="flex flex-col items-end space-y-1">
										<div className="font-bold">${price}/m</div>
									</div>
									<div
										className="delete-icon w-[60px] h-[50px] flex items-center justify-center bg-red-500 text-white absolute -right-[80px] top-0"
										onClick={() => {
											handleDelete(name);
										}}
										onKeyUp={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												handleDelete(name);
											}
										}}
									>
										<FiTrash2 className="text-2xl" />
									</div>
								</div>
							))}
						<div
							className="w-full border-t-2 border-[#FFDBB9] flex items-center mt-7 pt-5 space-x-3"
							onClick={() => setAnotherDebtOverPageState(true)}
							onKeyUp={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									setAnotherDebtOverPageState(true);
								}
							}}
						>
							<PlusIcon strokeWidth="2.5px" fill="#FF7A00" />
							<div className="text-[#FF7A00] font-semibold text-sm">
								Add another debt
							</div>
						</div>
					</div>

					<div className="mt-9">
						<div className="text-[#FF7A00] font-bold">Suggestions</div>
						<div className="space-y-3 mt-3">
							<div className="bg-white rounded-2xl py-4 px-3 flex items-center space-x-3">
								<SuggestionsIcon fill="#FFDBB9" stroke="#FF7A00" />
								<div className="text-xs font-medium">
									Lorem ipsum dolor sit amet, consectetur adipiscing
								</div>
							</div>
							<div className="bg-white rounded-2xl py-4 px-3 flex items-center space-x-3">
								<SuggestionsIcon fill="#FFDBB9" stroke="#FF7A00" />
								<div className="text-xs font-medium">
									Lorem ipsum dolor sit amet, consectetur adipiscing
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { DebtSection };

import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { openLink } from "../../services/openLink";
import { getPreApproved } from "../../services/account";
import MatchIcon from "../../assets/Profile/GetPreApproved/matchIcon.svg";
import CreditCheckIcon from "../../assets/Profile/GetPreApproved/creditCheckIcon.svg";
import UploadDocumnetsIcon from "../../assets/Profile/GetPreApproved/uploadDocumentsIcon.svg";
import ShareIncomeSavingIcon from "../../assets/Profile/GetPreApproved/shareIncomeSavingIcon.svg";
import ReceivePreApprovalIcon from "../../assets/Profile/GetPreApproved/receivePreApprovalIcon.svg";

const GetPreApprovedOverviewView = () => {
	const navigate = useNavigate();
	const steps = [
		{
			id: nanoid(),
			imgSrc: ShareIncomeSavingIcon,
			alt: "Income and Savings",
			text: "Share your income, savings, and debt to calculate what you can afford",
		},
		{
			id: nanoid(),
			imgSrc: MatchIcon,
			alt: "Preferred Lenders",
			text: "We'll match you with our preferred lenders for the best rates.",
		},
		{
			id: nanoid(),
			imgSrc: UploadDocumnetsIcon,
			alt: "Upload Documents",
			text: "Securely upload your documents to finalize your application.",
		},
		{
			id: nanoid(),
			imgSrc: ReceivePreApprovalIcon,
			alt: "Pre-Approval",
			text: "Receive your pre-approval within 24-48 hours and start house hunting.",
		},
		{
			id: nanoid(),
			imgSrc: CreditCheckIcon,
			alt: "Credit Check",
			text: "There will be a credit check to confirm eligibility.",
		},
	];

	return (
		<div className="flex flex-col items-center text-black space-y-6 px-2 pb-6 py-2">
			<header className="w-full flex flex-col items-center space-y-2">
				<h1 className="font-bold text-xl">Get Pre-Approved</h1>
				<p className="text-[#828282] font-medium text-[15px]">
					Application processed within 24-48 hours
				</p>
			</header>

			<section className="rounded-[15px] bg-[#F5F5F5] w-full flex items-center flex-col p-4 space-y-4">
				{steps.map((step) => (
					<div
						key={step.id}
						className="bg-white rounded-[15px] max-w-[312px] px-2 py-4 flex items-center gap-2"
					>
						<img src={step.imgSrc} alt={step.alt} />
						<p className="text-sm font-medium">{step.text}</p>
					</div>
				))}
			</section>

			<section className="text-center w-full space-y-6">
				<div className="space-y-4">
					<button
						type="button"
						className="w-full bg-z-dark-moss-green rounded-full py-3.5 text-white"
						onClick={async () => {
							await getPreApproved();
							navigate("/profile/get-preapproved/check-inbox");
						}}
					>
						Start Application
					</button>
					<button
						type="button"
						className="text-[15px] font-bold text-z-dark-moss-green"
						onClick={() => openLink("tel:+18444389696")}
					>
						<span className="font-light">Have questions?</span> Give us a call{" "}
						<span className="underline">1 (844) 438-9696</span>
					</button>
				</div>
				<p className="text-[15px] font-light">
					Already have a pre-approval?{" "}
					<button
						type="button"
						className="font-bold"
						onClick={() => navigate("/profile/upload-preapproved")}
					>
						Upload it here
					</button>
				</p>
			</section>
		</div>
	);
};

export { GetPreApprovedOverviewView };

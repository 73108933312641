import { useRef } from "react";
import {
  useStep,
  useHomeFeaturesStore,
} from "../../../stores/buyerHomeFeaturesStore";

interface City {
  id: number;
  name: string;
  type?: string;
  icon?: string;
  latitude?: number;
  longitude?: number;
}

const usePointsInterest = () => {
  const { setCurrentStep } = useStep();

  const {
    selectedAddresses,
    setSelectedAddresses,
    selectedStation,
    setSelectedStation,
    selectedSchool,
    setSelectedSchool,
    selectedWorkAddress,
    setSelectedWorkAddress,
    setPointsInterestError,
    pointsInterestError,
    setRemainingPopularPoints,
    remainingPopularPoints,
    selectedPoints,
    setSelectedPoints,
    placeWorship,
    setPlaceWorship,
  } = useHomeFeaturesStore();

  const processingPOIRef = useRef(new Set());

  const addPointOfInterest = (poi) => {
    const uniqueKey = `${poi.name}-${poi.type}`;
  
    if (processingPOIRef.current.has(uniqueKey)) {
      console.log(`Skipping addition during this render for POI: ${uniqueKey}`);
      return;
    }
  
    const alreadyExists = selectedPoints.some(
      (point) => point.name === poi.name && point.type === poi.type
    );
  
    if (alreadyExists) {
      console.log(`Skipping duplicate addition for POI: ${poi.name}, type: ${poi.type}`);
      return;
    }
  
    console.log("Before adding:", selectedPoints);
    setSelectedPoints((prev) => {
      const updatedPoints = [...prev, poi];
      console.log("After adding:", updatedPoints);
      return updatedPoints;
    });
  
    // Remove this POI from remainingPopularPoints
    setRemainingPopularPoints((prev) =>
      prev.filter((point) => !(point.name === poi.name && point.type === poi.type))
    );
  
    processingPOIRef.current.add(uniqueKey);
  };
  



  const removePointOfInterest = (point: City) => {
    setRemainingPopularPoints((prev) => [...prev, point]);
    setSelectedPoints((prev) => prev.filter((item) => item.id !== point.id));
    if (point.id === 1) {
      setSelectedStation("", 0, 0);
    }
    if (point.id === 2) {
      setSelectedWorkAddress("", 0, 0);
    }
    if (point.id === 3) {
      setSelectedSchool("", 0, 0);
    }
    if (point.id === 4) {
      setPlaceWorship("");
    }
  };

  const removeSelectedAddress = (id: number) => {
    setSelectedAddresses((prev) => prev.filter((address) => address.id !== id));
  };

  return {
    removeSelectedAddress,
    addPointOfInterest,
    removePointOfInterest,
    setCurrentStep,
    remainingPopularPoints,
    setRemainingPopularPoints,
    selectedAddresses,
    setSelectedAddresses,
    selectedPoints,
    setSelectedPoints,
    pointsInterestError,
    setPointsInterestError,
    selectedStation,
    setSelectedStation,
    selectedSchool,
    setSelectedSchool,
    selectedWorkAddress,
    setSelectedWorkAddress,
    placeWorship,
    setPlaceWorship,
  };
};

export { usePointsInterest };

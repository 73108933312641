import { clsx } from "clsx";
import xButtonIcon from "../../../assets/xButtonIcon.svg";
import { useSearchFiltersStore } from "../../../stores/filterStore";
import GrayArrowDownIcon from "../../../assets/grayArrowDownIcon.svg";
import {
	useCallback,
	useEffect,
	useRef,
	useState,
	type KeyboardEvent,
	type ReactNode,
} from "react";

import "./filterPanelStyles.css";

interface DropdownSelectorProps {
	title: string;
	openType: string;
	openDropdownType: string | null;
	dropdownValue: string;
	handleAdvancedFilter?: (option: string) => void;
	toggleDropdown: (
		openType: string,
		e?: KeyboardEvent<HTMLButtonElement>,
	) => void;
	advancedFilterTitle?: ReactNode;
	advancedFilterHandler?: () => void;
}

const daysOnMarketLabels = [
	{ value: "Any" },
	{ value: "0-7 Days" },
	{ value: "8-14 Days" },
	{ value: "15-30 Days" },
	{ value: "30+ Days" },
];
const ageOfPropertyLabels = [
	"Any",
	"New (0 - 9 Years)",
	"Average (10-40 Years)",
	"Old (40+ Years)",
];
const styleLabels = [
	"Any",
	"1 1/2 Storey",
	"2-Storey",
	"2 1/2 Storey",
	"3 Storey",
	"Apartment",
	"Backsplit 3",
	"Backsplit 4",
	"Backsplit 5",
	"Bunaglow",
	"Bungaloft",
	"Chalet",
	"Contemporary",
	"Garden House",
	"Log",
	"Loft",
	"Other",
	"Sidesplit 3",
	"Sidesplit 4",
	"Sidesplit 5",
];

const sqFtLabels = ["Any", "<500", "500-1100", "1100-1500", "1500+"];
const basementLabels = ["Any", "Apartment", "Unfinished", "Finished"];
const garageTypeLabels = ["Any", "Attached", "Detached"];
const numberOfKitchenLabels = ["Any", "1", "2", "3+"];

const advancedFilterLabels = [
	"Deposit Required",
	"Contract Required",
	"Other Required",
];

const DropdownSelector = ({
	title,
	openType,
	openDropdownType,
	dropdownValue,
	toggleDropdown,
	handleAdvancedFilter,
	advancedFilterTitle,
	advancedFilterHandler,
}: DropdownSelectorProps) => {
	const dropdownRef = useRef<HTMLUListElement | null>(null);
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [renderUpward, setRenderUpward] = useState(false);

	const {
		setDaysOnMarket,
		setAgeOfProperty,
		setStyle,
		setSqFt,
		setBasement,
		setGarageType,
		setNumberOfKitchens,
	} = useSearchFiltersStore((state) => state);

	const handleToggleDropdown = (
		openType: string,
		event?: KeyboardEvent<HTMLButtonElement>,
	) => {
		toggleDropdown(openType, event);
		if (renderUpward) {
			setRenderUpward(false);
		}
	};

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (
				dropdownRef.current &&
				buttonRef.current &&
				!dropdownRef.current.contains(event.target as Node) &&
				!buttonRef.current.contains(event.target as Node)
			) {
				toggleDropdown(openType);
			}
		},
		[toggleDropdown, openType],
	);

	const labelsArray = (openType: string) => {
		switch (openType) {
			case "daysOnMarket":
				return daysOnMarketLabels;
			case "ageOfProperty":
				return ageOfPropertyLabels;
			case "style":
				return styleLabels;
			case "sqFt":
				return sqFtLabels;
			case "basement":
				return basementLabels;
			case "garage":
				return garageTypeLabels;
			case "kitchen":
				return numberOfKitchenLabels;
			case "selectFilters":
				return advancedFilterLabels;
			default:
				return [];
		}
	};

	const handleOptionSelect = (
		type: string,
		option: string,
		event?: KeyboardEvent<HTMLButtonElement | HTMLLIElement>,
	) => {
		if (event) {
			if (event.key !== "Enter" && event.key !== " ") {
				return;
			}
		}

		switch (type) {
			case "daysOnMarket":
				setDaysOnMarket(option);
				break;
			case "ageOfProperty":
				setAgeOfProperty(option);
				break;
			case "style":
				setStyle(option);
				break;
			case "sqFt":
				setSqFt(option);
				break;
			case "basement":
				setBasement(option);
				break;
			case "garage":
				setGarageType(option);
				break;
			case "kitchen":
				setNumberOfKitchens(option);
				break;
			case "selectFilters":
				handleAdvancedFilter?.(option);
				break;
			default:
				console.warn(`Unhandled type: ${type}`);
				break;
		}

		toggleDropdown(type);
		setRenderUpward(false);
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	useEffect(() => {
		if (openDropdownType === openType && dropdownRef.current) {
			const { bottom } = dropdownRef.current.getBoundingClientRect();
			const viewportHeight = window.innerHeight;

			if (bottom > viewportHeight) {
				setRenderUpward(true);
			} else {
				setRenderUpward(false);
			}
		}
	}, [openDropdownType, openType]);

	return (
		<div className="flex flex-col">
			<div className="relative my-1">
				{advancedFilterTitle && advancedFilterHandler && (
					<div className="w-full px-4 my-2 relative">
						<span className={"text-black"}>{advancedFilterTitle}</span>
						<button
							type="button"
							className="back-button  right-1"
							onClick={advancedFilterHandler}
						>
							<img src={xButtonIcon} alt={"remove-filter"} />
						</button>
					</div>
				)}
				<button
					ref={buttonRef}
					type="button"
					className="dropdown"
					onClick={() => handleToggleDropdown(openType)}
					onKeyDown={(e) => handleToggleDropdown(openType, e)}
				>
					<div className="flex flex-col">
						<div className="text-sm text-[#5D5E5A]">{title}</div>

						{dropdownValue ? dropdownValue : "Select"}
					</div>

					<img
						src={GrayArrowDownIcon}
						alt=""
						className={`${
							openDropdownType === openType
								? "transition-transform duration-300 rotate-180"
								: ""
						}`}
					/>
				</button>

				{openDropdownType === openType && (
					<ul
						ref={dropdownRef}
						className={`absolute w-full border shadow-lg drop-shadow-lg border-[#E1E0E3] overflow-auto max-h-[200px] rounded-[12px] bg-white z-50 ${
							renderUpward ? "bottom-full mb-1" : "top-full mt-1"
						}`}
					>
						{labelsArray(openType).map((option, idx, array) => {
							const label = typeof option === "string" ? option : option.value;

							return (
								<li
									key={label}
									className={clsx(
										"text-black px-4 py-2  border-b border-b-1 hover:bg-gray-200 cursor-pointer",
										{
											"bg-[#E1E0E3]": label === dropdownValue,
											"rounded-t-[12px] border-[0px]":
												label === dropdownValue && idx === 0,
											"rounded-b-[12px] border-b-[0px]":
												label === dropdownValue &&
												idx === labelsArray(openType).length - 1,
										},
									)}
									onClick={() => handleOptionSelect(openType, label)}
									onKeyDown={(e) => handleOptionSelect(openType, label, e)}
								>
									{label}
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	);
};

export { DropdownSelector };

import { addressLookup } from "../../../services/addressLookup";
import {
	useState,
	type ChangeEvent,
	useRef,
	useEffect,
	type KeyboardEvent,
} from "react";
import {
	type AddressSuggestion,
	useAddressLookupStore,
} from "../../../stores/addressLookupStore";

interface AddressLookUpInputProps {
	bussinessType?: string;
	error?: string;
}

export const AddressLookupInput = ({ error }: AddressLookUpInputProps) => {
	const {
		setSelectedAddress,
		setAddressLookupModalOpen,
		setSuggestion,
		selectedAddress,
	} = useAddressLookupStore();

	const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([]);
	const dropdownRef = useRef<HTMLDivElement | null>(null);

	const filterSuggestions = (
		items: AddressSuggestion[],
	): AddressSuggestion[] => {
		const bestItems = new Map<string, AddressSuggestion>();

		for (const item of items) {
			const suggestion = item;

			const existingItem = bestItems.get(suggestion.Text);

			if (existingItem) {
				if (
					existingItem.Type !== "BuildingNumber" ||
					suggestion.Type === "BuildingNumber"
				) {
					bestItems.set(suggestion.Text, suggestion);
				}
			} else {
				bestItems.set(suggestion.Text, suggestion);
			}
		}

		return Array.from(bestItems.values());
	};

	const fetchSuggestions = async (input: string) => {
		try {
			const response: AddressSuggestion[] = await addressLookup(input);
			console.log("fetchSuggestions response:", response);
			const filteredSuggestions = filterSuggestions(response);
			setSuggestions(filteredSuggestions);
		} catch (error) {
			console.error("Error fetching suggestions:", error);
			setSuggestions([]);
		}
	};

	const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const input = e.target.value;
		setSelectedAddress(input);

		if (input.length > 2) {
			await fetchSuggestions(input);
		} else {
			setSuggestions([]);
		}
	};

	const formatSuggestionText = (suggestion: AddressSuggestion): string => {
		let suggestionText = suggestion.Text;
		if (suggestion.Type === "Address" || suggestion.Type === "BuildingNumber") {
			const descriptionParts = suggestion.Description?.split(", ") || [];
			const shortDescription = descriptionParts.slice(0, 2).join(", ");
			suggestionText = `${suggestionText}, ${shortDescription}`;
			if (suggestion.UnitNumber) {
				suggestionText += `, Unit ${suggestion.UnitNumber}`;
			}
		}
		return suggestionText;
	};

	const handleSuggestionClick = (suggestion: AddressSuggestion) => {
		setSelectedAddress(suggestion.Text);
		if (suggestion.Type === "BuildingNumber") {
			setSelectedAddress(suggestion.Text);
			setAddressLookupModalOpen(true);
		} else if (suggestion.Type === "Address") {
			setSelectedAddress(suggestion.Text);
			setSuggestion(suggestion);
		}
		setSuggestions([]);
	};

	const handleKeyDown = (
		e: KeyboardEvent<HTMLLIElement>,
		suggestion: AddressSuggestion,
	) => {
		if (e.key === "Enter") {
			handleSuggestionClick(suggestion);
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setSuggestions([]);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div
			ref={dropdownRef}
			className="flex flex-col space-y-2 items-center w-full relative"
		>
			<div className="flex flex-col items-center w-full gap-4 relative">
				<input
					type="text"
					value={selectedAddress}
					onChange={handleInputChange}
					className={`w-full rounded-[4.28px] px-2 py-3 bg-[#F9F9F9] ${
						error ? "border-red-500" : ""
					} rounded-md`}
				/>

				{suggestions.length > 0 && (
					<div className="absolute top-full left-0 border-2 border-[#F1F1F1] right-0 z-10 mt-2 max-h-[150px] overflow-y-auto bg-white shadow-lg rounded-md">
						<ul>
							{suggestions.map((suggestion, i) => (
								<li
									key={suggestion.Id}
									onClick={() => handleSuggestionClick(suggestion)}
									onKeyDown={(e) => handleKeyDown(e, suggestion)}
									className={`cursor-pointer ${
										suggestions.length === i + 1
											? ""
											: "border-b-2 border-b-[#F1F1F1]"
									}  px-4 py-2`}
								>
									{formatSuggestionText(suggestion)}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>

			{error && (
				<span className="text-red-500 text-sm text-left w-full">{error}</span>
			)}
		</div>
	);
};

import { Input } from "../common";
import { type ChangeEvent, useRef } from "react";
import { formatPrice } from "../../utils/formatPrice";
import { handleKeyPress } from "../../utils/keyboardUtils";
import DollarIcon from "../../assets/icons/dollarIcon.svg";
import { useInputFlowStore } from "../../stores/inputFlowStore";

const SavingInputView = () => {
	const { savings, setSavings } = useInputFlowStore();

	const inputRef = useRef<HTMLInputElement>(null);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^\d]/g, "");
		setSavings(value);
	};

	return (
		<div
			className="relative space-y-10 px-4"
			onKeyDown={(e) => handleKeyPress(e, inputRef)}
		>
			<h3 className="text-z-dark-moss-green text-2xl font-bold">
				How much have you saved so far? (including RRSP & TFSA).
			</h3>
			<Input
				inputType="tel"
				ref={inputRef}
				value={formatPrice(savings)}
				onChange={handleChange}
				startIcon={DollarIcon}
				radius="1.25rem"
				label="Total Household Savings"
			/>
			<div className="absolute -bottom-6 left-4 right-0 text-[#9E9E9E] text-xs">
				*30% equals up to 1.5% on a $500,000 home
			</div>
		</div>
	);
};

export { SavingInputView };

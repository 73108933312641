import { useEffect } from "react";
import { useAuth } from "../context/authProvider.tsx";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AuthLayout = ({ type }: { type: "public" | "private" }) => {
	const { authenticated } = useAuth();
	const location = useLocation();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		console.log("Current route:", location.pathname);
	}, [location]);

	if (type === "public") {
		return authenticated ? <Navigate to="/" /> : <Outlet />;
	}

	if (type === "private") {
		return authenticated ? <Outlet /> : <Navigate to="/login" />;
	}

	return null;
};

export { AuthLayout };

import { useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useState, type ChangeEvent } from "react";
import ClockIcon from "../../assets/clockIcon.svg";
import { useOutingStore } from "../../stores/outingStore";
import { updateAccountData } from "../../services/account";
import { PhoneValidator } from "../../utils/phoneValidator";
import { CreateBuyerOutingInput } from "../../services/outings";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { useShowingCartStore } from "../../stores/showingCartStore";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import { AddressLookupInput } from "../common/AddressLookup/addressLookupInput";
import { useUserProfileStore } from "../../stores/profileStore";
import {
	addressLookupSearchQuery,
	getAddressType,
} from "../../services/addressLookup";
import {
	useAddressLookupStore,
	type AddressSuggestion,
} from "../../stores/addressLookupStore";

interface OutingResponse {
	outingId: string;
	pendingContracts: { url: string; id: string }[];
}

const DetailsConfirmationView = () => {
	const { setOutingResponse } = useOutingStore();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState(formatPhoneNumber(""));
	const [firstNameError, setFirstNameError] = useState("");
	const [lastNameError, setLastNameError] = useState("");
	const [phoneNumberError, setPhoneNumberError] = useState("");
	const [addressError, setAddressError] = useState("");
	const [disabledButton, setDisabledButton] = useState(false);
	const [agreementChecked, setAgreementChecked] = useState(false);
	const [agreementError, setAgreementError] = useState("");
	const { availableSlots, zlids, setAvailableSlots, setZlids } =
		useShowingCartStore();
	const navigate = useNavigate();
	const { userProfile, setUserProfile } = useUserProfileStore();
	const { suggestion, selectedAddress, setSelectedAddress, setSuggestion } =
		useAddressLookupStore();

	useEffect(() => {
		setFirstName(userProfile?.firstName ?? "");
		setLastName(userProfile?.lastName ?? "");
		setPhoneNumber(formatPhoneNumber(userProfile?.phoneNumber ?? ""));
		setSelectedAddress(userProfile?.address?.address1 ?? "");
	}, [userProfile]);

	const validateInputs = async () => {
		let isValid = true;

		if (firstName.trim()) {
			setFirstNameError("");
		} else {
			setFirstNameError("First name cannot be empty.");
			isValid = false;
		}

		if (lastName.trim()) {
			setLastNameError("");
		} else {
			setLastNameError("Last name cannot be empty.");
			isValid = false;
		}
		const response = await addressLookupSearchQuery(selectedAddress);
		setSuggestion(response.Items[0]);

		if (!selectedAddress) {
			setAddressError("Address cannot be empty.");
			isValid = false;
		} else if (response?.valid_multi_unit_building_address) {
			setAddressError("");
		} else {
			setAddressError("Invalid address.");
			isValid = false;
		}
	

		if (agreementChecked) {
			setAgreementError("");
		} else {
			setAgreementError(
				"You must agree that you are not under contract with another real estate agent.",
			);
			isValid = false;
		}

		try {
			if (phoneNumber.trim()) {
				if (PhoneValidator.isValid(phoneNumber, "CA")) {
					setPhoneNumberError("");
				} else {
					setPhoneNumberError("Invalid phone number.");
					isValid = false;
				}
			} else {
				setPhoneNumberError("Phone number cannot be empty.");
				isValid = false;
			}
		} catch (error: unknown) {
			if (error instanceof Error) {
				setPhoneNumberError("Invalid phone number.");
			}
			isValid = false;
		}

		return isValid;
	};

	const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPhoneNumber(formatPhoneNumber(e.target.value));
	};

	const handleConfirmDetails = async () => {
		if (await validateInputs()) {
			setDisabledButton(true);
			try {
				const addressResult: any[] = (
					await getAddressType((suggestion as AddressSuggestion).Id)
				).Items;

				console.log(addressResult);

				const addressObj = addressResult.filter(
					(item: any) => item.Language === "ENG",
				)[0];

				console.log(addressObj);

				const response = await updateAccountData({
					phoneNumber: PhoneValidator.formatE164(phoneNumber, "CA"),
					firstName: capitalizeFirstLetter(firstName),
					lastName: capitalizeFirstLetter(lastName),
					address: {
						address1: addressObj.Line1,
						address2: addressObj.Line2,
						city: addressObj.City,
						state: addressObj.Province,
						postalCode: addressObj.PostalCode,
						country: addressObj.CountryIso2,
					},
				});

				setUserProfile(response);
				try {
					const createOutingResponse = await CreateBuyerOutingInput(
						availableSlots,
						zlids,
					);
					setAvailableSlots([]);
					setZlids([]);

					// const { outingId, pendingContracts } =
					// 	createOutingResponse as OutingResponse;

					// setOutingResponse(outingId, pendingContracts);

					// if (pendingContracts.length > 0 && pendingContracts[0].url) {
					// 	navigate("/cart/contract");
					// } else {
					navigate("/cart/booking-success");
					// }
				} catch (error) {
					console.error("Error confirming showings:", error);
					setDisabledButton(false);
				}
			} catch (error) {
				console.error("Failed to update account data", error);
				navigate("/cart");
				setDisabledButton(false);
			}
		}
	};

	return (
		<div className="flex flex-col items-center text-black space-y-6 w-full pb-6 pt-2">
			<div className="text-center space-y-2 text-black px-4">
				<div className="text-[22px] font-semibold">Confirm your details</div>
				<div className="text-sm text-black">
					Confirm whether the information we have is correct. This information
					will be used for your showings contract.
				</div>
			</div>

			<div className="w-full space-y-4 px-4">
				<div className="flex flex-col items-start space-y-2">
					<div className="text-z-black font-semibold text-sm">
						Legal First Name
					</div>
					<input
						type="text"
						spellCheck="false"
						autoComplete="off"
						className="w-full rounded-[4.28px] px-2 py-3 bg-[#F9F9F9]"
						value={firstName}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setFirstName(e.target.value)
						}
					/>
					{firstNameError && (
						<div className="text-red-500 text-sm">{firstNameError}</div>
					)}
				</div>

				<div className="flex flex-col items-start space-y-2">
					<div className="text-black font-semibold text-sm">
						Legal Last Name
					</div>
					<input
						type="text"
						spellCheck="false"
						autoComplete="off"
						className="w-full rounded-[4.28px] px-2 py-3 bg-[#F9F9F9]"
						value={lastName}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setLastName(e.target.value)
						}
					/>
					{lastNameError && (
						<div className="text-red-500 text-sm">{lastNameError}</div>
					)}
				</div>

				<div className="flex flex-col items-start space-y-2">
					<div className="text-black font-semibold text-sm">Phone Number</div>
					<input
						type="tel"
						spellCheck="false"
						autoComplete="off"
						className="w-full rounded-[4.28px] px-2 py-3 bg-[#F9F9F9]"
						value={phoneNumber}
						onChange={handlePhoneNumberChange}
					/>
					{phoneNumberError && (
						<div className="text-red-500 text-sm">{phoneNumberError}</div>
					)}
				</div>
				<div className="flex flex-col items-start space-y-2">
					<div className="text-black font-semibold text-sm">Address</div>
					<AddressLookupInput error={addressError} />
				</div>
				<div className="flex items-center h-full w-full">
					<input
						id="default-checkbox"
						type="checkbox"
						checked={agreementChecked}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setAgreementChecked(e.target.checked)
						}
						className="w-[30px] h-[30px] text-z-dark-moss-green bg-[#F9F9F9] rounded-[8px] border-[#A4A4A4]"
					/>
					<label
						htmlFor="default-checkbox"
						className="ms-2 text-sm font-medium h-full text-[#32302F]"
					>
						I agree I'm not under contract or working with another real estate
						agent
					</label>
				</div>
				{agreementError && (
					<div className="text-red-500 text-sm">{agreementError}</div>
				)}
			</div>

			<div className="w-full">
				<hr className="h-px my-4 bg-[#EDF1F4] border-0" />
				<div className="px-4">
					<button
						type="button"
						className="bg-z-dark-moss-green w-full font-semibold text-[20px] text-white rounded-full py-3.5 shadow-md drop-shadow-md flex items-center justify-center gap-3"
						onClick={handleConfirmDetails}
						disabled={disabledButton}
					>
						<img src={ClockIcon} alt="Clock Icon" />
						Confirm my details
					</button>
				</div>
			</div>
		</div>
	);
};

export { DetailsConfirmationView };

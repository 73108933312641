import { type ChangeEvent, useEffect, useRef, useState } from "react";
import {
	ChevronDownIcon,
	DocumentCheckIcon,
	InfoIcon,
	MinusIcon,
	PlusIcon,
	SavingIcon3D,
	SuggestionsIcon,
} from "../../assets/icons";

import { Button, Select } from "../common";
import { formatPrice } from "../../utils/formatPrice";
import { getTrackBackground, Range } from "react-range";
import clsx from "clsx";
import { setupHammer } from "./setupHammer";
import { FiTrash2 } from "react-icons/fi";
import { useModalStore } from "../../stores/handleModalStore";
import { finance } from "../../services/finance";
import { Finance, FinancialItemDetail } from "../../interfaces/finance";

const stationOptions = [
	{ value: "Parent", label: "Parent" },
	{ value: "Siblings", label: "Siblings" },
	{ value: "Grandparents", label: "Grandparents" },
	{ value: "Family members", label: "Family members" },
];

const STEP = 1;
const MIN = 0;
const MAX = 26000;

const SavingSection = () => {
	const [savingIsOpen, setSavingIsOpen] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [values, setValues] = useState<number[]>([1000]);
	const {setFinanceSavingsModal} = useModalStore()

	const [fullName, setFullName] = useState("");
	const [amount, setAmount] = useState("");
	const [relationshipSelect, setRelationshipSelect] = useState("");

	const [addGift, setAddGift] = useState(true);

	const [addGiftList, setAddGiftList] = useState<FinancialItemDetail[]>([]);

	const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const numericValue = e.target.value
			.replace(/[^0-9]/g, "")
			.replace(/^0+(?=\d)/, "");
		if (numericValue) {
			const formattedAmout = formatPrice(numericValue);
			setAmount(formattedAmout);
		} else {
			setAmount("");
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (fullName !== "" && amount !== "" && relationshipSelect !== "") {
			setDisableButton(true);
		} else {
			setDisableButton(false);
		}
	}, [fullName, amount, relationshipSelect]);

	const handleSaveData = async() => {
		setAddGift(false);
		const newGiftData: FinancialItemDetail = { id: Date.now(),fullName, amount, relationshipSelect };
		setAddGiftList((prev) => [...prev, newGiftData]);
		setFullName("");
		setAmount("");
		setRelationshipSelect("");
		const payload = {
			financeSaving: [...addGiftList, newGiftData]
		}
		console.log(payload);
		
		await finance(payload)
	};

	const [activeSwipe, setActiveSwipe] = useState<string | null>(null);

	const handleDelete = async(id:number) => {
		setAddGiftList((prevList) =>
			prevList.filter((item) => item.id !== id),
		);
		setActiveSwipe(null);	

		const updatedPayload: Finance = {
			financeSaving: [...addGiftList.filter(item => item.id !== id)],
		};
		console.log(updatedPayload);
    	await finance(updatedPayload);
	};

	const handleSwipeLeft = (el: HTMLDivElement) => {
		const fullName = el.querySelector(".font-bold")?.textContent;
		
		if (fullName) {
			setActiveSwipe(fullName);
		}
	};

	const handleSwipeRight = () => {
		setActiveSwipe(null);
	};

	const savingRef = useRef<HTMLDivElement>(null)
	
	useEffect(() => {
		if (savingIsOpen) {
				savingRef?.current?.scrollIntoView({behavior:'smooth'})
		} else {
			savingRef?.current?.scrollIntoView({behavior:'smooth', block:'center'})
		}
	} , [savingIsOpen])


	const checkGiftList = () => {
		setSavingIsOpen((prev) => !prev)
		if (addGiftList.length > 0) {
			setAddGift(false)
		}else{
			setAddGift(true)
		}
	}

	return (
		<div className="shadow-[0_0_6px_0_rgba(0,0,0,0.15)] rounded-[20px] w-full py-5 px-6 mt-10">
			<div className="flex items-center justify-between">
				<div className="flex items-center space-x-1">
					<img width={28} src={SavingIcon3D} alt="saving icon" />
					<div className="text-[#0075FF] text-lg font-semibold">Savings</div>
				</div>
				<div className="flex items-center">
					<div className="mr-1 flex items-center">
						<span className="text-z-shark text-xl font-bold">$1,000</span>
						<div className="px-2 text-[#828282]">/</div>
						<span className="font-medium text-[#828282]">$26,000</span>
					</div>
					<div
						onClick={() => setFinanceSavingsModal(true)}
						onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								setFinanceSavingsModal(true)
							}
						}}
						>
						<InfoIcon fill="#0075FF"/>
					</div>
				</div>
			</div>
			<Range
				values={values}
				step={STEP}
				min={MIN}
				max={MAX}
				disabled={true}
				onChange={(values) => setValues(values)}
				renderTrack={({ props, children }) => {
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					const { key, ...restProps } = props as any;
					return (
						<div
							key={key}
							{...restProps}
							className="w-full h-6 rounded-3xl bg-gray-300 mt-9 mb-5"
							style={{
								background: getTrackBackground({
									values,
									colors: ["#0075FF", "#D0E4FE"],
									min: MIN,
									max: MAX,
								}),
							}}
						>
							{children}
						</div>
					);
				}}
				renderThumb={({ props }) => {
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					const { key, ...restProps } = props as any;
					return <div key={key} {...restProps} className="" />;
				}}
			/>
			{!savingIsOpen && (
				<div
					className="flex items-center bg-[#DEECFF] h-[60px] px-4 rounded-2xl space-x-4"
					onClick={() => setSavingIsOpen(true)}
					onKeyUp={(event) => {
						if (event.key === "Enter" || event.key === " ") {
							setSavingIsOpen(true);
						}
					}}
				>
					<PlusIcon strokeWidth="2.5" fill="#0075FF" />
					<div className="text-[#0075FF] text-sm font-semibold">
						Add a gift amount
					</div>
				</div>
			)}
			<div
				className={clsx(
					"bg-[#DEECFF] rounded-2xl flex items-center overflow-hidden flex-col px-4 py-5 relative h-[90px] transition-height duration-300 ease-in-out mt-3",
					savingIsOpen ? "min-h-[420px] h-auto" : "max-h-[90px]",
				)} ref={savingRef}
			>
				<div className="flex items-center w-full" 					
					onClick={checkGiftList}
					onKeyUp={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							checkGiftList()
						}
					}}>
					<img src={DocumentCheckIcon} alt="document check icon" />
					<div className="text-[10px] font-medium ml-4">
						You’re doing 3 things really well - tackling
						<br /> the other 2 could boost your savings
					</div>
				</div>
				<div
					className={clsx(
						"absolute top-[70px] transition-all duration-300 left-1/2 -translate-x-1/2",
						savingIsOpen && "rotate-180",
					)}
					onClick={checkGiftList}
					onKeyUp={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							checkGiftList()
						}
					}}
				>
					<ChevronDownIcon fill="#0075FF" />
				</div>

				<div className="mt-12 w-full">
					<div className="flex items-center justify-between">
						<div className="text-[#0075FF] font-bold">Add a gift amount</div>
						<div
							className="w-8 h-8 flex items-center justify-end"
							onClick={checkGiftList}
							onKeyUp={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								checkGiftList()
							}
						}}
						>
							<MinusIcon fill="#0075FF" />
						</div>
					</div>
					{addGift ? (
						<>
							<div className="mt-7 space-y-4">
								<label className="text-z-shark font-medium">Full Name</label>
								<input
									type="text"
									spellCheck="false"
									autoComplete="off"
									className="w-full rounded-xl px-2 py-3 bg-[#fff]"
									value={fullName}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										setFullName(e.target.value)
									}
								/>
							</div>
							<div className="mt-7 space-y-4">
								<div className="text-z-shark font-medium">Relationship</div>
								<Select
									options={stationOptions}
									value={relationshipSelect}
									onChange={setRelationshipSelect}
									placeholder="Select one"
									customClassName="bg-white h-[52px]"
									customClassNameUl="!top-[55px]"
									iconColor="#0075FF"
								/>
							</div>
							<div className="mt-7 space-y-4">
								<label className="text-z-shark font-medium">Amount</label>
								<input
									type="tel"
									spellCheck="false"
									autoComplete="off"
									className="w-full rounded-xl px-2 py-3 bg-[#fff]"
									value={amount === "" ? `${amount}` : `$${amount}`}
									onChange={handleAmountChange}
									placeholder="$50,000"
								/>
							</div>
							<Button
								onClick={handleSaveData}
								width="full"
								height="md"
								variant="blue"
								radius="50px"
								disable={!disableButton}
								customClassName="font-semibold mt-5"
								disableClass="!bg-[#D9D9D9] !text-[#9D9D9D]"
							>
								Save
							</Button>
						</>
					) : (
						<div className="flex items-center flex-col">
							{addGiftList.map((item) => (
								<div
									key={item.fullName}
									className={`flex items-center justify-between text-z-shark w-full mt-6 relative transition-transform duration-300 ${
										activeSwipe === item.fullName
											? "transform -translate-x-[60px]"
											: ""
									}`}
									ref={(el) => {
										if (el) {
											setupHammer(
												el,
												() => {},
												handleSwipeLeft,
												handleSwipeRight,
											);
										}
									}}
								>
									<div className="flex flex-col space-y-1">
										<div className="font-bold">{item.fullName}</div>
										<div className="font-normal text-xs">
											{item.relationshipSelect}
										</div>
									</div>
									<div className="font-bold">${item.amount}</div>
									<div
										className="delete-icon w-[60px] h-[50px] flex items-center justify-center bg-red-500 text-white absolute -right-[80px] top-0"
										onClick={() => handleDelete(item.id)}
										onKeyUp={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												handleDelete(item.id);
											}
										}}
									>
										<FiTrash2 className="text-2xl" />
									</div>
								</div>
							))}
							<div
								className="w-full border-t-2 border-[#A3CCFB] flex items-center mt-10 pt-5 space-x-3"
								onClick={() => setAddGift(true)}
								onKeyUp={(event) => {
									if (event.key === "Enter" || event.key === " ") {
										setAddGift(true);
									}
								}}
							>
								<PlusIcon strokeWidth="2.5px" fill="#0075FF" />
								<div className="text-[#0075FF] font-semibold text-sm">
									Add another gift
								</div>
							</div>
						</div>
					)}

					<div className="mt-9">
						<div className="text-[#0075FF] font-bold">Suggestions</div>
						<div className="space-y-3 mt-3">
							<div className="bg-white rounded-2xl py-4 px-3 flex items-center space-x-3">
								<SuggestionsIcon fill="#DEECFF" stroke="#0075FF" />
								<div className="text-xs font-medium">
									Lorem ipsum dolor sit amet, consectetur adipiscing
								</div>
							</div>
							<div className="bg-white rounded-2xl py-4 px-3 flex items-center space-x-3">
								<SuggestionsIcon fill="#DEECFF" stroke="#0075FF" />
								<div className="text-xs font-medium">
									Lorem ipsum dolor sit amet, consectetur adipiscing
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { SavingSection };

import {
	forwardRef,
	type RefObject,
	useRef,
	type ChangeEvent,
	type FocusEvent,
	type KeyboardEventHandler,
} from "react";
import clsx from "clsx";
import { EditIcon } from "../../assets/FinancesImg";

interface InputOptions {
	label: string;
	labelIcon?: string;
	placeholder?: string;
	value?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
	onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
	inputType?: string;
	name?: string;
	isError?: string;
	onKeyUp?: KeyboardEventHandler<HTMLDivElement>;
	readOnly?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputOptions>(
	(
		{
			label,
			labelIcon,
			placeholder,
			value,
			onChange,
			onBlur,
			onFocus,
			inputType = "text",
			name,
			isError,
			onKeyUp,
			readOnly = false,
		},
		ref,
	) => {
		const inputRef = useRef<HTMLInputElement>(null);
		const combinedRef = (ref as RefObject<HTMLInputElement>) || inputRef;
		const handleEditClick = () => {
			if (combinedRef?.current) {
				combinedRef.current.focus();
			}
		};
		return (
			<div
				className={clsx(
					"bg-white flex flex-col rounded-[20px] p-4 h-[100px] justify-between border-2 focus-within:ring-0 relative shadow-[0_0_6px_0_rgba(0,0,0,0.20)]",
				)}
			>
				<div className="flex items-center mb-2 ">
					{labelIcon && (
						<img className="pr-1" alt="start icon" src={`${labelIcon}`} />
					)}
					<label className="font-semibold text-z-shark">{label}</label>
				</div>

				<div className="flex items-center">
					<input
						ref={combinedRef}
						name={name}
						type={inputType}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
						readOnly={readOnly}
						className="w-full text-3xl font-bold text-gray-900 placeholder-[#AEAEAE] placeholder:font-normal placeholder:text-2xl outline-none focus:outline-none"
					/>
				</div>
				<div
					className="absolute bottom-1 right-1"
					onClick={handleEditClick}
					onKeyUp={onKeyUp}
				>
					<img src={EditIcon} alt="edit icon" />
				</div>
				{isError && (
					<div className="text-[#FF5757] text-xs absolute -bottom-6 left-0">
						{isError}
					</div>
				)}
			</div>
		);
	},
);

export { Input };

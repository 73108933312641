import clsx from "clsx";
import { useState, useEffect, useRef } from "react";
import { ChevronDownIcon } from "../../assets/icons";

interface SelectOption {
	value: string;
	label: string;
	lat?: number;
	lon?: number;
}

interface SelectProps {
	options: SelectOption[];
	value?: SelectOption;
	onChange: (option: SelectOption) => void;
	placeholder?: string;
	iconColor?: string;
	customClassName?: string;
	customClassNameUl?: string;
	search?: boolean;
}

const PoiSelect = ({
	options,
	value,
	onChange,
	placeholder = "Select an option",
	iconColor,
	customClassName,
	customClassNameUl,
	search = false,
}: SelectProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const selectRef = useRef<HTMLDivElement>(null);
	const ulRef = useRef<HTMLUListElement>(null);

	const filteredOptions = searchTerm
		? options.filter((option) =>
				option.label.toLowerCase().includes(searchTerm.toLowerCase()),
			)
		: options;

	const handleSelect = (selectedOption: SelectOption) => {
		onChange(selectedOption);
		setIsOpen(false);
		setSearchTerm("");
	};

	useEffect(() => {
		if (isOpen && ulRef.current) {
			ulRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [isOpen]);

	const handleClickOutside = (event: MouseEvent) => {
		if (
			selectRef.current &&
			!selectRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="relative inline-block w-full" ref={selectRef}>
			<div
				className={clsx(
					"h-16 w-full text-z-shark font-medium text-sm px-3 rounded-xl border border-[#EAEAEA] placeholder:text-[#D1D1D1]",
					customClassName,
				)}
				onClick={() => setIsOpen(!isOpen)}
				onKeyDown={(e) => {
					if (
						e.key === "Enter" ||
						(e.key === " " && document.activeElement !== e.target)
					) {
						e.preventDefault();
						setIsOpen(!isOpen);
					}
				}}
			>
				<div className="flex items-center h-full">
					{value ? (
						value.label
					) : (
						<input
							type="text"
							placeholder={placeholder}
							className="w-full h-full bg-transparent"
							disabled={!search}
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					)}
				</div>
				<div className="absolute inset-y-0 right-3 flex items-center px-2 text-gray-700">
					<ChevronDownIcon fill={iconColor} />
				</div>
			</div>
			{isOpen && (
				<ul
					ref={ulRef}
					className={clsx(
						"absolute z-10 max-h-60 overflow-auto shadow-md bg-white w-full overflow-y-auto border border-[#EAEAEA] rounded-xl top-[74px]",
						customClassNameUl,
					)}
				>
					{filteredOptions.length > 0 ? (
						filteredOptions.map((option) => (
							<li
								key={option.value}
								className="h-12 text-black flex items-center border-t border-t-[#EAEAEA] first:border-t-0"
								onClick={() => handleSelect(option)}
								onKeyDown={(e) => {
									if (e.key === "Enter" || e.key === " ") {
										e.preventDefault();
										handleSelect(option);
									}
								}}
							>
								<span className="px-3">{option.label}</span>
							</li>
						))
					) : (
						<li className="h-12 text-black flex items-center justify-center border-t border-t-[#EAEAEA]">
							<span className="px-3">No options found</span>
						</li>
					)}
				</ul>
			)}
		</div>
	);
};

export { PoiSelect };

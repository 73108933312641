import { PropertyTypeSelectGroup } from "../common/singleSelectGroup";
import { propertyPreferences } from "../../mocks/propertyPreferencesData";
import { useBuyerFiltersStore } from "../../stores/propertyPreferencesStore";
import { useUserProfileStore } from "../../stores/profileStore";
import { useEffect } from "react";
import { useMatchFiltersStore } from "../../stores/filterStore";
import { MultiSelectGroup } from "../common/multiSelectGroup";

const PropertyPreferencesView = () => {
	const {
		matchBedCount,
		matchBathCount,
		matchParking,
		matchDenCount,

		setMatchBedCount,
		setMatchBathCount,
		setMatchParking,
		setMatchDenCount,
	} = useMatchFiltersStore();

	const { userProfile, setUserProfile } = useUserProfileStore();

	useEffect(() => {
		if (userProfile?.matchingPreferences?.filters) {
			const {
				numberOfBathrooms,
				numberOfBedrooms,
				numberOfParkingSpaces,
				dens,
			} = userProfile.matchingPreferences.filters;

			setMatchBedCount(numberOfBedrooms?.map(String) ?? []);
			setMatchBathCount(numberOfBathrooms?.map(String) ?? []);
			setMatchParking(numberOfParkingSpaces?.map(String) ?? []);
			setMatchDenCount(dens ?? null);
		}
	}, [
		userProfile,
		setMatchBedCount,
		setMatchBathCount,
		setMatchParking,
		setMatchDenCount,
	]);

	return (
		<div className="h-full px-4">
			<div className="space-y-8 pb-6">
				<div className="text-z-shark text-2xl font-bold">
					What are some of your preferences?
				</div>
				<div className="space-y-10">
					<MultiSelectGroup
						gap="12px"
						selectedValue={matchBedCount}
						label="Beds"
						onChange={(values) => {
							const updatedValues = values.map((value) =>
								value === "5+" ? "5" : value,
							);
							setMatchBedCount(updatedValues);
						}}
						firstClass={true}
						options={propertyPreferences.beds.map((item) => ({
							label: item.value === 5 ? "5+" : item.label,
							value: String(item.value === null ? "Any" : item.value),
						}))}
						customClassName="text-[#B8B8B8]"
					/>

					<MultiSelectGroup
						gap="12px"
						selectedValue={matchBathCount}
						label="Bathrooms"
						onChange={(values) => {
							const updatedValues = values.map((value) =>
								value === "5+" ? "5" : value,
							);
							setMatchBathCount(updatedValues);
						}}
						firstClass={true}
						options={propertyPreferences.beds.map((item) => ({
							label: item.value === 5 ? "5+" : item.label,
							value: String(item.value === null ? "Any" : item.value),
						}))}
						customClassName="text-[#B8B8B8]"
					/>

					<MultiSelectGroup
						gap="12px"
						selectedValue={matchParking}
						label="Parking"
						onChange={(values) => {
							const updatedValues = values.map((value) =>
								value === "5+" ? "5" : value,
							);
							setMatchParking(updatedValues);
						}}
						firstClass={true}
						options={propertyPreferences.beds.map((item) => ({
							label: item.value === 5 ? "5+" : item.label,
							value: String(item.value === null ? "Any" : item.value),
						}))}
						customClassName="text-[#B8B8B8]"
					/>

					<PropertyTypeSelectGroup
						gap="12px"
						selectedValue={matchDenCount}
						label="Dens"
						onChange={setMatchDenCount}
						options={propertyPreferences.dens.map((item) => item)}
						customClassName="text-[#B8B8B8]"
						width="90px"
					/>
				</div>
			</div>
		</div>
	);
};

export { PropertyPreferencesView };

import { useRef, useEffect } from "react";
import XbuttonIcon from "../assets/xButtonIcon.svg";
import { useShowTopMatchesModalStore } from "../stores/showTopMatchesModalStore";
import TopMatchesModalHomeImage from "../assets/static/images/top-matches-modal-home.webp";

const YourTopMatchesModal = () => {
	const { setShowTopMatchesModal } = useShowTopMatchesModalStore();
	const modalRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				setShowTopMatchesModal(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [setShowTopMatchesModal]);

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center px-2">
			<div className="absolute inset-0 bg-white bg-opacity-70 backdrop-blur-sm" />

			<div
				ref={modalRef}
				className="relative w-full max-w-md p-6 bg-white shadow-md rounded-[20px] space-y-4 z-10"
			>
				<button
					type="button"
					className="right-4 absolute w-[46px] h-[46px] rounded-full flex items-start justify-end"
					onClick={() => setShowTopMatchesModal(false)}
				>
					<img src={XbuttonIcon} alt="Close" />
				</button>
				<img src={TopMatchesModalHomeImage} alt="" />
				<h2 className="text-3xl text-black font-semibold text-start">
					Your Top Matches
				</h2>
				<div>
					Based on our research, these homes are perfectly matched for you based
					on
				</div>
				<button
					type="button"
					className="bg-z-dark-moss-green max-w-[193px] text-white font-semibold text-lg rounded-full w-full py-3"
					onClick={() => setShowTopMatchesModal(false)}
				>
					Ok, Got It
				</button>
			</div>
		</div>
	);
};

export { YourTopMatchesModal };

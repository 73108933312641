import {
	useState,
	type ChangeEvent,
	type KeyboardEvent,
	type RefObject,
} from "react";
import DefaultProfilePic from "../../assets/profile/defaultProfilePic.svg";
import AddProfilePicButtonIcon from "../../assets/profile/addProfilePicButtonIcon.svg";
import RemoveProfilePicIcon from "../../assets/profile/RemoveProfilePicButtonIcon.svg";

interface ProfilePictureProps {
	image: string | ArrayBuffer | null;
	onImageUpload: (event: ChangeEvent<HTMLInputElement>) => void;
	onRemoveImage: () => void;
	fileInputRef: RefObject<HTMLInputElement>;
}

const MAX_FILE_SIZE_MB = 3;

const ProfilePicture = ({
	image,
	onImageUpload,
	onRemoveImage,
	fileInputRef,
}: ProfilePictureProps) => {
	const [error, setError] = useState<string | null>(null);

	const triggerFileInput = () => {
		fileInputRef.current?.click();
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLImageElement>) => {
		if (event.key === "Enter" || event.key === " ") {
			triggerFileInput();
		}
	};

	const handleRemoveImageKeyDown = (event: KeyboardEvent<HTMLImageElement>) => {
		if (event.key === "Enter" || event.key === " ") {
			onRemoveImage();
		}
	};

	const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];

		if (file) {
			const fileSizeInMB = file.size / (1024 * 1024); // Convert size from bytes to MB

			if (fileSizeInMB > MAX_FILE_SIZE_MB) {
				// Trigger an error if the file size is larger than 3MB
				setError(
					`File size exceeds the 3MB limit. Your file is ${fileSizeInMB.toFixed(2)} MB.`,
				);
				return;
			}

			// Clear the error if the file size is valid
			setError(null);

			// Proceed with the image upload
			onImageUpload(event);
		}
	};

	return (
		<div className="flex flex-col items-center text-center bg-transparent">
			<div className="w-fit relative">
				<img
					src={image ? (image as string) : DefaultProfilePic}
					alt="Profile"
					className="w-[146px] h-[146px] object-cover rounded-full cursor-pointer shadow-md"
					onClick={triggerFileInput}
					onKeyDown={handleKeyDown}
				/>
				{image ? (
					<img
						src={RemoveProfilePicIcon}
						alt="Remove Profile"
						className="absolute w-[34px] h-[34px] right-0 top-0 cursor-pointer"
						onClick={onRemoveImage}
						onKeyDown={handleRemoveImageKeyDown}
					/>
				) : (
					<img
						src={AddProfilePicButtonIcon}
						alt="Add Profile"
						className="absolute w-[34px] h-[34px] right-0 bottom-0 cursor-pointer"
						onClick={triggerFileInput}
						onKeyDown={handleKeyDown}
					/>
				)}
				<input
					type="file"
					accept="image/*"
					onChange={handleImageUpload}
					className="hidden"
					ref={fileInputRef}
				/>
			</div>
			{/* Render the error message below the input */}
			{error && <p className="text-red-500 text-sm mt-2">{error}</p>}
		</div>
	);
};

export { ProfilePicture };

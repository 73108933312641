import { create } from "zustand";

interface OutingStoreState {
  outingId: string | null;
  pendingContracts: { url: string; id: string }[];
  setOutingResponse: (outingId: string, pendingContracts: { url: string; id: string }[]) => void;
}

const useOutingStore = create<OutingStoreState>((set) => ({
  outingId: null,
  pendingContracts: [],
  setOutingResponse: (outingId, pendingContracts) => set({ outingId, pendingContracts }),
}));

export { useOutingStore };

import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import BedIcon from "../../assets/cardBedIcon.svg";
import SqftIcon from "../../assets/carSqftIcon.svg";
import { formatPrice } from "../../utils/formatPrice";
import ShowerIcon from "../../assets/cardShowerIcon.svg";
import { useImagesStore } from "../../stores/imagesStore";
import ListingXbutton from "../../assets/listingXButton.svg";
import { convertToFormattedAmount } from "../../utils/convertAmount";

import "swiper/css";

interface ListingPropertyImagesProps {
	images: string[];
	zlid: string;
	address: string;
	listingPrice: string;
	bed: string;
	bath: string;
	sqft: string;
	propertyType: string;
	listingDate: Date;
	downPaymentContribution: number;
}

const LisitngImages = ({
	images,
	zlid,
	address,
	listingPrice,
	bed,
	bath,
	sqft,
	propertyType,
	listingDate,
	downPaymentContribution,
}: ListingPropertyImagesProps) => {
	const { setImages } = useImagesStore();
	const navigate = useNavigate();

	const [currentIndex, setCurrentIndex] = useState(0);

	const handleSwiperClick = () => {
		setImages(images);
		navigate(`/listing/${zlid}/images`);
	};

	return (
		<div className="relative">
			<Swiper
				slidesPerView={1}
				loop={true}
				onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
				className="rounded-[20px]"
				onClick={handleSwiperClick}
			>
				{images.map((url, index) => (
					<SwiperSlide key={url}>
						<img
							src={url}
							alt={`Property view ${index + 1}`}
							className=" h-[415px] w-full object-cover"
						/>
					</SwiperSlide>
				))}
			</Swiper>
			<div className="absolute top-2 left-1/2 transform -translate-x-1/2 flex space-x-1 z-20">
				{images.map((imgSrc, index) => (
					<div
						key={imgSrc}
						className={`h-1.5 bg-black rounded-full will-change-transform transition-all duration-300 ease-in ${
							currentIndex === index ? "w-5" : "w-1.5"
						}`}
					/>
				))}
			</div>
			<div className="h-fit w-full bottom-0 right-0 absolute z-20 text-white">
				<div className="relative flex flex-col items-start space-y-1.5 text-start py-4 px-4 pt-2 z-30">
					<div
						className="text-2xl font-semibold"
						style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
					>
						${formatPrice(convertToFormattedAmount(listingPrice))}
					</div>
					<div
						className="font-medium text-sm"
						style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
					>
						{address}
					</div>
					<div
						className="flex gap-3"
						style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
					>
						<div
							className="flex items-center gap-1.5 text-sm"
							style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
						>
							<img src={BedIcon} alt="Beds" />
							{bed}
						</div>
						<div
							className="flex items-center gap-1.5 text-sm"
							style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
						>
							<img src={ShowerIcon} alt="Baths" />
							{bath}
						</div>
						<div
							className="flex items-center gap-1.5 text-sm"
							style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
						>
							<img src={SqftIcon} alt="Square footage" />
							{`${formatPrice(sqft)}`} sqft
						</div>
					</div>
					<div className="bg-white rounded-full text-z-dark-moss-green text-sm font-extrabold px-2 py-1 flex gap-2 h-full">
						<div>Down Payment Contribution</div>
						<div>${formatPrice(downPaymentContribution)}</div>
					</div>
					<div className="text-[#D0D0D0] text-sm">
						{propertyType} • Listed {moment.utc(listingDate).fromNow()}
					</div>
				</div>
			</div>
			<button
				type="button"
				onClick={() => navigate(-1)}
				className="absolute top-2 w-[60px] h-[60px] flex items-start drop-shadow-md justify-end  z-20 right-2"
			>
				<img src={ListingXbutton} alt="" />
			</button>

			<div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-[#012A43] rounded-[12.33px] z-10 pointer-events-none" />
		</div>
	);
};

export { LisitngImages };

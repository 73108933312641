import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { MinusIcon, PlusIcon } from "../../assets/icons";
import { usePropertyCityStore } from "../../stores/propertyCitySelection";

const listOfCities: string[] = [
	"Hamilton",
	"Toronto",
	"Tiny",
	"Barrie",
	"Kingston",
	"Vaughan",
	"Ottawa",
	"Brampton",
	"St. Catharines",
	"Collingwood",
	"Mississauga",
	"Brantford",
	"Thorold",
	"Innisfil",
	"Clearview",
	"Guelph",
	"Kitchener",
	"Cambridge",
	"Niagara-on-the-Lake",
	"Niagara Falls",
	"Dutton/Dunwich",
	"Minto",
	"Magnetawan",
	"Midland",
	"Perry",
	"Wellington North",
	"Lincoln",
	"Madoc",
	"Milton",
	"The Blue Mountains",
	"Caledon",
	"Richmond Hill",
	"Georgian Bluffs",
	"North Frontenac",
	"Northern Bruce Peninsula",
	"Fort Erie",
	"North Bay",
	"Welland",
	"Huntsville",
	"Cavan Monaghan",
	"Kawartha Lakes",
	"Grimsby",
	"Hawkesbury",
	"Burlington",
	"Beckwith",
	"Leeds and the Thousand Islands",
	"Uxbridge",
	"Severn",
	"Markham",
	"Central Huron",
	"South Huron",
	"London",
];

const recommandedCitiesIndex: number[] = [1, 15];

const PickCity = () => {
	const navigate = useNavigate();

	const searchInputRef = useRef<HTMLInputElement>(null);
	const searchResultControlRef = useRef<HTMLUListElement>(null);

	const [searchQuery, setSearchQuery] = useState<string>("");
	const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
	const [filteredCities, setFilteredCities] = useState<string[]>([]);
	const { selectedCities, setSelectedCities } = usePropertyCityStore();

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			if (
				searchInputRef.current &&
				!searchInputRef.current.contains(e.target as Node) &&
				searchResultControlRef.current &&
				!searchResultControlRef.current.contains(e.target as Node)
			) {
				setShowSearchResult(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	const availableCities = recommandedCitiesIndex
		.map((index) => listOfCities[index])
		.filter((city) => !selectedCities.includes(city));

	console.log("here", selectedCities);
	const saveData = async () => {
		// Mark the function as async
		const cityNames = selectedCities.map((city) => city);

		// await updatePreferences(undefined, undefined, {
		//   selectedCities: cityNames,
		// });

		navigate("/profile/points-interest", { state: { origin: "filterPanel" } });
	};

	// Post
	//   const handleHomeFeatures = async () => {
	//     const payload:BuyerHomeFeatures = {
	//       selectedCities: selectedCities,
	//     };

	//     console.log("inputFlow response:", payload);
	//     await buyerHomeFeaturesService(payload);
	// };

	return (
		<div
			className={`absolute inset-0 bg-white px-4 pt-2 z-30 ${
				window.bridge ? "pt-bridge-inset-top pb-bridge-inset-bottom" : "pb-24"
			} text-z-shark w-full`}
		>
			<h3 className="text-z-shark text-2xl font-bold mb-2">Pick a city</h3>
			<span className="text-z-shark text-sm">
				Are there any specific regions you want to live in
			</span>
			<div className="mt-3 relative mb-3">
				<input
					ref={searchInputRef}
					className="h-16 w-full text-z-shark font-medium text-sm px-3 rounded-xl border border-[#EAEAEA] placeholder:text-[#D1D1D1]"
					type="text"
					onChange={(e) => {
						setSearchQuery(e.target.value);
						setShowSearchResult(true);

						const filtered = listOfCities.filter(
							(city) =>
								city.toLowerCase().includes(e.target.value.toLowerCase()) &&
								!selectedCities.includes(city),
						);
						setFilteredCities(filtered);
					}}
					value={searchQuery}
					placeholder="Enter a city"
				/>
				{showSearchResult && (
					<ul
						ref={searchResultControlRef}
						className="shadow-md bg-white w-full min-h-12 max-h-48 overflow-y-auto border border-[#EAEAEA] rounded-xl absolute top-[74px] z-10"
					>
						{filteredCities.length > 0 ? (
							filteredCities.map((city) => (
								<li
									className="h-12 flex items-center border-t border-t-[#EAEAEA] first:border-t-0"
									key={city}
									onClick={(e) => {
										e.preventDefault();
										setShowSearchResult(false);
										setSearchQuery("");
										setSelectedCities(city);
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter" || e.key === " ") {
											e.preventDefault();
											setShowSearchResult(false);
											setSearchQuery("");
											setSelectedCities(city);
										}
									}}
								>
									<span className="px-3">{city}</span>
								</li>
							))
						) : (
							<li className="absolute font-semibold top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
								No cities found
							</li>
						)}
					</ul>
				)}
			</div>

			<div className="overflow-y-auto h-[calc(100%_-_145px)]">
				{availableCities.length > 0 && (
					<div className="mt-6 space-y-3">
						<span className="text-sm font-semibold text-z-shark">
							Recommended Cities
						</span>
						<div className="flex items-center flex-wrap gap-4">
							{availableCities.map((city) => (
								<div
									onClick={(e) => {
										e.preventDefault();
										setSelectedCities(city);
									}}
									onKeyDown={(e) => {
										e.preventDefault();
										setSelectedCities(city);
									}}
									key={city}
									className="px-5 space-x-6 py-3 rounded-full bg-[#EFF6FF] flex items-center justify-between"
								>
									<span className="text-z-shark text-sm font-semibold">
										{city}
									</span>
									<PlusIcon />
								</div>
							))}
						</div>
					</div>
				)}

				{selectedCities.length > 0 && (
					<div className="mt-12 space-y-3">
						<span className="text-sm font-semibold text-z-shark">
							Selected Cities
						</span>
						{selectedCities.length > 0 && (
							<ul className="flex flex-col gap-4">
								{selectedCities.map((city, index) => (
									<li
										onClick={(e) => {
											e.preventDefault();
											setSelectedCities(city);
										}}
										onKeyDown={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												e.preventDefault();
											}
										}}
										key={city}
										className="flex flex-1 items-center justify-between bg-[#FCF9EA] rounded-lg px-3 py-3 text-xl text-z-shark font-semibold"
									>
										<span>{city}</span>
										<MinusIcon />
									</li>
								))}
							</ul>
						)}
					</div>
				)}
			</div>
			<Button
				customClassName={`text-lg font-semibold absolute left-1/2 -translate-x-1/2 ${
					window.bridge ? "bottom-bridge-inset-bottom" : "bottom-5"
				}`}
				onClick={saveData}
				radius="60px"
				width="330px"
				height="md"
			>
				Continue
			</Button>
		</div>
	);
};

export { PickCity };

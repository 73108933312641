import { useRef } from "react";
import { Chart, type TooltipItem } from "chart.js";
import type { AverageDaysOnMarket } from "../../../interfaces/statistics";

interface MedianSoldPriceAvgDomProps {
	medianSoldPriceAvgDom: AverageDaysOnMarket;
}

const MedianSoldPriceAvgDom = ({
	medianSoldPriceAvgDom,
}: MedianSoldPriceAvgDomProps) => {
	const chartRef = useRef<Chart | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	const soldPriceData = medianSoldPriceAvgDom.statistics.soldPrice.mth;
	const daysOnMarketData = medianSoldPriceAvgDom.statistics.daysOnMarket.mth;

	const xValues = Object.keys(soldPriceData).sort();

	const medianSoldPrice = xValues.map(
		(month) => soldPriceData[month]?.med || 0,
	);
	const avgDaysOnMarket = xValues.map(
		(month) => daysOnMarketData[month]?.avg || 0,
	);

	if (canvasRef.current) {
		if (chartRef.current) {
			chartRef.current.destroy();
		}

		const ctx = canvasRef.current.getContext("2d");
		if (!ctx) {
			return;
		}

		const gradient = ctx.createLinearGradient(0, 0, 0, 600);
		gradient.addColorStop(0, "rgba(136, 215, 255, 0.30)");
		gradient.addColorStop(1, "rgba(136, 215, 255, 0)");

		chartRef.current = new Chart(ctx, {
			type: "bar",
			data: {
				labels: xValues,
				datasets: [
					{
						type: "line",
						label: "Median Sold Price",
						data: medianSoldPrice,
						fill: true,
						backgroundColor: gradient,
						pointRadius: 0,
						borderColor: "#88D7FF",
						tension: 0.3,
						yAxisID: "y",
					},
					{
						type: "bar",
						label: "Average Days on Market",
						data: avgDaysOnMarket,
						backgroundColor: "#C8FFF5",
						yAxisID: "y1",
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: true,
					},
					tooltip: {
						mode: "index",
						intersect: false,
						callbacks: {
							label: (tooltipItem: TooltipItem<"line" | "bar">) => {
								if (tooltipItem.datasetIndex === 0) {
									return `Price: $${tooltipItem.formattedValue}`;
								}
								return `Days on Market: ${tooltipItem.formattedValue}`;
							},
						},
					},
				},
				scales: {
					x: {
						grid: {
							display: false,
						},
					},
					y: {
						type: "linear",
						position: "left",
						ticks: {
							callback: (value) => `$${value}`,
							stepSize: 50000,
						},
					},
					y1: {
						type: "linear",
						position: "right",
						ticks: {
							stepSize: 5,
						},
						grid: {
							drawOnChartArea: false,
						},
					},
				},
			},
		});
	}

	return (
		<div className="space-y-4 px-4">
			<div>
				<div className="font-semibold text-lg">
					Median Sold Price & Avg. Days on Market
				</div>
				<canvas ref={canvasRef} id="myChart" className="h-auto max-h-[220px]" />
			</div>
		</div>
	);
};

export { MedianSoldPriceAvgDom };

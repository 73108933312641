import { create } from "zustand";

interface RestTopPicksState {
  endDate: Date | null;
  countdown: string;
  intervalId: ReturnType<typeof setInterval> | null;
  startCountdown: () => void;
  resetCountdown: () => void;
  setEndDate: (date: Date) => void;
}

const useRestTopPicksStore = create<RestTopPicksState>((set, get) => ({
  endDate: null,
  countdown: "00:00:00",
  intervalId: null,

  setEndDate: (date: Date) => {
    set({ endDate: date });
    get().startCountdown();
  },

  startCountdown: () => {
    const calculateCountdown = () => {
      const { endDate } = get();
      if (!endDate) {
        return "00:00:00";
      }

      const now = new Date();
      const distance = endDate.getTime() - now.getTime();

      if (distance <= 0) {
        const { intervalId } = get();
        if (intervalId) {
          clearInterval(intervalId);
        }
        return "00:00:00";
      }

      const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((distance / 1000 / 60) % 60);
      const seconds = Math.floor((distance / 1000) % 60);

      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    };

    const updateCountdown = () => {
      const countdownValue = calculateCountdown();
      set({ countdown: countdownValue });
    };

    updateCountdown();

    const intervalId = setInterval(updateCountdown, 1000);
    set({ intervalId });
  },

  resetCountdown: () => {
    const { intervalId } = get();
    if (intervalId) {
      clearInterval(intervalId);
    }
    set({ countdown: "00:00:00", endDate: null, intervalId: null });
  },
}));

export { useRestTopPicksStore };

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOutingStore } from "../../stores/outingStore";

const ContractView = () => {
	const navigate = useNavigate();
	const { pendingContracts } = useOutingStore();
	const [currentUrlIndex, setCurrentUrlIndex] = useState(0);

	useEffect(() => {
		const urls = pendingContracts.map((contract) => contract.url);

		if (urls.length === 0) {
			navigate("/cart/booking-success");
			return;
		}

		const intervalId = setInterval(() => {
			setCurrentUrlIndex((prevIndex) => {
				const nextIndex = prevIndex + 1;
				if (nextIndex >= urls.length) {
					clearInterval(intervalId);
					navigate("/cart/booking-success");
				}
				return nextIndex;
			});
		}, 5000);

		return () => clearInterval(intervalId);
	}, [pendingContracts, navigate]);

	const urls = pendingContracts.map((contract) => contract.url);

	return (
		<iframe
			src={urls[currentUrlIndex] || "about:blank"}
			className="absolute w-full h-full"
			title="Contract Viewer"
		/>
	);
};

export { ContractView };

import { CmaBox } from "./cmaBox";
import { LisintgDescriptionMap } from "./map";
import { ListingHistory } from "./listingHistory";
import { ListingPropertyInfo } from "./propertyInfo";
// import { OfferPrediction } from "./offerPrediction";
import type { Listing } from "../../../interfaces/listing";
import { ChanceOfferAccepted } from "./chanceOfferAccepted";
import ArrowGreenIcon from "../../../assets/arrowGreenIcon.svg";
import GrayArrowDownIcon from "../../../assets/grayArrowDownIcon.svg";
import { type KeyboardEvent, useEffect, useRef, useState } from "react";
import PropertyDetailsIcon from "../../../assets/Listing/propertyDetailsIcon.svg";
import type { StatisticsResponse } from "../../../interfaces/statistics";
import { OfferPrediction } from "./offerPrediction";

interface PropertyDetailsProps {
	listing: Listing;
	statistics?: StatisticsResponse | null;
}

const PropertyDetails = ({ listing, statistics }: PropertyDetailsProps) => {
	const [isExpanded, setIsExpanded] = useState(true);
	const [showMore, setShowMore] = useState(false);
	const descriptionRef = useRef<HTMLDivElement>(null);
	const [isClamped, setIsClamped] = useState(false);

	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (descriptionRef.current) {
			const { clientHeight, scrollHeight } = descriptionRef.current;
			setIsClamped(scrollHeight > clientHeight);
		}
	}, [listing.description]);

	useEffect(() => {
		if (descriptionRef.current) {
			if (showMore) {
				descriptionRef.current.style.maxHeight = `${descriptionRef.current.scrollHeight}px`;
				descriptionRef.current.style.height = "auto";
			} else {
				descriptionRef.current.style.maxHeight = "4.5em";
			}
		}
	}, [showMore]);

	return (
		<div className="w-full bg-white flex justify-between rounded-[20px] py-4 items-start shadow-md drop-shadow-lg">
			<div className="w-full">
				<div
					className="text-black font-semibold text-xl flex items-center justify-between w-full px-4 cursor-pointer"
					onClick={toggleSection}
					onKeyDown={handleKeyDown}
					aria-expanded={isExpanded}
				>
					<div className="flex items-center gap-2">
						<img src={PropertyDetailsIcon} alt="" />
						Property Details
					</div>

					<img
						src={GrayArrowDownIcon}
						alt="Toggle Section"
						className={`transition-transform duration-300 ${
							isExpanded ? "rotate-180" : ""
						}`}
					/>
				</div>

				<div
					className={`overflow-hidden transition-[max-height] duration-300 ease-in-out space-y-6 ${
						isExpanded ? "max-h-auto" : "max-h-0"
					}`}
				>
					<hr className="h-px bg-[#F3F3F3] border-0 mt-6" />
					<div className="px-4 space-y-3">
						<div
							ref={descriptionRef}
							className="text-[#A0A0A0] overflow-hidden transition-[max-height]  duration-300 ease-in-out"
							style={{
								display: "-webkit-box",
								WebkitLineClamp: showMore ? "unset" : 3,
								WebkitBoxOrient: "vertical",
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxHeight: showMore ? "none" : "4.5em",
							}}
						>
							{listing.description}
						</div>
						{(showMore || !isClamped) && (
							<div className="text-[#A0A0A0] pt-5">
								Listing by: {listing.listingAgent}
							</div>
						)}

						<button
							type="button"
							className="text-z-dark-moss-green font-medium flex gap-2 items-center cursor-pointer"
							onClick={() => setShowMore(!showMore)}
						>
							{showMore ? "Show less" : "Show more"}
							<img
								src={ArrowGreenIcon}
								alt="Arrow Down"
								className={`transform transition-transform duration-300 ${
									showMore ? "rotate-180" : ""
								}`}
							/>
						</button>

						<LisintgDescriptionMap lat={listing.lat} lng={listing.long} />
					</div>

					<CmaBox
						address={listing.address}
						zlid={listing.listingId}
						listingPrice={listing.listingPrice}
						valuation={
							listing.valuation
								? listing.valuation.final_ai_eval_for_target_property
								: 0
						}
					/>

					<ListingPropertyInfo
						tax={listing.tax}
						propertyType={listing.propertyType}
						buildingAge={listing.buildingAge}
						size={listing.size}
						lot={listing.lot}
						parking={listing.parking}
						basement={listing.basement}
						mlsNumber={listing.mlsNumber}
						dataSource={listing.dataSource}
						statusChange={listing.statusChange}
						listingDate={listing.listingDate}
						updatedDate={listing.updatedDate}
						style={listing.style}
						community={listing.community}
						municipality={listing.municipality}
						bed={listing.bed}
						bath={listing.bath}
						colling={listing.colling}
						rooms={listing.rooms}
						heatingType={listing.heatingType}
						heatingFuel={listing.heatingFuel}
						amenities={listing.amenities}
						garageType={listing.garageType}
						crossStreet={listing.crossStreet}
					/>
					{listing.listingHistory.length > 0 && (
						<>
							<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
							<ListingHistory listingHistory={listing.listingHistory} />
						</>
					)}

					<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
					{statistics?.percentageOverAskingPrice && (
						<OfferPrediction
							zlid={listing.listingId}
							dom={listing.dom}
						comunity={listing.community}
							percentageOverAskingPrice={statistics?.percentageOverAskingPrice}
						/>
					)}

					<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
					<ChanceOfferAccepted
						listingPrice={listing.listingPrice}
						inGta={false}
						dom={listing.dom}
					/>
				</div>
			</div>
		</div>
	);
};

export { PropertyDetails };

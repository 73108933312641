import { useEffect, useRef, useState } from "react";
import { MinusIcon, PlusIcon } from "../../assets/icons";
import { usePropertyCityStore } from "../../stores/propertyCitySelection";
import { useUserProfileStore } from "../../stores/profileStore";

const listOfCities: string[] = [
	"Hamilton",
	"Toronto",
	"Tiny",
	"Barrie",
	"Kingston",
	"Vaughan",
	"Ottawa",
	"Brampton",
	"St. Catharines",
	"Collingwood",
	"Mississauga",
	"Brantford",
	"Thorold",
	"Innisfil",
	"Clearview",
	"Guelph",
	"Kitchener",
	"Cambridge",
	"Niagara-on-the-Lake",
	"Niagara Falls",
	"Dutton/Dunwich",
	"Minto",
	"Magnetawan",
	"Midland",
	"Perry",
	"Wellington North",
	"Lincoln",
	"Madoc",
	"Milton",
	"The Blue Mountains",
	"Caledon",
	"Richmond Hill",
	"Georgian Bluffs",
	"North Frontenac",
	"Northern Bruce Peninsula",
	"Fort Erie",
	"North Bay",
	"Welland",
	"Huntsville",
	"Cavan Monaghan",
	"Kawartha Lakes",
	"Grimsby",
	"Hawkesbury",
	"Burlington",
	"Beckwith",
	"Leeds and the Thousand Islands",
	"Uxbridge",
	"Severn",
	"Markham",
	"Central Huron",
	"South Huron",
	"London",
];

const recommandedCitiesIndex: number[] = [1, 15];

const PropertyCitySelectionView = () => {
	const searchInputRef = useRef<HTMLInputElement>(null);
	const searchResultControlRef = useRef<HTMLUListElement>(null);

	const [searchQuery, setSearchQuery] = useState<string>("");
	const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
	const [filteredCities, setFilteredCities] = useState<string[]>([]);
	const { selectedCities, setSelectedCities } = usePropertyCityStore();
	const { userProfile, setUserProfile } = useUserProfileStore();

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			if (
				searchInputRef.current &&
				!searchInputRef.current.contains(e.target as Node) &&
				searchResultControlRef.current &&
				!searchResultControlRef.current.contains(e.target as Node)
			) {
				setShowSearchResult(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	useEffect(() => {
		const userSelectedCities =
			userProfile?.matchingPreferences?.filters?.selectedCities || [];

		console.log("User selected cities from profile:", userSelectedCities);

		for (const city of userSelectedCities) {
			if (city && typeof city === "string") {
				console.log("Adding city from profile:", city);
				setSelectedCities(city);
			} else {
				console.warn("Skipping invalid city from profile:", city);
			}
		}
	}, [userProfile, setSelectedCities]);

	const availableCities = recommandedCitiesIndex
		.map((index) => listOfCities[index])
		.filter((city) => !selectedCities.includes(city));

	return (
		<div className="w-full h-full relative">
			<div className="pb-6">
				<h2 className="text-z-shark text-2xl font-bold mb-2 px-4">
					Where would you like to live?
				</h2>
				<span className="text-z-shark text-sm px-4">
					Pick the cities that feel like home
				</span>
				<div className="mt-3 relative w-full">
					<div className="relative mx-4">
						<input
							ref={searchInputRef}
							className="h-16 w-full text-z-shark font-medium text-sm px-3 rounded-xl border border-[#EAEAEA] placeholder:text-[#D1D1D1]"
							type="text"
							onChange={(e) => {
								setSearchQuery(e.target.value);
								setShowSearchResult(true);

								const filtered = listOfCities.filter(
									(city) =>
										city.toLowerCase().includes(e.target.value.toLowerCase()) &&
										!selectedCities.includes(city),
								);
								setFilteredCities(filtered);
							}}
							value={searchQuery}
							placeholder="Enter a city"
						/>

						{showSearchResult && (
							<div className="absolute top-[74px] w-full">
								<ul
									ref={searchResultControlRef}
									className="shadow-md bg-white  min-h-12 max-h-48 overflow-y-auto border border-[#EAEAEA] rounded-xl w-full z-10"
								>
									{filteredCities.length > 0 ? (
										filteredCities.map((city) => (
											<li
												className="h-12 flex items-center border-t border-t-[#EAEAEA] first:border-t-0"
												key={city}
												onClick={(e) => {
													e.preventDefault();
													setShowSearchResult(false);
													setSearchQuery("");
													setSelectedCities(city);
												}}
												onKeyDown={(e) => {
													if (e.key === "Enter" || e.key === " ") {
														e.preventDefault();
														setShowSearchResult(false);
														setSearchQuery("");
														setSelectedCities(city);
													}
												}}
											>
												<span className="px-3">{city}</span>
											</li>
										))
									) : (
										<li className="absolute font-semibold top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
											No cities found
										</li>
									)}
								</ul>
							</div>
						)}
					</div>
					{availableCities.length > 0 && (
						<div className="mt-6 space-y-3 px-4">
							<span className="text-sm font-semibold text-z-shark">
								Recommended Cities
							</span>
							<div className="flex items-center flex-wrap gap-4">
								{availableCities.map((city) => (
									<div
										onClick={(e) => {
											e.preventDefault();
											setSelectedCities(city);
										}}
										onKeyDown={(e) => {
											e.preventDefault();
											setSelectedCities(city);
										}}
										key={city}
										className="px-5 space-x-6 py-3 rounded-full bg-[#EFF6FF] flex items-center justify-between"
									>
										<span className="text-z-shark text-sm font-semibold">
											{city}
										</span>
										<PlusIcon />
									</div>
								))}
							</div>
						</div>
					)}

					{selectedCities.length > 0 && (
						<div className="mt-12 space-y-3 px-4">
							<span className="text-sm font-semibold text-z-shark">
								Selected Cities
							</span>
							{selectedCities.length > 0 && (
								<ul className="flex flex-col gap-4">
									{selectedCities.map((city) => (
										<li
											onClick={(e) => {
												e.preventDefault();
												setSelectedCities(city);
											}}
											onKeyDown={(e) => {
												if (e.key === "Enter" || e.key === " ") {
													e.preventDefault();
												}
											}}
											key={city}
											className="flex flex-1 items-center justify-between bg-[#FCF9EA] rounded-lg px-3 py-3 text-xl text-z-shark font-semibold"
										>
											<span>{city}</span>
											<MinusIcon />
										</li>
									))}
								</ul>
							)}
						</div>
					)}

					{!selectedCities.length && (
						<div className="text-[#E31D1C] text-xs mt-5 px-4">
							Please select at least 1 city to continue
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export { PropertyCitySelectionView };

// import type { SearchListingInterface } from "../interfaces/searchListing";
// import { testExploreHomesData } from "../mocks/searchTestData";
// import type { FilterValues } from "../stores/filterStore";
import { universalFetch } from "./common";

interface SearchListingAddress {
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  state: string;
}

export interface SearchListingInterface {
  listingId: string;
  images?: string[];
  displayImageUrl?: string;
  listPrice: number;
  originalPrice: number;
  lastStatus: string;
  numBathrooms: number;
  numBedrooms: number;
  sqft: number;
  repliersUpdatedOn: string;
  downPaymentContribution?: number;
  downPaymentAssistance?: number;
  liked: boolean;
  address: SearchListingAddress;
  listDate: string;
  map?: {
    type: string;
    coordinates: [number, number];
  };
  coordinates?: { lat: number; long: number };
  displayAddress?: string;
  propertyType: string;
}

// export const fetchExploreLisitings = async (
//   page?: string,
//   limit?: string,
//   sortBy?: string,
//   sortOrder?: string,
//   long?: string,
//   lat?: string,
//   zoom?: string,
//   q?: string,
//   filters?: FilterValues
// ): Promise<SearchListingInterface[]> => {
//   try {
//     const response = (await universalFetch(
//       "/listings?buyerId=66f0cc3e1fd002694d5e2567",
//       "POST"
//       // { filters: filters }
//     )) as any;
//     console.log("fetchExploreProperties response:", response);
//     const properties = response.listings as SearchListingInterface[];
//     return properties;

//     // return testExploreHomesData as SearchListingInterface[];
//   } catch (error) {
//     console.error("Failed fetchLikedProperties", error);
//     throw error;
//   }
// };

const daysOnMarketLabels = [
  { value: "Any", range: [0, Infinity] },
  { value: "0-7 Days", range: [0, 7] },
  { value: "8-14 Days", range: [8, 14] },
  { value: "15-30 Days", range: [15, 30] },
  { value: "30+ Days", range: [30, Infinity] },
];

export const fetchExploreListings = async (
  page?: string,
  limit?: string,
  sortBy?: string,
  sortOrder?: string,
  long?: number,
  lat?: number,
  zoom?: number,
  q?: "",
  filters?: any
): Promise<SearchListingInterface[]> => {
  try {
    let url = "/listings?";

    if (page) {
      url += `&page=${page}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (sortBy) {
      url += `&sortBy=${sortBy}`;
    }
    if (sortOrder) {
      url += `&sortOrder=${sortOrder}`;
    }
    if (long) {
      url += `&long=${long}`;
    }
    if (lat) {
      url += `&lat=${lat}`;
    }
    if (zoom) {
      url += `&zoom=${zoom}`;
    }
    if (q) {
      url += `&q=${q}`;
    }

    // console.log(url);

    let filterBody;

    if (filters) {
      let daysOnMarket;
      let ageOfProperty;
      let styleKey;
      let sqft;
      let basement;
      let garage;
      let kitchens;
      let propertyTypeStrings;

      if (filters.daysOnMarket !== "Any") {
        switch (filters.daysOnMarket) {
          case "0-7 Days":
            daysOnMarket = [0, 7];
            break;
          case "8-14 Days":
            daysOnMarket = [8, 14];
            break;
          case "15-30 Days":
            daysOnMarket = [15, 30];
            break;
          case "30+ Days":
            daysOnMarket = [30, undefined];
            break;
          default:
            break;
        }
      }

      if (filters.ageOfProperty !== "Any") {
        switch (filters.ageOfProperty) {
          case "New (0 - 9 Years)":
            ageOfProperty = [0, 9];
            break;
          case "Average (10-40 Years)":
            ageOfProperty = [10, 40];
            break;
          case "Old (40+ Years)":
            ageOfProperty = [40, undefined];
            break;

          default:
            break;
        }
      }

      if (filters.style !== "Any") {
        switch (filters.style) {
          case "1 1/2 Storey":
          case "1.5 Storey":
            styleKey = "ONE_HALF_STOREY";
            break;
          case "2-Storey":
          case "2 Storey":
          case "Two Story":
            styleKey = "TWO_STOREY";
            break;
          case "2 1/2 Storey":
          case "2.5 Storey":
            styleKey = "TWO_HALF_STOREY";
            break;
          case "3 Storey":
          case "3-Storey":
            styleKey = "THREE_STOREY";
            break;
          case "Apartment":
          case "1 Storey/Apt":
            styleKey = "APARTMENT";
            break;
          case "Backsplit 3":
            styleKey = "BACKSPLIT_THREE";
            break;
          case "Backsplit 4":
            styleKey = "BACKSPLIT_FOUR";
            break;
          case "Backsplit 5":
            styleKey = "BACKSPLIT_FIVE";
            break;
          case "Bungalow":
          case "Bungalow-Raised":
          case "Bungalow Raised":
            styleKey = "BUNGALOW";
            break;
          case "Bungaloft":
            styleKey = "BUNGALOFT";
            break;
          case "Chalet":
            styleKey = "CHALET";
            break;
          case "Contemporary":
            styleKey = "CONTEMPORARY";
            break;
          case "Garden House":
            styleKey = "GARDEN_HOUSE";
            break;
          case "Log":
            styleKey = "LOG";
            break;
          case "Loft":
            styleKey = "LOFT";
            break;
          case "Other":
            styleKey = "OTHER";
            break;
          case "Sidesplit 3":
            styleKey = "SIDESPLIT_THREE";
            break;
          case "Sidesplit 4":
            styleKey = "SIDESPLIT_FOUR";
            break;
          case "Sidesplit 5":
            styleKey = "SIDESPLIT_FIVE";
            break;
          default:
            styleKey = undefined; // or handle the default case as necessary
            break;
        }
      }

      if (filters.sqFt !== "Any") {
        switch (filters.sqFt) {
          case "<500":
            sqft = [0, 500];
            break;
          case "500-1100":
            sqft = [500, 1100];
            break;
          case "1100-1500":
            sqft = [1100, 1500];
            break;
          case "1500+":
            sqft = [1500, undefined];
            break;
          default:
            break;
        }
      }

      if (filters.sqFt !== "Any") {
        switch (filters.sqFt) {
          case "<500":
            sqft = [0, 500];
            break;
          case "500-1100":
            sqft = [500, 1100];
            break;
          case "1100-1500":
            sqft = [1100, 1500];
            break;
          case "1500+":
            sqft = [1500, undefined];
            break;
          default:
            break;
        }
      }

      if (filters.propertyTypes && filters.propertyTypes.length > 0) {
        propertyTypeStrings = filters.propertyTypes.map((property) =>
          property.label.replace(/\s+/g, "_").toUpperCase()
        );
      }

      filterBody = {
        numberOfBedrooms:
          filters.bedCount === "any"
            ? undefined
            : Number.parseInt(filters.bedCount.replace(/\+/g, "")),
        numberOfBathrooms:
          filters.bathCount === "any"
            ? undefined
            : Number.parseInt(filters.bathCount.replace(/\+/g, "")),
        numberOfParkingSpaces:
          filters.parking === "any"
            ? undefined
            : Number.parseInt(filters.parking.replace(/\+/g, "")),
        dens:
          // biome-ignore lint/complexity/noUselessTernary: <explanation>
          filters.denCount !== "no" ? true : false,
        // priceRange:
        //   // biome-ignore lint/complexity/noUselessTernary: <explanation>
        //   [100000, filters.priceRange.maxVal],
        daysOnMarket: daysOnMarket,
        ageOfProperty: ageOfProperty,
        style: styleKey,
        sqft: sqft,
        basement:
          filters.basement !== "Any"
            ? filters.basement.toUpperCase()
            : undefined,
        garage:
          filters.garageType !== "Any"
            ? filters.garageType.toUpperCase()
            : undefined,
        numKitchens:
          filters.numberOfKitchens === "any"
            ? undefined
            : Number.parseInt(filters.numberOfKitchens.replace(/\+/g, "")),
        propertyType: propertyTypeStrings,
      };
    }

    // console.log("filter", filterBody);

    const response = (await universalFetch(url, "POST", {
      ...(filters ? filterBody : {}),
    })) as any;

    // console.log("fetchExploreListings response:", response);
    const properties = response.listings as SearchListingInterface[];
    return properties;
  } catch (error) {
    console.error("Failed fetchExploreListings", error);
    throw error;
  }
};

import "./filterPanelStyles.css";
import { formatPrice } from "../../../utils/formatPrice";
import { useSearchFiltersStore } from "../../../stores/filterStore";
import {
	type ChangeEvent,
	type RefObject,
	useCallback,
	useEffect,
	useRef,
} from "react";

interface PriceSliderProps {
	min: number;
	max: number;
}

interface ValueSliderProps {
	range: RefObject<HTMLDivElement> | undefined;
}

interface RangeInputProps extends PriceSliderProps {
	type: "min" | "max";
	value: number | undefined;
	minVal: number | undefined;
	maxVal: number | undefined;
	setPriceRange: (range: { minVal?: number; maxVal?: number }) => void;
	inputRef: RefObject<HTMLInputElement> | undefined;
	priceRange: { minVal?: number; maxVal?: number };
}

interface ValuesDisplayProps {
	minVal: number | undefined;
	maxVal: number | undefined;
}

const ValueSlider = ({ range }: ValueSliderProps) => {
	return (
		<div className="relative w-full">
			<div className="slider__track" />
			<div ref={range} className="slider__range  absolute" />
		</div>
	);
};

const RangeInput = ({
	type,
	min,
	max,
	value,
	setPriceRange,
	minVal,
	maxVal,
	inputRef,
	priceRange,
}: RangeInputProps) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputValue = +event.target.value;
		const roundedValue = Math.round(inputValue / 25000) * 25000;

		const updatedValue =
			type === "min"
				? Math.min(roundedValue, (maxVal ?? max) - 25000)
				: Math.max(roundedValue, (minVal ?? min) + 25000);

		setPriceRange({
			minVal: type === "min" ? updatedValue : priceRange.minVal,
			maxVal: type === "max" ? updatedValue : priceRange.maxVal,
		});
	};
	return (
		<input
			type="range"
			min={min}
			max={max}
			value={value}
			ref={inputRef}
			onChange={handleChange}
			className={`w-full thumb ${
				type === "min" ? "thumb--zindex-3" : "thumb--zindex-4"
			}`}
			style={{
				zIndex:
					value && (type === "min" ? value > max - 100 : value < min + 100)
						? 5
						: 3,
			}}
		/>
	);
};

const ValuesDisplay = ({ minVal, maxVal }: ValuesDisplayProps) => {
	const formattedMinVal = `$${minVal && formatPrice(minVal)}`;
	const formattedMaxVal = `$${maxVal && formatPrice(maxVal)}`;

	return (
		<div className="value-display-container">
			<div className="value-display">
				<div className="value-display-label" id="min-val-display">
					Minimum
				</div>
				<input
					className="value-display-value"
					id="min-val-display"
					type="text"
					readOnly={true}
					value={formattedMinVal}
				/>
			</div>

			<hr className="w-3/12 border-t-2 border-gray-400" />

			<div className="value-display">
				<div className="value-display-label" id="grid-last-name">
					Maximum
				</div>
				<input
					className="value-display-value"
					id="grid-last-name"
					type="text"
					readOnly={true}
					value={formattedMaxVal}
				/>
			</div>
		</div>
	);
};

const PriceSlider = ({ min, max }: PriceSliderProps) => {
	const { priceRange, setPriceRange } = useSearchFiltersStore();
	const minValRef = useRef(null);
	const maxValRef = useRef(null);
	const range = useRef(null);

	const getPercent = useCallback(
		(value: number) => Math.round(((value - min) / (max - min)) * 100),
		[min, max],
	);

	useEffect(() => {
		if (minValRef?.current && maxValRef?.current && range?.current) {
			const minPercent =
				typeof priceRange.minVal === "number"
					? getPercent(priceRange.minVal)
					: 0;
			const maxPercent =
				typeof priceRange.maxVal === "number"
					? getPercent(priceRange.maxVal)
					: 100;

			if (range?.current.style) {
				range.current.style.left = `${minPercent}%`;
				range.current.style.width = `${Math.max(0, maxPercent - minPercent)}%`;
			}
		}
	}, [priceRange.minVal, priceRange.maxVal, getPercent]);

	useEffect(() => {
		console.log(priceRange);
	}, [priceRange]);

	return (
		<div className="flex flex-col items-center w-full">
			<div className="relative pb-5 w-full max-w-lg mx-auto">
				<RangeInput
					type="min"
					min={min}
					max={max}
					value={priceRange.minVal}
					setPriceRange={setPriceRange}
					minVal={priceRange.minVal}
					maxVal={priceRange.maxVal}
					inputRef={minValRef}
					priceRange={priceRange}
				/>
				<RangeInput
					type="max"
					min={min}
					max={max}
					value={priceRange.maxVal}
					setPriceRange={setPriceRange}
					minVal={priceRange.minVal}
					maxVal={priceRange.maxVal}
					inputRef={maxValRef}
					priceRange={priceRange}
				/>
				<ValueSlider range={range} />
			</div>
			<ValuesDisplay minVal={priceRange.minVal} maxVal={priceRange.maxVal} />
		</div>
	);
};

export { PriceSlider };

import { useState, useEffect, useRef } from "react";
import { useNavigate, useMatches } from "react-router-dom";

interface CartTopBarProps {
  setTopBarHeight: (height: number) => void;
}

const CartTopBar = ({ setTopBarHeight }: CartTopBarProps) => {
  const navigate = useNavigate();
  const matches = useMatches();
  const [activeTab, setActiveTab] = useState("cart");

  useEffect(() => {
    const currentRoute = matches[matches.length - 1];

    if (currentRoute?.pathname.includes("/cart/comparison")) {
      setActiveTab("comparison");
    } else if (currentRoute?.pathname.includes("/cart")) {
      setActiveTab("cart");
    }
  }, [matches]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    if (tab === "cart") {
      navigate("/cart");
    } else if (tab === "comparison") {
      navigate("/cart/comparison");
    }
  };

  const topBarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (topBarRef.current) {
      setTopBarHeight(topBarRef.current.offsetHeight);
    }
  }, [setTopBarHeight]);

  return (
    <div
      ref={topBarRef}
      className={`flex flex-col items-center w-full absolute top-0 bg-white z-10  ${
        window.bridge ? "pt-bridge-inset-top" : "pt-2"
      }`}
    >
      <div className="text-sm font-medium text-center border-b border-[#D6E9FC] w-full">
        <ul className="flex -mb-px justify-center">
          <button
            type="button"
            onClick={() => handleTabChange("cart")}
            className={`inline-block w-full p-4 border-b-2 rounded-t-lg ${
              activeTab === "cart"
                ? "text-z-dark-moss-green border-z-dark-moss-green"
                : "border-[#F2F2F2] text-[#B4B4B4]"
            }`}
          >
            Cart
          </button>

          <button
            type="button"
            onClick={() => handleTabChange("comparison")}
            className={`inline-block p-4 w-full border-b-2 rounded-t-lg ${
              activeTab === "comparison"
                ? "text-z-dark-moss-green border-z-dark-moss-green"
                : "border-[#F2F2F2] text-[#B4B4B4]"
            }`}
          >
            Comparison
          </button>
        </ul>
      </div>
    </div>
  );
};

export { CartTopBar };

import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { openLink } from "../../services/openLink";
import { BellIcon, ChatIcon } from "../../assets/icons";

interface FinanceTopBarProps {
  setTopBarHeight: (height: number) => void;
}

const FinanceTopBar = ({ setTopBarHeight }: FinanceTopBarProps) => {
  const topBarRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (topBarRef.current) {
      setTopBarHeight(topBarRef.current.offsetHeight);
    }
  }, [setTopBarHeight]);

  return (
    <div
      className="absolute inset-x-0 top-0 z-10 pb-4 flex items-center justify-center w-full"
      style={{
        paddingTop: `${
          (window.bridge?.adjustedContentInset?.top ?? 24) + 10
        }px`,
      }}
      ref={topBarRef}
    >
      <h1 className="text-z-shark text-xl font-bold">My Finances</h1>
      <div className="flex items-center absolute right-4 space-x-6">
        <button
          type="button"
          onClick={() =>
            openLink(
              "https://wa.me/12898166416?text=Hello,%20I%20am%20looking%20for%20assistance%20with%20the%20Zown%20mobile%20app.%20Can%20you%20help%20me%20with%20this"
            )
          }
        >
          <ChatIcon />
        </button>
        <img
          src={BellIcon}
          alt="bell icon"
          onClick={() => navigate("/profile/notification")}
          onKeyDown={() => navigate("/profile/notification")}
        />
      </div>
    </div>
  );
};

export { FinanceTopBar };

import { Input } from "../common";
import { type ChangeEvent, useRef } from "react";
import { formatPrice } from "../../utils/formatPrice";
import { handleKeyPress } from "../../utils/keyboardUtils";
import DollarIcon from "../../assets/icons/dollarIcon.svg";
import { useInputFlowStore } from "../../stores/inputFlowStore";

const MonthlyDebtInputView = () => {
	const { debt, setDebt } = useInputFlowStore();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^\d]/g, "");
		setDebt(formatPrice(value || 0));
	};

	return (
		<div className="space-y-10 px-4" onKeyDown={(e) => handleKeyPress(e, inputRef)}>
			<h2 className="text-z-shark text-2xl font-normal">
				To help us calculate your buying power, please share your estimated
				monthly debt payments (e.g., credit cards, loans, car payments).
			</h2>
			<h3 className="text-z-dark-moss-green text-2xl font-bold">
				How much do you pay monthly on debt?
			</h3>
			<Input
				inputType="tel"
				ref={inputRef}
				value={debt}
				onChange={handleChange}
				startIcon={DollarIcon}
				radius="20px"
				label="Estimated Monthly Debt"
			/>
		</div>
	);
};

export { MonthlyDebtInputView };

import { MainLayout } from "./layouts/main";
import { LoginLayout } from "./layouts/login";
import { ErrorView } from "./components/errorView";
import type { RouteObject } from "react-router-dom";
import { ListingView } from "./components/ListingView/listingView";
import { ShowingView } from "./components/ShowingView/showingView";
import { ProfileView } from "./components/ProfileView/profileView";
import { BuyerMainView } from "./components/BuyerMainView/buyerMainView";
import { LikedHomesView } from "./components/LikedHomesView/likedHomesView";
import { LoginEmailView } from "./components/LoginEmailView/loginEmailView";
import { LoginVerifyView } from "./components/LoginVerifyView/loginVerifyView";
import { NotificationView } from "./components/NotificationView/notificationView";
import { ProfileOverviewView } from "./components/ProfileOverviewView/profileOverviewView";
import { PreApprovalDocsUploadView } from "./components/PreApprovalDocsUploadView/preApprovalDocsUploadView";
import { GetPreApprovedOverviewView } from "./components/GetPreApprovedOverviewView/getPreApprovedOverviewView";
import { GetPreApprovedEmailInputView } from "./components/GetPreApprovedEmailInputView/getPreApprovedEmailInputView";
import { GetPreApprovedCheckInboxView } from "./components/GetPreApprovedCheckInboxView/getPreApprovedCheckInboxView";
import { OwnershipIntroView } from "./components/HeroHomeOwnershipView/ownershipIntroView";
import { DetailsInputView } from "./components/DetailsInputView/detailsInputView";
import { SavingInputView } from "./components/SavingInputView/savingInputView";
import { BuyerInputFlow } from "./layouts/buyerInputFlow";
import { ImagesView } from "./components/ImagesView/imagesView";
import { AnnualIncomeInputView } from "./components/AnnualIncomeInputView/annualIncomeInputView";
import { MonthlyDebtInputView } from "./components/MonthlyDebtInputView/monthlyDebtInputView";
import { AffordabilityCheckView } from "./components/AffordabilityCheckView/affordabilityCheckView";
import { PropertyPreferencesView } from "./components/PropertyPreferencesView/propertyPreferencesView";
import { PropertySearchingView } from "./components/PropertySearchingView/propertySearchingView";
import { PropertyMatchOverviewView } from "./components/PropertyMatchOverviewView/propertyMatchOverviewView";
import { PropertyCitySelectionView } from "./components/PropertyCitySelectionView/propertyCitySelectionView";
import { PointsOfInterestView } from "./components/PointsOfInterestView/pointsOfInterestView";
import { CartView } from "./components/CartView/cartView";
import { CartComparison } from "./components/CartComparison/cartComparison";
import { ShowingDateSelectionView } from "./components/ShowingDateSelectionView/showingDateSelectionView";
import { LifestyleView } from "./components/LifestyleView/lifestyleView";
import { NeedsVsWantsView } from "./components/NeedsVsWantsView/needsVsWantsView";
import { DetailsConfirmationView } from "./components/DetailsConfirmationView/detailsConfirmationView";
import { ShowingTimeSelectionView } from "./components/ShowingTimeSelectionView/showingTimeSelectionView";
import { ContractView } from "./components/ContractView/contractView";
import { BookingSuccessView } from "./components/BookingSuccessView/bookingSuccessView";
import { PrioritiesView } from "./components/PrioritiesView/prioritiesView";
import { BuyerExploreListingsView } from "./components/BuyerExploreListingsView/buyerExploreListingsView";
import { DeleteAccountView } from "./components/DeleteAccountView/deleteAccountView";
import { DeleteAccountMissYouView } from "./components/deleteAccountMissYouView";
import { CheckoutCartPropertiesView } from "./components/CheckoutCartPropertiesView/checkoutCartPropertiesView";
import { BuyerSearchView } from "./components/BuyerSearchView/buyerSearchView";
import { SubRouteLayout } from "./layouts/subRouteLayout";
import { ComparativeMarketAnalysisView } from "./components/ComparativeMarketAnalysisView/comparativeMarketAnalysisView";
import { FinanceView } from "./components/Finances/financeView";
import { PickCity } from "./components/PickCity/pickCity";
import { PointsInterest } from "./components/PointsInterest/pointsInterest";
import { LifeStyle } from "./components/LifeStyle/lifeStyle";
import { NeedsWants } from "./components/NeedsWants/needsWants";
import { Prioritize } from "./components/Prioritize/prioritize";
import { WelcomeView } from "./components/WelcomeView/welcomeViw";
import { AuthLayout } from "./layouts/authLayout";

export const routes = (): RouteObject[] => {
  return [
    {
      path: "/login",
      element: <AuthLayout type="public" />,
      children: [
        {
          path: "",
          element: <WelcomeView />,
          errorElement: <ErrorView />,
        },
        {
          path: "",
          element: <LoginLayout />,
          children: [
            {
              path: "email-view",
              element: <LoginEmailView />,
              errorElement: <ErrorView />,
            },
            {
              path: "verify-view",
              element: <LoginVerifyView />,
              errorElement: <ErrorView />,
            },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <AuthLayout type="private" />,
      children: [
        {
          path: "/",
          element: <MainLayout />,
          errorElement: <ErrorView />,
          children: [
            {
              path: "/",
              element: <BuyerMainView />,
              errorElement: <ErrorView />,
            },

            {
              path: "/explore",
              element: <BuyerExploreListingsView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile",
              element: <ProfileView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/cart",
              element: <CartView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/cart/comparison",
              element: <CartComparison />,
              errorElement: <ErrorView />,
            },

            {
              path: "/finances",
              element: <FinanceView />,
              errorElement: <ErrorView />,
            },
          ],
        },
        {
          path: "/",
          element: <SubRouteLayout />,
          children: [
            {
              path: "/profile/prioritize",
              element: <Prioritize />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/life-style",
              element: <LifeStyle />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/needs-wants",
              element: <NeedsWants />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/pick-city",
              element: <PickCity />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/points-interest",
              element: <PointsInterest />,
              errorElement: <ErrorView />,
            },
            {
              path: "/search",
              element: <BuyerSearchView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/showing",
              element: <ShowingView filterStatus={"upcoming"} />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/showing/completed",
              element: <ShowingView filterStatus={"completed"} />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/notification",
              element: <NotificationView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/delete-account",
              element: <DeleteAccountView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/delete-account/miss-you",
              element: <DeleteAccountMissYouView />,
              errorElement: <ErrorView />,
            },

            {
              path: "/profile/overview",
              element: <ProfileOverviewView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/liked-homes",
              element: <LikedHomesView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/upload-preapproved",
              element: <PreApprovalDocsUploadView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/get-preapproved",
              element: <GetPreApprovedOverviewView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/get-preapproved/email-view",
              element: <GetPreApprovedEmailInputView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/profile/get-preapproved/check-inbox",
              element: <GetPreApprovedCheckInboxView />,
              errorElement: <ErrorView />,
            },

            {
              path: "/cart/checkout-cart-properties",
              element: <CheckoutCartPropertiesView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/cart/date-selection",
              element: <ShowingDateSelectionView />,
              errorElement: <ErrorView />,
            },

            {
              path: "/cart/time-selection",
              element: <ShowingTimeSelectionView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/cart/details-confirmation",
              element: <DetailsConfirmationView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/cart/contract",
              element: <ContractView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/cart/booking-success",
              element: <BookingSuccessView />,
              errorElement: <ErrorView />,
            },
            {
              path: "/listing/:zlid/cma",
              element: <ComparativeMarketAnalysisView />,
              errorElement: <ErrorView />,
            },
          ],
        },
        {
          path: "/listing/:zlid",
          element: <ListingView />,
          errorElement: <ErrorView />,
        },
        {
          path: "/listing/:zlid/images",
          element: <ImagesView />,
          errorElement: <ErrorView />,
        },

        {
          path: "/ownership",
          element: <OwnershipIntroView />,
        },
      

        {
          path: "/buyer-input-flow",
          element: <BuyerInputFlow />,
          children: [
            {
              path: "/buyer-input-flow/details-input",
              element: <DetailsInputView />,
            },
            {
              path: "/buyer-input-flow/saving",
              element: <SavingInputView />,
            },
            {
              path: "/buyer-input-flow/annual-income",
              element: <AnnualIncomeInputView />,
            },
            {
              path: "/buyer-input-flow/monthly-debt",
              element: <MonthlyDebtInputView />,
            },
            {
              path: "/buyer-input-flow/affordability",
              element: <AffordabilityCheckView />,
            },
            {
              path: "/buyer-input-flow/property-preferences",
              element: <PropertyPreferencesView />,
            },
            {
              path: "/buyer-input-flow/property-searching",
              element: <PropertySearchingView />,
            },
            {
              path: "/buyer-input-flow/city-selection",
              element: <PropertyCitySelectionView />,
            },
            {
              path: "/buyer-input-flow/property-match",
              element: <PropertyMatchOverviewView />,
            },
            {
              path: "/buyer-input-flow/points-interest",
              element: <PointsOfInterestView />,
            },
            {
              path: "/buyer-input-flow/lifestyle",
              element: <LifestyleView />,
            },
            {
              path: "/buyer-input-flow/needs-wants",
              element: <NeedsVsWantsView />,
            },
            {
              path: "/buyer-input-flow/priorities",
              element: <PrioritiesView />,
            },
          ],
        },
      ],
    },
  ];
};

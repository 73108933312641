import { universalFetch } from "./common";
// import { showingData } from "../mocks/showingData";
import { reorderProperties } from "../mocks/reorderProperties";
import type { BuyerOuting } from "../interfaces/outingInterface";
import type { CartProperties } from "../interfaces/cartProperties";
// import { cartPropertiesData } from "../mocks/cartPropertiesData";
// import { comparisonProperties } from "../mocks/comparisonPropertiesData";
import type { CartComparisonProperty } from "../interfaces/cartComparison";

export const fetchCartComparisonProperties = async (): Promise<
  CartComparisonProperty[]
> => {
  try {
    const response = await universalFetch("/cart/comparison", "GET");
    console.log("fetchOuting response:", response);
    return response as CartComparisonProperty[];
  } catch (error) {
    console.error("Failed fetchCartComparisonProperties", error);
    throw error;
  }
};

export const fetchCartProperties = async (): Promise<CartProperties[]> => {
  try {
    const response = await universalFetch("/cart", "GET");

    return response as CartProperties[];
  } catch (error) {
    console.error("Failed fetchCartProperties", error);
    throw error;
  }
};

export const fetchOuting = async (): Promise<BuyerOuting[]> => {
  try {
    const response = await universalFetch("/outings", "GET");

    return response.outings as BuyerOuting[];
  } catch (error) {
    console.error("Failed fetchShowings", error);
    throw error;
  }
};

export const fetchReorderProperties = async () => {
  try {
    // const response = await universalFetch("/outings", "GET");
    // console.log("fetchOuting response:", response);
    // const outings = (response as FetchOutingResponse).outings;
    return reorderProperties;
  } catch (error) {
    console.error("Failed fetchShowings", error);
    throw error;
  }
};

export const postReorderProperties = async (payload: {
  showingsCart: string[];
  saveForLater: string[];
}) => {
  try {
    // const response = await universalFetch("/outings", "GET");
    // console.log("fetchOuting response:", response);
    // const outings = (response as FetchOutingResponse).outings;
    // return reorderProperties;
    console.log(payload);
  } catch (error) {
    console.error("Failed fetchShowings", error);
    throw error;
  }
};

export const CreateBuyerOutingInput = async (
  showingDates: { startAt: Date; endAt: Date }[],
  listingIds: string[]
) => {
  try {
    const response = await universalFetch("/outings", "POST", {
      outing: {
        showingDates,
        listingIds,
      },
    });
    console.log("CreateBuyerOutingInput response", response);
    return response;
  } catch (error) {
    console.error("Failed to CreateBuyerOutingInput", error);
    throw error;
  }
};

export const removeFromCart = async (listingId: string) => {
  try {
    const response = await universalFetch(`/cart/${listingId}`, "DELETE");
    console.log("CreateBuyerOutingInput response", response);
    return response;
  } catch (error) {
    console.error("Failed to RemoveFromCart", error);
    throw error;
  }
};

import clsx from "clsx";
import { useEffect, useState } from "react";
import { Button } from "../components/common";
import { PhoneValidator } from "../utils/phoneValidator";
import { updatePreferences } from "../services/preferences";
import { useInputFlowStore } from "../stores/inputFlowStore";
import { useUserProfileStore } from "../stores/profileStore";
import type { MainProfile } from "../interfaces/mainProfile";
import { ProgressBar } from "../components/common/progressBar";
import { capitalizeFirstLetter } from "../utils/capitalizeLetter";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { usePropertyCityStore } from "../stores/propertyCitySelection";
import { convertToNumberAndMultiplyBy100 } from "../utils/convertAmount";
import { useBuyerFiltersStore } from "../stores/propertyPreferencesStore";
import { updateAccountData, updateFinancesData } from "../services/account";
import { usePropertySearchingStore } from "../stores/propertySearchingStore";
import { usePointsInterest } from "../components/PointsInterest/hook/pointsInterest";

import {
	useHomeFeaturesStore,
	useStep,
} from "../stores/buyerHomeFeaturesStore";
import { BuyerMatchingPreferencesPoiTypeEnum } from "../interfaces/buyerMatchingPreferences";
import { useMatchFiltersStore } from "../stores/filterStore";

const PROGRESS_STEP_MAP: { [key: number]: number } = {
	5: 10,
	6: 30,
	7: 40,
	9: 50,
	10: 70,
	11: 85,
	12: 100,
};
const INDICES_STEPS = Object.keys(PROGRESS_STEP_MAP).map(Number);

const BuyerInputFlow = () => {
	const [progressBarHeight, setProgressBarHeight] = useState(0);
	const [footerHeight, setFooterHeight] = useState(0);
	const { currentStep, setCurrentStep } = useStep();
	const showProgressBar = INDICES_STEPS.includes(currentStep);
	const isAffordability = [4].includes(currentStep);
	const isPropertyMatch = [8].includes(currentStep);
	const number = PROGRESS_STEP_MAP[currentStep] || 0;
	const dynamicPaddingTop = showProgressBar ? `${progressBarHeight}px` : "30px";
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const dynamicPaddingBottom = `${footerHeight}px`;
	const {
		debt,
		savings,
		income,
		firstName,
		lastName,
		phoneNumber,
		// validateDetailsInputForm,
	} = useInputFlowStore();
	const { mustHaves, niceHaves, selectedLifestyles, selectedProperties } =
		usePropertySearchingStore();
	const { selectedCities } = usePropertyCityStore();
	const { setUserProfile } = useUserProfileStore();

	const {
		matchBedCount,
		matchBathCount,
		matchParking,
		matchDenCount,

		setMatchBedCount,
		setMatchBathCount,
		setMatchParking,
		setMatchDenCount,
	} = useMatchFiltersStore();
	// const {
	// 	selectedLifestyle,
	// 	selectedAddresses,
	// 	selectedStation,
	// 	selectedWorkAddress,
	// 	selectedSchool,
	// 	placeWorship,
	// } = useHomeFeaturesStore();

	const {
		selectedAddresses,

		placeWorship,
		selectedStation,
		selectedSchool,
		selectedWorkAddress,
	} = usePointsInterest();

	const {
		selectedStationLat,
		selectedStationLon,
		selectedSchoolLat,
		selectedSchoolLon,
		selectedWorkLat,
		selectedWorkLon,
	} = useHomeFeaturesStore();

	const navigate = useNavigate();
	const location = useLocation();

	const handleSubmit = async () => {
		const accountPayload: Partial<MainProfile> = {};

		if (firstName) {
			accountPayload.firstName = capitalizeFirstLetter(firstName);
		}
		if (lastName) {
			accountPayload.lastName = capitalizeFirstLetter(lastName);
		}
		if (phoneNumber) {
			accountPayload.phoneNumber = PhoneValidator.formatE164(phoneNumber, "CA");
		}

		const financesPayload = {
			totalIncome: {
				amount: convertToNumberAndMultiplyBy100(income),
				currency: "CAD",
			},
			totalSavings: {
				amount: convertToNumberAndMultiplyBy100(savings),
				currency: "CAD",
			},
			totalMonthlyDebts: {
				amount: convertToNumberAndMultiplyBy100(debt),
				currency: "CAD",
			},
		};

		try {
			setButtonDisabled(true);
			const financesResponse = await updateFinancesData(financesPayload);

			if (Object.keys(accountPayload).length > 0) {
				const accountResponse = await updateAccountData(accountPayload);
				setUserProfile(financesResponse);
				setUserProfile(accountResponse);
			} else {
				setUserProfile(financesResponse);
			}
			setButtonDisabled(false);
		} catch (error) {
			console.error("Error updating data:", error);
			setButtonDisabled(false);
		}
	};

	const handleNextStep = async () => {
		switch (location.pathname) {
			case "/buyer-input-flow/details-input":
				// const isValid = validateDetailsInputForm();
				// if (isValid) {
				// 	console.log(firstName, lastName, phoneNumber);
				// 	navigate("/buyer-input-flow/annual-income");
				// }

				navigate("/buyer-input-flow/annual-income");
				break;
			case "/buyer-input-flow/annual-income": {
				console.log(income);
				navigate("/buyer-input-flow/saving");
				break;
			}
			case "/buyer-input-flow/saving": {
				console.log(savings);
				navigate("/buyer-input-flow/monthly-debt");
				break;
			}
			case "/buyer-input-flow/monthly-debt": {
				console.log(debt);
				setCurrentStep(4);
				navigate("/buyer-input-flow/affordability");
				break;
			}
			case "/buyer-input-flow/affordability": {
				handleSubmit();
				setCurrentStep(5);
				navigate("/");
				break;
			}
			case "/buyer-input-flow/property-preferences": {
				setCurrentStep(6);
				const mapValue = (value: string) =>
					value === "5+" ? 5 : Number(value);

				const numberOfBedrooms =
					matchBedCount && matchBedCount.length > 0
						? matchBedCount.every((value) => value === "Any")
							? null
							: matchBedCount.map(mapValue)
						: undefined;

				const numberOfBathrooms =
					matchBathCount && matchBathCount.length > 0
						? matchBathCount.every((value) => value === "Any")
							? null
							: matchBathCount.map(mapValue)
						: undefined;

				const numberOfParkingSpaces =
					matchParking && matchParking.length > 0
						? matchParking.every((value) => value === "Any")
							? null
							: matchParking.map(mapValue)
						: undefined;

				const mappedFilters = {
					numberOfBedrooms,
					numberOfBathrooms,
					numberOfParkingSpaces,
					dens: matchDenCount !== null ? matchDenCount : undefined,
				};

				try {
					setButtonDisabled(true);
					await updatePreferences(undefined, undefined, mappedFilters);
					setButtonDisabled(false);
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}
				setButtonDisabled(false);
				navigate("/buyer-input-flow/property-searching");
				break;
			}
			case "/buyer-input-flow/property-searching": {
				setCurrentStep(7);

				const mappedFilters = {
					propertyType:
						selectedProperties && selectedProperties.length > 0
							? selectedProperties
							: undefined,
				};
				try {
					setButtonDisabled(true);

					await updatePreferences(undefined, undefined, mappedFilters);
					setButtonDisabled(false);
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}

				navigate("/buyer-input-flow/city-selection");
				break;
			}
			case "/buyer-input-flow/city-selection": {
				setCurrentStep(8);

				const mappedFilters = {
					selectedCities:
						selectedCities && selectedCities.length > 0
							? selectedCities
							: undefined,
				};
				try {
					setButtonDisabled(true);

					await updatePreferences(undefined, undefined, mappedFilters);
					setButtonDisabled(false);
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}

				navigate("/buyer-input-flow/property-match");
				break;
			}
			case "/buyer-input-flow/property-match": {
				setCurrentStep(9);

				navigate("/buyer-input-flow/points-interest");
				break;
			}
			case "/buyer-input-flow/points-interest": {
				setCurrentStep(10);
				const pointsOfInterests = [];

				if (selectedSchool && selectedSchoolLat && selectedSchoolLon) {
					pointsOfInterests.push({
						designation: selectedSchool,
						location: {
							type: "Point",
							coordinates: [selectedSchoolLon, selectedSchoolLat],
						},
						type: BuyerMatchingPreferencesPoiTypeEnum.School,
					});
				}

				if (selectedStation && selectedStationLat && selectedStationLon) {
					pointsOfInterests.push({
						designation: selectedStation,
						location: {
							type: "Point",
							coordinates: [selectedStationLon, selectedStationLat],
						},
						type: BuyerMatchingPreferencesPoiTypeEnum.PublicTransit,
					});
				}

				if (selectedWorkAddress && selectedWorkLat && selectedWorkLon) {
					pointsOfInterests.push({
						designation: selectedWorkAddress,
						location: {
							type: "Point",
							coordinates: [selectedWorkLon, selectedWorkLat],
						},
						type: BuyerMatchingPreferencesPoiTypeEnum.Work,
					});
				}

				if (placeWorship) {
					pointsOfInterests.push({
						designation: placeWorship,
						type: BuyerMatchingPreferencesPoiTypeEnum.PlaceOfWorship,
					});
				}

				for (const address of selectedAddresses) {
					pointsOfInterests.push({
						designation: address.name,
						location: {
							type: "Point",
							coordinates: [address.longitude, address.latitude],
						},
						type: BuyerMatchingPreferencesPoiTypeEnum.Other,
					});
				}

				try {
					setButtonDisabled(true);
					await updatePreferences(pointsOfInterests, undefined, undefined);
					setButtonDisabled(false);
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}

				navigate("/buyer-input-flow/lifestyle");
				break;
			}
			case "/buyer-input-flow/lifestyle": {
				setCurrentStep(11);
				try {
					setButtonDisabled(true);
					await updatePreferences(
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
						selectedLifestyles,
					);
					setButtonDisabled(false);
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}
				navigate("/buyer-input-flow/needs-wants");
				break;
			}
			case "/buyer-input-flow/needs-wants": {
				try {
					setButtonDisabled(true);
					await updatePreferences(
						undefined,
						undefined,
						undefined,
						mustHaves,
						niceHaves,
					);
					setButtonDisabled(false);
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}
				setCurrentStep(12);

				navigate("/buyer-input-flow/priorities");
				break;
			}
			case "/buyer-input-flow/priorities": {
				setCurrentStep(13);
				try {
					setButtonDisabled(true);
					await updatePreferences(undefined, niceHaves);
					setButtonDisabled(false);
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}
				navigate("/");
				break;
			}
			default:
				break;
		}
	};

	const handlePreviousStep = () => {
		switch (location.pathname) {
			case "/buyer-input-flow/priorities":
				setCurrentStep(11);
				break;
			case "/buyer-input-flow/needs-wants":
				setCurrentStep(10);
				break;
			case "/buyer-input-flow/lifestyle":
				setCurrentStep(9);
				break;
			case "/buyer-input-flow/points-interest":
				setCurrentStep(8);
				break;
			case "/buyer-input-flow/property-match":
				setCurrentStep(7);
				break;
			case "/buyer-input-flow/city-selection":
				setCurrentStep(6);
				break;
			case "/buyer-input-flow/property-searching":
				setCurrentStep(5);
				break;
			case "/buyer-input-flow/property-preferences":
				setCurrentStep(4);
				break;
			case "/buyer-input-flow/affordability":
				setCurrentStep(3);
				break;
			case "/buyer-input-flow/monthly-debt":
				setCurrentStep(2);
				break;
			case "/buyer-input-flow/annual-income":
				setCurrentStep(1);
				break;
			case "/buyer-input-flow/saving":
				break;
			case "/buyer-input-flow/details-input":
				break;
			default:
				break;
		}
		navigate(-1);
	};

	const dynamicNextButton = () => {
		if (isAffordability) {
			return "Continue";
		}
		if (isPropertyMatch) {
			return "Get Started";
		}
		return "Next";
	};

	useEffect(() => {
		const progressBarEl = document.getElementById("progress-bar");
		const footerEl = document.getElementById("footer");

		if (progressBarEl) {
			setProgressBarHeight(progressBarEl.offsetHeight);
		}
		if (footerEl) {
			setFooterHeight(footerEl.offsetHeight);
		}
	}, []);

	return (
		<div
			className={clsx(" overflow-auto absolute  inset-0")}
			style={{
				paddingTop: dynamicPaddingTop,
				paddingBottom: dynamicPaddingBottom,
			}}
		>
			{showProgressBar && (
				<div
					id="progress-bar"
					className={`fixed ${
						window.bridge ? "top-bridge-inset-top" : "top-0"
					}  bg-white h-20 w-full z-10`}
				>
					<ProgressBar totalSteps={number} />
				</div>
			)}
			<div
				className={clsx(
					"relative flex justify-start h-full overflow-auto items-center pt-bridge-inset-top pb-bridge-inset-bottom",
					isPropertyMatch && "!mx-0",
					!showProgressBar && "h-full",
				)}
			>
				<Outlet />
			</div>
			<div
				id="footer"
				className={`fixed bottom-0 right-0 left-0 flex items-center ${
					window.bridge ? "pb-bridge-inset-bottom pt-3" : "py-4"
				} justify-between px-4 bg-white border-t border-[#F2F2F2] w-full`}
			>
				{currentStep !== 4 ? (
					<Button
						onClick={handlePreviousStep}
						disable={buttonDisabled}
						variant="primary"
						customClassName="font-bold text-lg bg-white"
						height="lg"
						isOutlined={true}
						width="120px"
						radius="60px"
					>
						Back
					</Button>
				) : (
					<div />
				)}
				<Button
					onClick={handleNextStep}
					variant="primary"
					customClassName="font-bold text-lg min-w-[120px] shadow-md"
					height="lg"
					width="fit"
					radius="60px"
					disable={
						buttonDisabled ||
						(currentStep === 0 && !(firstName && lastName && phoneNumber)) ||
						(currentStep === 1 && !income) ||
						(currentStep === 6 && selectedProperties.length === 0) ||
						(currentStep === 7 && selectedCities.length === 0) ||
						(currentStep === 10 && selectedLifestyles.length < 3) ||
						(currentStep === 11 &&
							(mustHaves.length === 0 || niceHaves.length === 0))
					}
				>
					{dynamicNextButton()}
				</Button>
			</div>
		</div>
	);
};

export { BuyerInputFlow };

import { type RefObject, useEffect } from "react";

const createButtonListener =
  (
    love: boolean,
    onSwipeLeft: (card: HTMLDivElement) => void,
    onSwipeRight: (card: HTMLDivElement) => void,
    initCards: () => void
  ) =>
  (event: MouseEvent) => {
    const cards = document.querySelectorAll(".swiper--card:not(.removed)");
    const moveOutWidth = document.body.clientWidth * 1.5;

    if (!cards.length) {
      return;
    }

    const card = cards[0] as HTMLDivElement;
    const loveLabel = card.querySelector<HTMLDivElement>(".love-label");
    const nopeLabel = card.querySelector<HTMLDivElement>(".nope-label");

    card.classList.add("removed");

    if (love) {
      if (loveLabel) {
        loveLabel.style.opacity = "1";
      }
      if (nopeLabel) {
        nopeLabel.style.opacity = "0";
      }
      card.style.transform = `translate(${moveOutWidth}px, -100px) rotate(-30deg)`;
      onSwipeRight(card);
    } else {
      if (nopeLabel) {
        nopeLabel.style.opacity = "1";
      }
      if (loveLabel) {
        loveLabel.style.opacity = "0";
      }
      card.style.transform = `translate(-${moveOutWidth}px, -100px) rotate(30deg)`;
      onSwipeLeft(card);
    }

    card.addEventListener("transitionend", () => {
      card.remove();
      initCards();
    });

    event.preventDefault();
  };

export const useButtonListeners = (
  nopeButtonRef: RefObject<HTMLButtonElement>,
  loveButtonRef: RefObject<HTMLButtonElement>,
  initCards: () => void,
  onSwipeLeft: (card: HTMLDivElement) => void,
  onSwipeRight: (card: HTMLDivElement) => void
) => {
  useEffect(() => {
    const nopeButton = nopeButtonRef.current;
    const loveButton = loveButtonRef.current;

    if (nopeButton && loveButton) {
      const nopeListener = createButtonListener(
        false,
        onSwipeLeft,
        onSwipeRight,
        initCards
      );
      const loveListener = createButtonListener(
        true,
        onSwipeLeft,
        onSwipeRight,
        initCards
      );

      nopeButton.addEventListener("click", nopeListener);
      loveButton.addEventListener("click", loveListener);

      return () => {
        nopeButton.removeEventListener("click", nopeListener);
        loveButton.removeEventListener("click", loveListener);
      };
    }

    return undefined;
  }, [nopeButtonRef, loveButtonRef, initCards, onSwipeLeft, onSwipeRight]);
};

import { LoadingCircle } from "../common";
import { useCallback, useEffect, useState } from "react";
import { NotificationSection } from "./notifiactionSection";
import type { NotificationItem } from "../../interfaces/notifications";
import {
  clearNotifications,
  fetchNotifications,
} from "../../services/notifications";

const NotificationView = () => {
  const [notificationItems, setNotificationItems] = useState<
    NotificationItem[]
  >([]);
  const [loading, setLoading] = useState(false);

  const fetchListingData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchNotifications();
      setNotificationItems(response);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch listing data", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchListingData();
  }, [fetchListingData]);

  const handleClearNotifications = async () => {
    try {
      await clearNotifications();
      setNotificationItems([]);
    } catch (error) {
      console.error("Failed to clear notifications", error);
    }
  };

  return (
    <div
      className={`flex flex-col w-full space-y-10 text-black px-2 pt-2 pb-6 ${
        loading || notificationItems.length === 0 ? "h-full" : ""
      }`}
    >
      {loading && (
        <div className="h-full flex justify-center items-center">
          <LoadingCircle />
        </div>
      )}

      {!loading && notificationItems.length === 0 && (
        <div className="flex justify-center items-center text-center h-full font-bold text-xl">
          You're all caught up!
          <br /> There are no new notifications at the moment.
        </div>
      )}
      {!loading && notificationItems.length !== 0 && (
        <>
          <NotificationSection
            sectionTitle="New Activity"
            items={notificationItems}
          />
          <div className="flex justify-center w-full">
            <button
              type="button"
              className="bg-[#FF5757] rounded-full w-[278px] text-white font-bold text-[15px] py-2.5"
              onClick={handleClearNotifications}
            >
              Clear notifications
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export { NotificationView };

import type { Libraries } from "@react-google-maps/api";

export const GOOGLE_MAPS_API_KEY = "AIzaSyD1jxU3CIzQBqgNPJYqoaX56TItnH1SsYQ";
export const LIBRARIES: Libraries = ["places", "marker", "geometry"];

export const loaderOptions = {
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  libraries: LIBRARIES,
  version: "weekly",
  language: "en",
  region: "US",
};

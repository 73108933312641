import { useState } from "react";
import { Button } from "../common";
import { useNavigate } from "react-router-dom";
import { Bars3BottomLeft } from "../../assets/icons";
import { useFilterPanelStore } from "../../stores/filterStore";
import { usePropertySearchingStore } from "../../stores/propertySearchingStore";
import {
	DragDropContext,
	Draggable,
	Droppable,
	type DropResult,
} from "@hello-pangea/dnd";
import { updatePreferences } from "../../services/preferences";

const Prioritize = () => {
	const { showFilterPanel } = useFilterPanelStore();

	const navigate = useNavigate();

	const { niceHaves, filteredItems } = usePropertySearchingStore();
	const [priorities, setPriorities] = useState<string[]>([...niceHaves]);

	const allItems = filteredItems();

	const mapTypeToDisplayName = (type: string) => {
		const foundItem = allItems.find((item) => item.type === type);

		return foundItem ? foundItem.displayName : type;
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const reorderedPriorities = Array.from(priorities);
		const [removed] = reorderedPriorities.splice(result.source.index, 1);
		reorderedPriorities.splice(result.destination.index, 0, removed);

		setPriorities(reorderedPriorities);
	};

	const handleSave = async () => {
		await updatePreferences(undefined, niceHaves);
		showFilterPanel();
		navigate("/profile");
	};

	return (
		<div className="px-4 pt-2 absolute inset-0 text-z-shark">
			<h2 className="text-z-shark text-2xl font-bold mb-3">
				Prioritize the things that matter the most to you
			</h2>
			<span className="text-z-shark text-sm">
				Drag the items to rearrange them
			</span>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="priorities">
					{(provided) => (
						<ul
							{...provided.droppableProps}
							ref={provided.innerRef}
							className="mt-4 space-y-4"
						>
							{priorities.map((priority, index) => (
								<Draggable key={priority} draggableId={priority} index={index}>
									{(provided) => (
										<li
											ref={provided.innerRef}
											{...provided.draggableProps}
											className="h-11 rounded bg-white flex items-center"
										>
											<span className="mr-2 text-z-shark">{index + 1}.</span>
											<div className="bg-[#F9F9F9] rounded-xl w-full h-full items-center flex justify-between px-5">
												<span className="font-semibold text-z-shark">
													{mapTypeToDisplayName(priority)}
												</span>
												<div
													{...provided.dragHandleProps}
													className="w-10 flex items-end justify-end"
												>
													<img
														src={Bars3BottomLeft}
														alt="bar bottom left icon"
													/>
												</div>
											</div>
										</li>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>
			<Button
				customClassName={`text-lg font-semibold absolute left-1/2 -translate-x-1/2 ${
					window.bridge ? "bottom-bridge-inset-bottom" : "bottom-5"
				}`}
				radius="60px"
				width="330px"
				height="md"
				onClick={handleSave}
			>
				Save
			</Button>
		</div>
	);
};

export { Prioritize };

import { create } from "zustand";

interface PropertyCitySelectionStore {
  selectedCities: string[];
  setSelectedCities: (citeName: string) => void;
}

const usePropertyCityStore = create<PropertyCitySelectionStore>((set, get) => ({
  selectedCities: [],
  setSelectedCities: (cityName) => {
    const { selectedCities } = get();
    if (cityName && typeof cityName === "string") {
      if (!selectedCities.includes(cityName)) {
        set({
          selectedCities: [...selectedCities, cityName],
        });
      }
    } else {
      console.warn("Invalid city name:", cityName);
    }
  },
}));

export { usePropertyCityStore };

import { useEffect, useRef, useState } from "react";
import { CheckMarkerIcon, InfoIcon, LockIcon } from "../../../assets/icons";
import { Button } from "../../common";
import { useFinanceStore } from "../../../stores/financeStore";
import { EditIcon } from "../../../assets/FinancesImg";
import { useModalStore } from "../../../stores/handleModalStore";
import { formatPrice } from "../../../utils/formatPrice";

const StepThree = () => {
	const {setHomeBuyingPlanModal} = useModalStore()
	const [homeGoals, setHomeGoals] = useState(false);
	const {
		completedSteps,
		totalPriceCurrentQualification,
		completeStep,
		setBasedBuyingGoal,
		resetSteps,
		buyingHomeData,
		planSpendData,
		whenBuyHomeData,
	} = useFinanceStore();
	const {setBuyingHomeState , setPlanSpendHomeState , setWhenBuyHomeState} = useModalStore()

	const [validHomeGoal, setValidHomeGoal] = useState(false);

	useEffect(() => {
		if (
			whenBuyHomeData !== "Set goal" &&
			planSpendData !== "Set goal" &&
			buyingHomeData.length !== 0
		) {
			setValidHomeGoal(true);
		} else {
			setValidHomeGoal(false);
		}
	}, [whenBuyHomeData, planSpendData, buyingHomeData]);

	const estimatedRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (estimatedRef.current) {
			estimatedRef.current.scrollIntoView({behavior:'smooth' , block:"center"})
		}
	} , [completedSteps])

	return (
		<div className="text-z-shark flex items-center justify-center flex-col">
			{completedSteps[3] ? (
				<div className="flex items-center space-x-2">
					<div className="bg-z-dark-moss-green rounded-full w-4 h-4 flex items-center justify-center">
						<img src={CheckMarkerIcon} width={10} alt="check marker icon" />
					</div>
					<div className="text-sm">Step 3 Complete</div>
				</div>
			) : (
				<div className="text-sm mb-1">Step 3</div>
			)}
			<div className="text-xl font-bold mb-3">Create your home buying plan</div>
			<div className="flex items-center space-x-2">
				<div className="text-[#828282] text-[14px] font-medium">
					Are you ready to buy?
				</div>
				<div
					onClick={() => setHomeBuyingPlanModal(true)}
					onKeyUp={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							setHomeBuyingPlanModal(true);
						}
					}}
				>
					<InfoIcon fill="#828282" />
				</div>
			</div>
			{completedSteps[2] && !completedSteps[3] && (
				<div className="text-z-shark rounded-[20px] shadow-[0_0_6px_0_rgba(0,0,0,0.20)] w-full flex justify-center items-center flex-col px-5 py-8 mt-5" ref={estimatedRef}>
					{homeGoals ? (
						<div className="w-full">
							<div className="text-xl font-bold">Set your home goals</div>
							<div className="divide-y-[1px] mt-6">
								<div className="flex items-center justify-between">
									<div className="font-medium">Location</div>
									<div
										className="text-z-dark-moss-green font-medium w-[calc(100%_-85px)] text-ellipsis overflow-hidden whitespace-nowrap text-end"
										onClick={() => setBuyingHomeState(true)}
										onKeyUp={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												setBuyingHomeState(true);
											}
										}}
									>
										{buyingHomeData.length <= 0 ? "Set goal" : buyingHomeData.map((location) => location.replace(", ON", "")).join(", ")}
									</div>
								</div>
								<div className="flex items-center justify-between mt-5 pt-5">
									<div className="font-medium">House price</div>
									<div
										className="text-z-dark-moss-green font-medium"
										onClick={() => setPlanSpendHomeState(true)}
										onKeyUp={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												setPlanSpendHomeState(true);
											}
										}}
									>
										{planSpendData === "Set goal"
											? planSpendData
											: `$${planSpendData}`}
									</div>
								</div>
								<div className="flex items-center justify-between mt-5 pt-5">
									<div className="font-medium">Target Date</div>
									<div
										className="text-z-dark-moss-green font-medium"
										onClick={() => setWhenBuyHomeState(true)}
										onKeyUp={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												setWhenBuyHomeState(true);
											}
										}}
									>
										{whenBuyHomeData}
									</div>
								</div>
							</div>
							<Button
								disableClass="!text-[#9D9D9D] !bg-[#D9D9D9]"
								customClassName="font-bold mt-5"
								// disable={!validHomeGoal}
								height="50px"
								onClick={() => {
									completeStep(3);
									setBasedBuyingGoal(true);
								}}
								width="100%"
							>
								Save home goal
							</Button>
						</div>
					) : (
						<>
							<span className="font-semibold">
								Your estimated buying power is
							</span>
							<div className="text-4xl font-bold mt-3 mb-8">
								{totalPriceCurrentQualification === "" ? "" : `$${formatPrice(totalPriceCurrentQualification)}`}
							</div>
							<span className="text-[15px]">
								Is this how much you want to purchase for?
							</span>
							<div className="flex items-center space-x-3 mt-5 mb-8">
								<Button
									height="50px"
									radius="40px"
									width="150px"
									onClick={() => completeStep(3)}
								>
									Yes
								</Button>
								<Button
									height="50px"
									radius="40px"
									width="150px"
									isOutlined={true}
									onClick={() => setHomeGoals(true)}
								>
									No
								</Button>
							</div>

							<span className="text-[10px] font-thin text-[#A3A3A3]">
								*estimate may adjust based on your pre-approval.
							</span>
						</>
					)}
				</div>
			)}

			{!completedSteps[2] && (
				<Button
					disable={true}
					disableClass="!text-[#9D9D9D] !bg-[#D9D9D9] font-normal !text-base font-semibold"
					customClassName="font-bold text-lg mt-5"
					radius="60px"
					height="md"
					width="320px"
					startIcon={LockIcon}
				>
					Complete step 2 to unlock
				</Button>
			)}

			{completedSteps[3] && (
				<div className="shadow-[0_0_6px_0_rgba(0,0,0,0.20)] rounded-[20px] flex items-center justify-center flex-col w-full py-7 mt-5">
					<span className="font-semibold">You can purchase up to</span>
					<div className="flex items-center space-x-3 mt-3 mb-5">
						<div className="font-bold text-4xl">${totalPriceCurrentQualification}</div>
						<img
							width={24}
							src={EditIcon}
							alt="edit icon"
							onClick={() => resetSteps(2)}
							onKeyUp={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									resetSteps(2);
								}
							}}
						/>
					</div>
					<div className="text-[#A3A3A3] text-[10px] font-thin">
						*estimate may adjust based on your pre-approval.
					</div>
				</div>
			)}
		</div>
	);
};

export { StepThree };

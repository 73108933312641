import {
	CheckMarkerIcon,
	PencilSquareIcon,
	StatsReportIcon,
} from "../../../assets/icons";
import { Button } from "../../common";
import { SavingSection } from "../savingSection";
import { IncomeSection } from "../incomeSection";
import { CreditScoreSection } from "../creditScoreSection";
import { DebtSection } from "../debtSection";
import { useModalStore } from "../../../stores/handleModalStore";
import { useEffect, useRef } from "react";

const BasedBuyingGoalStep = () => {
	const {setEditHouseGoalOverPageState , setFinancialReportOverPage} = useModalStore()
	const basedBuyingGoalRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		basedBuyingGoalRef?.current?.scrollIntoView({behavior:'smooth'})
	} , [])
	return (
		<div className="text-z-shark flex items-center justify-center flex-col" ref={basedBuyingGoalRef}>
			<div className="flex items-center space-x-2">
				<div className="bg-z-dark-moss-green rounded-full w-4 h-4 flex items-center justify-center">
					<img src={CheckMarkerIcon} width={10} alt="check marker icon" />
				</div>
				<div className="text-sm">Step 3 Complete</div>
			</div>
			<div className="text-xl font-bold mb-3">Your Progress</div>
			<div className="flex items-center space-x-2">
				<div className="text-[#828282] text-[14px] font-medium">
					Based on your home buying goal
				</div>
			</div>
			<div className="flex items-center space-x-2 mt-5">
				<Button
					onClick={() => setEditHouseGoalOverPageState(true)}
					width="160px"
					height="36px"
					radius="50px"
					startIcon={PencilSquareIcon}
					customClassName="!text-sm whitespace-nowrap"
				>
					Edit Goal
				</Button>
				<Button
					width="160px"
					height="36px"
					onClick={() => setFinancialReportOverPage(true)}
					isOutlined={true}
					radius="50px"
					startIcon={StatsReportIcon}
					customClassName="!text-sm !px-2 whitespace-nowrap"
				>
					Financial Report
				</Button>
			</div>
			<SavingSection />
			<IncomeSection />
			<CreditScoreSection />
			<DebtSection />
		</div>
	);
};

export { BasedBuyingGoalStep };
